
// 引入Vue实例对象和错误码描述
import { app } from "../main";
import { ERR_NET_FAIL, message } from "@/config/error";
import { BASE_SERVER_URL } from "@/config/ip";

let seq = 0;

/**
 * @function axiosFn请求后台数据接口
 * @param options {Object}
 * 请求接口参数设置 {url: 请求地址，param: 请求参数对象，method: 请求方式(默认值为get)}
 * isThirdParty 如果为true 则在url前不拼 BASE_SERVER_URL
 */
function axiosFn(options) {
  let authenticationParam = localStorage.getItem("token") || localStorage.getItem("Authentication") || "";
  const { Authentication, id, userName } = app.$route.query;
  if (Authentication) {
    localStorage.setItem("Authentication", Authentication);
    authenticationParam = Authentication;
    if (id) {
      localStorage.setItem("id", id);
    }
    if (userName) {
      localStorage.setItem("userName", userName);
    }
  }
  const config = {
    // 默认配置
    // 请求头和默认请求方式
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authentication: authenticationParam,
    },
    timeout: 1800000,
  };
  if (
    options.headers &&
    options.headers["Content-Type"] !== config.headers["Content-Type"]
  ) {
    options.headers.Authentication = authenticationParam;
  }
  // 参数设置
  for (const k in options) {
    if (k !== "param") {
      config[k] = options[k];
    }
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Methods"] = "*";
  config.headers["Access-Control-Allow-Headers"] = "*";
  if (config["url"].indexOf("http") === -1) {
    config["url"] = `${BASE_SERVER_URL}/api${config["url"]}`;
  }
  // 设置url
  // 设置传参方式
  if (
    config.method === "post" ||
    config.method === "put" ||
    config.method === "delete"
  ) {
    // 处理formData数据上传
    if (config.headers["Content-Type"] === "multipart/form-data") {
      let formData = new FormData();
      Object.keys(options.param).forEach((key) => {
        formData.append(key, options.param[key]);
      });
      config["data"] = formData;
    } else if (
      config.headers["Content-Type"] ===
      "application/x-www-form-urlencoded;charset=UTF-8"
    ) {
      // 请求头为application/x-www-form-urlencoded的请求参数传递
      let postData = "";
      for (const key in options.param) {
        // 设置传参为数组时使用jsonStringfy方法转字符串
        if (
          Object.prototype.toString.apply(options.param[key]) ===
          "[object Array]" ||
          Object.prototype.toString.apply(options.param[key]) ===
          "[object Object]"
        ) {
          options.param[key] = JSON.stringify(options.param[key]);
        }
        postData +=
          encodeURIComponent(key) +
          "=" +
          encodeURIComponent(options.param[key]) +
          "&";
      }
      config["data"] = postData;
    } else {
      // 请求头为application/json的请求参数传递 (config.headers["Content-Type"] === "application/json;charset=utf-8")
      config["data"] = options.param;
    }
  } else if (config.method === "get") {
    // 有可能是字符串这里暂时先写非全等
    if (options["param"] != undefined || options["param"] != null) {
      Object.keys(options["param"]).forEach((v) => {
        if (
          Object.prototype.toString.apply(options["param"][v]) ===
          "[object Array]" ||
          Object.prototype.toString.apply(options.param[v]) ===
          "[object Object]"
        ) {
          options["param"][v] = JSON.stringify(options["param"][v]);
        }
      });
    }
    config["params"] = options["param"];
  }
  // 拦截请求结果
  app.$axios.interceptors.response.use(
    function (response) {
      response.seq = seq;
      // 请求成功返回response
      if (response.status === 200) {
        if (response.data.code == 200 || response.data.code == 1) {
          return response;
        } else if (UnLoginCode.indexOf(response.data.code) !== -1) {
          app.$message.error("登录超时，请重新登录！");
          app.$router.replace("/Login");
        } else if (response.data.code == 403) {
          return Promise.reject(response.data);
        } else if (response.data.code == 404) {
          return Promise.reject(response.data);
        } else {
          return Promise.reject(response.data);
        }
      } else if (response.status === 500) {
        return Promise.reject(response.data);
      }
    },
    function (error) {
      //判断请求超时
      if (
        error.code === "ECONNABORTED" &&
        error.message.indexOf("timeout") !== -1
      ) {
        if (document.querySelectorAll(".el-message").length === 0) {
          app.$message.error("请求超时，请刷新页面重试！");
          return;
        }
      }
      error.seq = seq;
      return Promise.reject(error);
    }
  );
  seq++;
  // return axios的new promise对象
  return app.$axios(config).then((response) => response.data);
}
/**
 * @function axiosFlowFn流程相关请求后台数据接口
 * @param options {Object}
 * @return void
 * @author csz 2020/05/10
 */
function axiosFlowFn(options) {
  let ProjectId = sessionStorage.getItem("ProjectId");
  options["tenantId"] = ProjectId;
  return axiosFn(options, WORKFLOW_URL);
}
/**
 * @function axiosExportFn导出后项目相关请求后台数据接口
 * @param options {Object}
 * @return void
 */
function axiosExportFn(options) {
  let authenticationParam = localStorage.getItem("token") || localStorage.getItem("Authentication") || "";
  let config = {
    "method": "",
    "headers": {
      "Content-Type": "",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
      Authentication: authenticationParam,
    },
    "timeout": 1800000,
    "url": ""
  };
  config.method = options.method;
  config.url = options.url;
  if (options.method === "get") {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
  } else if (options.method === "post") {
    config.headers["Content-Type"] = "multipart/form-data";
    let formData = new FormData();
    Object.keys(options.param).forEach((key) => {
      formData.append(key, options.param[key]);
    });
    config["data"] = formData;
  }
  return app.$axios(config).then((response) => response.data);
}
/**
 * 统一的文件下载
 * @param url {String} url
 * @param param {Object} param
 */
function downloadAxiosFn(url, params) {
  let authentication = localStorage.getItem("Authentication");
  let baseURL = `${BASE_SERVER_URL}/api`;
  const myAxios = app.$axios.create({
    method: "get",
    baseURL: baseURL,
    timeout: 1800000,
    responseType: "blob", // 注意responseType必须设置成blob
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
      Authentication: authentication,
    },
  });
  return myAxios
    .get(url, { params: params })
    .then((res) => {
      if (res.headers["exception-message"] !== undefined) {
        let message = decodeURI(res.headers["exception-message"]);
        let result = { code: 300, message: message };
        notifyError(result);
        return;
      } else {
        let index = res.headers["content-disposition"].indexOf("=") + 1;
        let fileName = decodeURI(
          res.headers["content-disposition"].substring(index)
        );
        let BLOB = new Blob([res.data], { type: "application/octet-stream" });
        let url = window.URL.createObjectURL(BLOB);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      let result = { code: 300, message: err };
      notifyError(result);
    })
    .finally(() => {
    });
}
//未登录的错误状态码
const UnLoginCode = ["401", "403", "404", "408", "409", "410"];
/**
 * @function 统一错误返回接口
 * @param err {Object} 需要合并的Grid
 */
function notifyError(err) {
  if (err.code) {
    app.$message.error(err.message || message[ERR_NET_FAIL]);
  } else {
    if (document.querySelectorAll(".el-message").length === 0) {
      app.$message.error(message[ERR_NET_FAIL]);
    }
  }
}

/**
 * 通用文件处理API
 */
const fileUtilServers = {
  // 文件上传
  fileUpload(param) {
    return axiosFn({
      url: "/file/upload",
      param: param,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  // 文件下载
  fileDownload(param) {
    return downloadAxiosFn({ url: `/file/download/${param.id}` });
  },
  // 获取文件信息详情
  getFile(param) {
    return axiosFn({ url: `/file/${param.id}` });
  },
  // 获取文件信息列表
  queryFileList(param) {
    return axiosFn({ url: "/file", param });
  },
  // 新增文件信息
  addFile(param) {
    return axiosFn({ url: "/file", param, method: "post" });
  },
  // 编辑文件信息
  editFile(param) {
    return axiosFn({ url: `/file/${param.id}`, param, method: "put" });
  },
  // 删除文件信息，ids为数组
  removeFile(param) {
    return axiosFn({ url: `/file/${param.id}`, method: "delete" });
  },
  // 导入我的项目图片
  uploadImgae(param) {
    return axiosFn({
      url: "/file/upload",
      param,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
/**
 * 系统设置-机构-相关Api
 */
const systemOrganizationServers = {
  //获取机构tree接口
  getOrganizationTreeList() {
    return axiosFn({ url: "/organization/tree" });
  },

  //获取机构列表的接口
  getOrganizationList(param) {
    return axiosFn({ url: "/organization", param: param });
  },

  //获取机构详情
  getOrganizationById(url) {
    return axiosFn({ url: url });
  },

  //编辑机构
  editOrganizationtById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },

  //新增机构接口
  addOrganization(param) {
    return axiosFn({ url: "/organization", param: param, method: "post" });
  },

  //批量删除机构
  deleteOrganization(param) {
    return axiosFn({ url: "/organization", param: param, method: "delete" });
  }
};
/**
 * 系统菜单相关API
 */
const systemMenuServers = {
  //获取菜单tree接口
  getModuleTreeList() {
    return axiosFn({ url: "/menu/tree" });
  },
  //获取菜单列表的接口
  getModuleList(param) {
    return axiosFn({ url: "/menu", param: param });
  },
  //获取菜单详情
  getModuleById(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //新增菜单接口
  addModule(param) {
    return axiosFn({ url: "/menu", param: param, method: "post" });
  },
  //编辑菜单
  editModuleById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //批量删除菜单
  deleteModuleByIds(param) {
    return axiosFn({ url: "/menu", param: param, method: "delete" });
  },
  // 菜单操作
  operateMenuById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  }
};
/**
 * 系统用户相关API（包括token）
 */
const systemUserServers = {
  // 获取当前用户绑定的角色列表
  getUserRoleList(url) {
    return axiosFn({ url: url });
  },
  //保存用户的角色分配
  addUserRole(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  // 获取已登录角色的菜单权限
  getPermission(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 获取已登录角色的功能权限
  getFunction(param) {
    return axiosFn({ url: "/menu/permission/function", param: param });
  },
  //获取用户列表
  getUserList(param) {
    return axiosFn({ url: "/user", param: param });
  },
  //获取用户详情
  getUserInfoById(url) {
    return axiosFn({ url: url });
  },
  //编辑用户
  editUserInfoById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //新增用户
  addUser(param) {
    return axiosFn({ url: "/user", param: param, method: "post" });
  },
  //新增用户
  registerUser(param) {
    return axiosFn({ url: "/user/register", param: param, method: "post" });
  },
  //批量删除用户
  deleteUserInfoByIds(param) {
    return axiosFn({ url: "/user", param: param, method: "delete" });
  },
  // 重置用户密码
  resetUserPassword(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  // 用户登录
  userlogin(url, param) {
    return axiosFn({ url: url, param: param, method: "post", headers: { "Content-Type": "multipart/form-data" } });
  },
  //校验用户Token状态
  getTokenStatus(param) {
    return axiosFn({ url: "/intergration/valid", param: param });
  },
  // 用户注销
  userLogOut() {
    return axiosFn({ url: "/user/token", method: "delete" });
  }
};
/**
 * 系统角色相关API
 */
const systemRoleServers = {
  //获取所有菜单列表
  getRolel(param) {
    return axiosFn({ url: "/role", param: param });
  },
  //获取角色列表
  getRoleList(param) {
    return axiosFn({ url: "/role", param: param });
  },
  //获取角色详情
  getRoleInfoById(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //批量删除角色
  deleteRoleInfoByIds(url, param) {
    return axiosFn({ url: url, param: param, method: "delete" });
  },
  //编辑角色详情
  editRoleInfoById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //新增角色
  addRole(param) {
    return axiosFn({ url: "/role", param: param, method: "post" });
  },
  // 给角色绑定成员
  allocationRoleUser(url, param) {
    return axiosFn({
      url: url,
      param: param,
      method: "post",
    });
  },
  //获取当前绑定角色的用户
  getRoleUser(url) {
    return axiosFn({ url: url });
  },
  //获取当前角色绑定的菜单
  getRoleMenuList(url) {
    return axiosFn({ url: url });
  },
  //获取菜单详情
  getMenuview(url) {
    return axiosFn({ url: url });
  },
  //获取指定菜单下的子模块和功能
  getMenuChildren(url) {
    return axiosFn({ url: url });
  },
  // 给角色绑定菜单权限
  authorityRoleModules(url, param) {
    return axiosFn({
      url: url,
      param: param,
      method: "post",
    });
  }
};
/**
 * 系统日志相关API
 */
const systemLogServers = {
  //获取单条日志详细
  getLogInfo(url) {
    return axiosFn({ url: url });
  },
  //重试
  retryLog(url) {
    return axiosFn({ url: url });
  },
  //获取日志列表
  getLogList(param) {
    return axiosFn({ url: "/log", param });
  },

  // 获取系统日志列表
  getSystemLogList(param) {
    return axiosFn({ url: "/log/system", param });
  }
};
/**
 * 系统流程配置相关
 */
const systemProcessConfigServers = {
  // 获取流程列表
  getNodeInformationList(param) {
    return axiosFn({ url: "/v1/flowable/task-definition", param });
  },
  // 获取流程列表
  getProcessLists(param) {
    return axiosFn({ url: "/v1/flowable/process-definition", param });
  }
};
/**
 * 数据字典相关接口
 */
const dictionaryServers = {
  //获取数据字典列表
  getDictionaryList(param) {
    return axiosFn({ url: "/dictionary", param: param });
  },
  //新增数据字典
  addDictionary(param) {
    return axiosFn({ url: "/dictionary", param: param, method: "post" });
  },
  //获取数据字典详情
  getDictionaryById(param) {
    return axiosFn({ url: `/dictionary/${param.id}`, param: param });
  },
  //编辑数据字典详情
  editDictionaryById(param) {
    return axiosFn({
      url: `/dictionary/${param.id}`,
      param: param,
      method: "put",
    });
  },
  //批量删除数据字典
  deleteDictionaryInfoByIds(param) {
    return axiosFn({
      url: `/dictionary?ids=${param.ids}`,
      param: param,
      method: "delete",
    });
  },
  // 新增数据字典项
  addDictionaryItem(param) {
    return axiosFn({
      url: `/dictionary/${param.id}/dictionaryItem`,
      param: param,
      method: "post",
    });
  },
  //通过字典编码查询字典项
  getDictionaryItemByDictionaryCode(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 编辑数据字典项
  editDictionaryItem(param) {
    return axiosFn({
      url: `/dictionary/${param.id}/dictionaryItem/${param.itemId}`,
      param: param,
      method: "put",
    });
  },
  // 批量删除数据字典项
  deleteDictionaryItemByIds(param) {
    return axiosFn({
      url: `/dictionary/${param.dictionaryId}/dictionaryItem?ids=${param.ids}`,
      param: param,
      method: "delete",
    });
  }
};
/**
 * 权限相关API
 */
const permissionServers = {
  //获取菜单下的按钮
  getButtons(routeName) {
    if (routeName && routeName.indexOf("/") != -1) {
      routeName = routeName.substring(routeName.indexOf("/") + 1);
    }
    return axiosFn({
      url: "/menu/permission/function",
      param: {
        routeName,
      },
    });
  },
  //获取菜单
  getMenus(param) {
    return axiosFn({ url: "/menu/permission", param });
  },
};
/**
 * 流程相关API
 */
const flowServers = {
  //查询多条流程
  getProcessList(param) {
    return axiosFn({
      url: `/v1/flowable/process`,
      method: "get",
      param: param
    });
  },
  //查询流程定义（通过流程定义标识）
  getProcessDefinition(key) {
    return axiosFn({
      url: `/v1/flowable/process-definition/${key}`,
    });
  },
  //获取流程实例的详情
  getFlowInstance(param) {
    return axiosFn({
      url: `/v1/flowable/task/${param.currentTaskId}`
    });
  },
  //发起申请
  startFlow(param) {
    return axiosFn({
      url: `/v1/flowable/process`,
      method: "post",
      param,
    });
  },
  //审批流程
  approveFlow(param) {
    param.approverStatus = 1;
    return axiosFn({
      url: `/v1/flowable/task`,
      method: "put",
      param,
    });
  },
  //获取流程审批记录
  getProcessRecord(param) {
    return axiosFn({
      url: `/v1/flowable/process/${param.processId}/tasks`,
    });
  }
}
/****
 * 数据中心相关
 */
const systemFileDataServers = {
  //查询目录树
  getDirTree(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 数据包校验
  verifypacket(param) {
    return axiosFn({
      url: "/v1/data/file/checkout", param: param, method: "post",
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  //新增目录或者文件
  addDir(param) {
    return axiosFn({
      url: "/dataCatalog",
      param: param,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  //编辑目录或者文件
  editDir(url, param) {
    return axiosFn({
      url: url,
      param: param,
      method: "put",
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  //批量删除目录或者文件
  deleteDir(param) {
    return axiosFn({ url: "/dataCatalog", param: param, method: "delete" });
  },
  //目录移动
  moveDir(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //查询文件数据
  getRecordList(param) {
    return axiosFn({ url: "/v1/data/file", param: param });
  },
  //新增文件数据
  addRecord(param) {
    return axiosFn({
      url: "/v1/data/file",
      param: param,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  //编辑文件数据
  editRecord(url, param) {
    return axiosFn({
      url: url,
      param: param,
      method: "put",
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  //获取文件数据详情
  getRecordInfo(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //删除文件数据
  deleteRecordByIds(param) {
    return axiosFn({ url: "/v1/data/file", param: param, method: "delete" });
  },
  //数据历史树
  getDataHistoryTree(param) {
    return axiosFn({ url: "/v1/data/file/version", param: param });
  },
  //设置有效版本
  setHistoryTree(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  // 下载历史树数据
  downloadHistoryTree(url) {
    return downloadAxiosFn(url);
  },
  // 元数据列表（非字段映射）
  getRealMetadataList(param) {
    return axiosFn({ url: "/realMetadata", param: param });
  },
  //更新数据
  updateRecord(url, param) {
    return axiosFn({
      url: url,
      param: param,
      method: "post"
    });
  },
  register(url, param) {
    return axiosFn({
      url: url,
      param: param,
      method: "put"
    });
  },
  // 下载
  download(url) {
    return axiosFn({ url: url });
  },
  // 获取所有的图层的属性字段
  getAllAttribute(url) {
    return axiosFn({ url: url });
  },
  // 获取当前图层的属性字段列表
  getlayerAttributeList(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 保存图层的拥有属性
  addlayerAttributes(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 获取所有的本地瓦片文件数据
  getelecTlocalTilesDataList() {
    return axiosFn({ url: "/mapService/video/dataList" });
  },
  // 获取所有的本地瓦片文件数据
  getThreeDimensionalDataList() {
    return axiosFn({ url: "/mapService/threeDimensional/dataList" });
  },
  // 更改服务状态
  modifyDataStatus(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  // 获取所有的地图模式矩形
  getFileDataRectangleList(url) {
    return axiosFn({ url: "/v1/data/file/all" });
  },
  // 点图查询数据信息
  getListByPoint(param) {
    return axiosFn({ url: "/v1/data/point", param: param });
  },
  // 绘制查询数据信息
  getListByDraw(param) {
    return axiosFn({ url: "/v1/data/area", param: param });
  },
  /**
   * 样式相关
   **/
  //获取样式列表
  getStylesList(param) {
    return axiosFn({ url: "/style", param: param });
  },
  //获取样式详情
  getStylesInfo(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //新增样式
  addStyles(param) {
    return axiosFn({ url: "/style", param: param, method: "post" });
  },
  //编辑样式
  editStyles(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //删除样式
  deleteStyles(param) {
    return axiosFn({ url: "/style", param: param, method: "delete" });
  },
  /**
   * 图层相关
   **/
  //查询图层列表
  getLayerList(param) {
    return axiosFn({ url: "/layer", param: param });
  },
  //查询单个图层详情
  getLayerInfo(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //编辑图层
  editLayerById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  // 图层下载
  downloadLayer(params) {
    return downloadAxiosFn("/layer/download/form", params);
  },
  /**
   * 图层组相关
   **/
  //获取图层组列表
  getLayerGroupList(param) {
    return axiosFn({ url: "/layerGroup", param: param });
  },
  //获取图层详情
  getLayerGroupInfo(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 获取图层结构
  getLayerstructure(url, param) {
    return axiosFn({ url: url, param: param });
  },
  /**
   *
   *映射字段相关
   */
  //根据metaDataId获取映射字段信息
  getMetaById(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //根据metaDataId获取映射字段信息
  editMetaById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //批量删除映射字段
  deleteMetaByIds(url, param) {
    return axiosFn({ url: url, param: param, method: "delete" });
  },
  //删除映射字段
  deleteMetaById(url) {
    return axiosFn({ url: url, method: "delete" });
  },
  //获取映射字段列表
  getMapFieldsList(param) {
    return axiosFn({ url: "/metaData", param: param });
  },
  //获取新增映射字段
  addMeta(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  //获取导入映射字段
  ImportMeta(url, param) {
    return axiosFn({ url: url, param: param, method: "post", headers: { "Content-Type": "multipart/form-data" } });
  },
  /**
   * 地图模型相关接口
   *
   */
  //查询地图模型列表
  querySpatialModelList(param) {
    return axiosFn({ url: "/spatialModel", param: param });
  }
}

/****
 * 目录管理相关
 */
const systemSourceDirServers = {
  //获取目录树
  getDirectoryTreeList(param) {
    return axiosFn({ url: "/sourceDir/role/tree", param: param });
  },
  //获取角色列表
  getDirectoryList(param) {
    return axiosFn({ url: "/sourceDir", param: param });
  },
  // 目录详细
  getDirectoryById(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //编辑目录
  editDirectoryById(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  // 新增目录
  addDirectory(param) {
    return axiosFn({ url: "/sourceDir", param: param, method: "post" });
  },
  // 批量删除
  deleteDirectoryByIds(param) {
    return axiosFn({ url: "/sourceDir", param: param, method: "delete" });
  },
  // 删除
  deleteDirectoryById(url) {
    return axiosFn({ url: url, method: "delete" });
  },
  // 目录操作
  operateDirectoryById(url, param) {
    return axiosFn({ param: param, url: url, method: "put" });
  },
};

/**
 * 系统设置-图层树管理-相关Api
 */
const systemLayerTreeServers = {
  getMapTreeData(url) {
    return axiosFn({ url: url });
  },
  //获取图层树下拉
  getLayerTreeOption() {
    return axiosFn({ url: "/mapTreeComponent" });
  },
  //获取图层树目录
  getLayerTreeMenu(param) {
    return axiosFn({ url: `/mapTreeMenu/tree/${param.id}` });
  },
  // 新增图层树目录
  addLayerTreeDirectory(param) {
    return axiosFn({ url: "/mapTreeMenu", param: param, method: "post" });
  },
  // 编辑图层树目录
  editLayerTreeDirectory(param) {
    return axiosFn({
      url: `/mapTreeMenu/${param.id}`, param: param, method: "put"
    });
  },
  // 删除图层树目录
  deleteLayerTreeDirectory(param) {
    return axiosFn({ url: `/mapTreeMenu/${param.id}`, method: "delete" });
  },
  // 获取图层目录绑定的角色
  getDirectoryRoleList(url) {
    return axiosFn({ url: url });
  },
  //保存图层目录的角色分配
  addDirectoryRole(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  // 获取图层服务列表
  getLayerSeverList(param) {
    return axiosFn({ url: "/mapTreeService", param, method: "get" });
  },
  // 编辑图层服务
  setServerInfo(param) {
    return axiosFn({
      url: `/mapTreeService/${param.id}`, param: param, method: "put"
    });
  },
  // 删除图层服务
  deleteLayerServer(param) {
    return axiosFn({ url: `/mapTreeService/${param.id}`, method: "delete" });
  },
  // 获取图层目录绑定的角色
  getLayerRoleList(url) {
    return axiosFn({ url: url });
  },
  //保存图层目录的角色分配
  addLayerRole(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  // 新增图层服务
  addServerInfo(param) {
    return axiosFn({
      url: "/mapTreeService", param: param, method: "post"
    });
  },
};

/**
 * 服务相关
 **/
const systemServers = {
  //获取服务列表
  getServerList(param) {
    return axiosFn({ url: "/mapService", param: param });
  },
  //获取服务的数据
  getServerDataList(param) {
    return axiosFn({ url: "/mapService/dataList", param: param });
  },
  // 获取单条数据
  getServerByDataId(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //获取单个服务
  getServerInfo(url, param) {
    return axiosFn({ url: url, param: param });
  },
  //删除服务
  deleteServer(param) {
    return axiosFn({ url: "/mapService", param: param, method: "delete" });
  },
  //发布服务
  addServer(param) {
    return axiosFn({ url: "/mapService", param: param, method: "post" });
  },
  //修改服务
  editServer(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //更新服务状态
  editServerStatus(url, param) {
    return axiosFn({ url: url, param: param, method: "put" });
  },
  //获取资源目录树（在线地图）
  getResourceTree(param) {
    return axiosFn({ url: "/resources/tree", param: param, method: "get" });
  },
  // 获取资源树列表
  getResourcesTreeList(url, param) {
    return axiosFn({ url: url, param: param, });
  },
  // 远程服务发布提交方法
  postForm(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  // 本地瓦片服务提交
  localTilesPostForm(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  // 本地瓦片服务提交
  threeDimensionalPostForm(url, param) {
    return axiosFn({ url: url, param: param, method: "post" });
  },
  // 获取瓦片文件路径信息
  getLocalTilesPath(url, param) {
    return axiosFn({ url: url, param: param, });
  },
  // 获取本地瓦片服务所需的参数
  getTileArgs(url, param) {
    return axiosFn({ url: url, param: param });
  },
  // 下载3D数据
  download3DData(url) {
    return downloadAxiosFn(url);
  },
  // 获取服务关联的表格数据
  getServerTableList(param) {
    return axiosFn({ url: "/v1/serviceSource", method: "get", param });
  },
  // 新增服务关联
  addServerData(param) {
    return axiosFn({ url: "/v1/serviceSource", method: "post", param });
  },
  // 编辑服务关联
  editServerData(param) {
    return axiosFn({ url: `/v1/serviceSource/${param.id}`, method: "put", param });
  },
  // 删除服务关联
  delServerData(ids) {
    return axiosFn({ url: `/v1/serviceSource/${ids}`, method: "delete" });
  },
  // 获取服务关联详情
  getServerInfoData(id) {
    return axiosFn({ url: `/v1/serviceSource/${id}`, method: "get" });
  },
}


/**
* 政策管理-相关API
*/
const bizZhengceguanliServers={
  // 获取政策管理详情
  getZhengceguanli(param) {
    return axiosFn({url: `/v1/zhengceguanli/${param.id}`});
  },
  // 获取政策管理列表
  queryZhengceguanli(param) {
    return axiosFn({url: "/v1/zhengceguanli", param});
  },
  // 新增政策管理
  addZhengceguanli(param) {
    return axiosFn({url: "/v1/zhengceguanli", param, method: "post"});
  },
  // 编辑政策管理
  editZhengceguanli(param) {
    return axiosFn({url: `/v1/zhengceguanli/${param.id}`, param, method: "put"});
  },
  // 删除政策管理，ids为数组
  removeZhengceguanli(param) {
    return axiosFn({url: `/v1/zhengceguanli/${param.id}`, method: "delete"});
  }
}
/**
* 流程审批记录-相关API
*/
const bizProcessTaskServers={
  // 获取流程审批记录详情
  getProcessTask(param) {
    return axiosFn({url: `/v1/processTask/${param.id}`});
  },
  // 获取流程审批记录列表
  queryProcessTask(param) {
    return axiosFn({url: "/v1/processTask", param});
  },
  // 新增流程审批记录
  addProcessTask(param) {
    return axiosFn({url: "/v1/processTask", param, method: "post"});
  },
  // 编辑流程审批记录
  editProcessTask(param) {
    return axiosFn({url: `/v1/processTask/${param.id}`, param, method: "put"});
  },
  // 删除流程审批记录，ids为数组
  removeProcessTask(param) {
    return axiosFn({url: `/v1/processTask/${param.id}`, method: "delete"});
  }
}
/**
* 流程数据-相关API
*/
const bizProcessServers={
  // 获取流程数据详情
  getProcess(param) {
    return axiosFn({url: `/v1/process/${param.id}`});
  },
  // 获取流程数据列表
  queryProcess(param) {
    return axiosFn({url: "/v1/process", param});
  },
  // 新增流程数据
  addProcess(param) {
    return axiosFn({url: "/v1/process", param, method: "post"});
  },
  // 编辑流程数据
  editProcess(param) {
    return axiosFn({url: `/v1/process/${param.id}`, param, method: "put"});
  },
  // 删除流程数据，ids为数组
  removeProcess(param) {
    return axiosFn({url: `/v1/process/${param.id}`, method: "delete"});
  }
}

const constObj = {
  clientType() {
    return "Web";
  },
  //退出登录，调用方法：  @click="getDataAxios.loginOut"
  loginOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("Authentication");
    app.$router.push({ name: "Login" });
  },
}
/**
 * 二维地图相关接口
 */
const mapServers = {
  // 要素查询
  queryFeature(param) {
    return axiosFn({
      url: "/v1/space/featureSearch",
      param: param,
      method: "post",
    });
  },
  // 叠加分析
  mapOverlayAnalysis(param) {
    return axiosFn({
      url: "/v1/space/overlayAnalysis2",
      param: param,
      method: "post",
    });
  }
};
/**
 * 文件上传相关
 */
const fileUpload = {
  // 上传
  uplooad(url, param) {
    return axiosExportFn({
      url: url,
      param: param,
      method: "post",
    });
  },
  // 获取文件列表URL
  getUrlByFileId(url) {
    return axiosExportFn({
      url: url,
      method: "get",
    });
  },
};


/**
 * 结构，组装
 */
const getDataAxios = {
  downloadAxiosFn: downloadAxiosFn,
  notifyError: notifyError,
  ...fileUtilServers,
  ...permissionServers,
  ...flowServers,
  ...dictionaryServers,
  ...systemProcessConfigServers,
  ...systemLogServers,
  ...systemRoleServers,
  ...systemUserServers,
  ...systemMenuServers,
  ...systemOrganizationServers,
  ...systemFileDataServers,
  ...systemSourceDirServers,
  ...systemLayerTreeServers,
  ...systemServers,
  ...constObj,
  ...mapServers,
  ...fileUpload,

  ...bizZhengceguanliServers,
  ...bizProcessTaskServers,
  ...bizProcessServers,

};
//注册到原型链
export default {
  install(Vue) {
    Vue.prototype.getDataAxios = getDataAxios;
  },
};

