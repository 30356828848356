/**
 * @Author: chensizhong
 * @Date: 2022-02-28 11:17:36
 */
const requireFunc = require.context("./", true, /Util.js$/);
const requireAll = (context) =>
  context.keys().map((path) => context(path).default);
const AllUtils = requireAll(requireFunc).filter((item) => item);
export default {
  install(Vue) {
    for (const utils of AllUtils) {
      Vue.use(utils);
    }
  },
};
