import { render, staticRenderFns } from "./BarComponents.vue?vue&type=template&id=635e3880&scoped=true&"
import script from "./BarComponents.vue?vue&type=script&lang=js&"
export * from "./BarComponents.vue?vue&type=script&lang=js&"
import style0 from "./BarComponents.vue?vue&type=style&index=0&id=635e3880&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635e3880",
  null
  
)

export default component.exports