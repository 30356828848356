/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */

/**
 * Created by csz on 2022/03/15.
 */
/**
 * 递归查找组件标识在组件树中的最大编号
 * @param {*} tag 组件标识
 * @param {*} components 组件树
 * @param {*} index 起始编号
 * @returns 最大编号
 */
function findTagIndex(tag, components, index) {
    if (components && components.children && components.children.length > 0) {
        components.children.forEach((child) => {
            if (child && child.tag == tag) {
                let idStrArr = child.id ? child.id.split("_") : null;
                let idIndex = idStrArr ? idStrArr[idStrArr.length - 1] : null;
                if (child.id && idIndex) {
                    //切割组件ID，解析组件的编号
                    let oldIndex = new Number(idIndex);
                    if (oldIndex >= index) {
                        //如果编号比起始编号大或相等，则编号+1。
                        index = 1 + oldIndex;
                    }
                }
            }
            if (child.children) {
                //递归子节点查找组件标识的最大编号
                index = findTagIndex(tag, child, index);
            }
        });
    }
    return index;
}

// 转码
function utf8_encode(string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }
    }
    return utftext;
}

// 解码
function utf8_decode(utftext) {
    var string = "";
    var i = 0;
    var c = (c1 = c2 = 0);
    while (i < utftext.length) {
        c = utftext.charCodeAt(i);
        if (c < 128) {
            string += String.fromCharCode(c);
            i++;
        } else if (c > 191 && c < 224) {
            c2 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
        } else {
            c2 = utftext.charCodeAt(i + 1);
            c3 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(
                ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
            );
            i += 3;
        }
    }
    return string;
}

// 导出对应方法
export default {
    install(Vue) {
        // 生成一个不重复的数字ID，长度为16位
        Vue.prototype.$generateId = function () {
            let p = new Date().getTime().toString();
            let m = Math.random().toString().slice(2, 5);
            return p + m;
        };
        // 生成字符串ID
        Vue.prototype.$generateIdStr = function (tag, components) {
            if (tag && components) {
                //如果传了组件标识和组件树对象，返回组件ID
                let index = 1;
                index = findTagIndex(tag, components, index);
                let idPreStr = tag.replace("-", "_");
                return idPreStr + "_" + index;
            }
            let p = new Date().getTime().toString(36);
            let m = Math.random().toString().slice(2, 9);
            return p + Number(m).toString(36);
        };
        // 转码
        Vue.prototype.$Base64Encode = function (input) {
            let _keyStr =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;
            input = utf8_encode(input);
            while (i < input.length) {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output =
                    output +
                    _keyStr.charAt(enc1) +
                    _keyStr.charAt(enc2) +
                    _keyStr.charAt(enc3) +
                    _keyStr.charAt(enc4);
            }
            return output;
        };
        // 解码
        Vue.prototype.$Base64Decode = function (input) {
            let _keyStr =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
            while (i < input.length) {
                enc1 = _keyStr.indexOf(input.charAt(i++));
                enc2 = _keyStr.indexOf(input.charAt(i++));
                enc3 = _keyStr.indexOf(input.charAt(i++));
                enc4 = _keyStr.indexOf(input.charAt(i++));
                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;
                output = output + String.fromCharCode(chr1);
                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }
            }
            output = utf8_decode(output);
            return output;
        };
    },
};
