/**
 * @Author: chensizhong
 * @Date: 2021-05-08 11:17:36
 * @LastEditors: chensizhong
 * @LastEditTime: 2021-05-08 14:44:19
 */
const flowUtils = {
  /**
   * 根据节点审批状态获取颜色
   * 审批中 ==> #E6A23C
   * 已同意 ==> #67C23A
   * 已拒绝 ==> #F56C6C
   * null/'' ==> #909399
   * @param {*} approveStr 节点审批状态
   * @returns
   */
  approveStatusToColor(approveStr) {
    approveStr = approveStr + "";
    switch (approveStr) {
      case "null":
        return "#E6A23C";
      case "1":
        return "#67C23A";
      case "0":
        return "#F56C6C";
      default:
        return "#909399";
    }
  },
  /**
   * 根据节点审批状态获取图标class
   * 审批中 ==> el-icon-more
   * 已同意 ==> el-icon-success
   * 已拒绝 ==> el-icon-error
   * null/'' ==> el-icon-warning
   * @param {*} approveStr 节点审批状态
   * @returns
   */
  approveStatusToClass(approveStr) {
    approveStr = approveStr + "";
    switch (approveStr) {
      case "null":
        return "el-icon-more";
      case "1":
        return "el-icon-success";
      case "0":
        return "el-icon-error";
      default:
        return "el-icon-warning";
    }
  },
};

export default {
  install(Vue) {
    Vue.prototype.flowUtils = flowUtils;
  },
};

