<template>
  <div class="editor-render">
    <Editor
      :id="`editor-container-${componentId}`"
      class="editor-container"
      style="height: 100% !important"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor } from "@wangeditor/editor-for-vue";
export default {
  name: "EditorViewComponents",
  components: { Editor },
  props: {
    value: {
      type: String,
      default: "",
    },
    componentId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      editorConfig: { readOnly: true },
      mode: "simple",
    };
  },
  beforeDestroy() {
    if (this.editor == null) {
      return;
    }
    this.editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  watch: {
    // 监听父组件通过请求变更的值
    value: {
      handler(val) {
        this.html = val;
      },
    },
  },
  methods: {
    onCreated(editor) {
      this.html = this.value;
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
};
</script>

<style lang="less">
.editor-render {
  padding: 1px;
  width: 100%;
  height: calc(100% - 120px);
  border: 1px solid #ccc;
  box-sizing: border-box;
  .w-e-text-container {
    border: 1px solid #c9d8db;
    height: 100%;
  }
}
</style>
