<template>
  <!-- 地图部分 -->
  <div class="online-map" id="onlineMap">
    <!-- 地图容器 -->
    <div ref="onlineMap" :id="getMapId" class="map-container"></div>
    <!-- 图层树 -->
    <layer-tree
        v-show="options.treeData.show"
        :layerTreeOption="options.treeData"
    />
    <!-- 工具栏 -->
    <toolbar-control
        ref="toolbarControl"
        :toolbarOptions="options.toolBar"
        :mapData="mapData"
        @toolBarDrawEnd="handleOnDrawEnd"
    >
    </toolbar-control>
    <!-- 右下角底图栏 -->
    <base-map-control
        ref="baseMapControl"
        v-show="options.mapToggleData.show"
        :mapData="mapData"
        :mapOptions="options"
        :baseLayer="baseLayer"
    ></base-map-control>
    <!-- 地图其他工具栏 -->
    <other-control
        :mapData="mapData"
        :mapOptions="options"
        :mapZoom="mapZoom"
        @handleOnSetZoom="handleOnCloseMeasure"
    ></other-control>
    <!-- 各类overlay -->
    <span id="queryPositionMark" class="el-icon-aim"></span>
  </div>
</template>

<script>
import ToolbarControl from "./OnlineMap/ToolbarControl";
import BaseMapControl from "./OnlineMap/BaseMapControl";
import OtherControl from "./OnlineMap/OtherControl";
// 图层树相关
import LayerTree from "./OnlineMap/LayerTree";
import mapEventArrangeMixin from "./OnlineMap/mapEventArrangeMixin";
// 引入地图相关mock数据
import {
  ExistBuildingData,
  ApplyBuildingData,
} from "@/mock/workbench/map/nczjdGeojsonMock";
import { mapInfo } from "@/mock/workbench/map/mapInfo";
// 引入openlayers相关
import "ol/ol.css";
import { Map, View } from "ol";
import { createStringXY } from "ol/coordinate";
import Register from "@/utils/registerProj";
// 矢量图层和矢量源对象
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSONFormat from "ol/format/GeoJSON";
import { ScaleLine } from "ol/control";
import MousePosition from "ol/control/MousePosition";
// 样式对象
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
// 控制器对象
import { defaults as defaultControls } from "ol/control";

export default {
  name: "OnlineMap",
  components: {
    ToolbarControl,
    BaseMapControl,
    OtherControl,
    LayerTree
  },
  mixins: [mapEventArrangeMixin],
  props: {
    baseLayer: {
      type: Object,
      require: true
    },
    options: {
      type: Object,
      require: true
    },
  },
  data() {
    return {
      // 二维地图的实例
      mapData: null,
      // 主区域的layer
      mainLayer: null,
      // map的缩放事件
      scaleEvent: null,
      // map的点击事件
      clickEvent: null,
      // map的zoom等级
      mapZoom: this.options.defaultZoom || 18,
      mapInfo,
      // 当前map的绘制geoJson集合
      drawGeoJson: {}
    };
  },
  watch: {
    "options.scaleData": {
      handler() {
        this.setScalePosition();
      },
      deep: true
    }
  },
  computed: {
    // 当前比例尺的偏移量
    scaleDataStyle() {
      const left = this.options.scaleData.left;
      const top = this.options.scaleData.top;
      return { left: `${left}%`, top: `${top}%` };
    },
    // 计算控制地图id
    getMapId() {
      return `map-${this.$generateId()}`;
    },
  },
  async created() {
    await this.$nextTick();
    // 页面渲染后，绑定底图切换的鼠标移入移出事件
    let container = document.getElementById("basemap-container");
    if (container) {
      container.onmouseenter = function () {
        container.classList.add("expand");
      };
      container.onmouseleave = function () {
        container.classList.remove("expand");
      };
    }
    // 初始化地图
    this.initMap();
  },
  methods: {
    // 初始化地图
    async initMap() {
      // 注册自定义坐标系
      if (this.mapData == null) {
        Register.registerProjection();
      } else {
        this.mapData.destroy();
        this.mapData = null;
      }
      // 移除所有的绘制geojson
      this.drawGeoJson = {};
      // 控制工具的集合
      const resultControl = this.setControlLine();
      // 基本图层的集合
      const resultLayer = this.setInitMapLayer();
      // 地图中心点
      const defaultValue = this.options.defaultCenterPoint;
      const defaultArray = defaultValue && defaultValue.split(",");
      let mapCenter = "";
      if (defaultArray.length <= 1) {
        mapCenter = [119.09841817148207, 25.359994436691665];
      } else {
        mapCenter = [parseFloat(defaultArray[0]), parseFloat(defaultArray[1])];
      }
      // 初始化地图
      this.mapData = new Map({
        target: this.getMapId,
        view: new View({
          zoom: this.mapZoom,
          center: mapCenter,
          projection: "EPSG:4490",
          minZoom: this.options.minZoom || 2,
          maxZoom: this.options.maxZoom || 24
        }),
        // 设置控制器
        controls: defaultControls({
          attributionOptions: {
            collapsible: false,
            zoomWheelEnabled: false
          },
          zoom: false,
          // 扩展设置好的比例尺和鼠标控制器
        }).extend([
          resultControl.mousePositionControl,
          resultControl.scaleLine
        ]),
      });
      // 加入基础的图层
      this.mapData.addLayer(resultLayer.measureLayer);
      this.mapData.addLayer(resultLayer.buildingLayer);
      this.mapData.addLayer(resultLayer.exitBuildingLayer);
      this.mapData.addLayer(resultLayer.applyBuildingLayer);
      await this.$nextTick();
      if (this.$refs.baseMapControl) {
        this.$refs.baseMapControl.toggleBaseLayer("img");
      }
      // 设置基础的相关事件
      this.setInitMapEvent();
      // 设置比列尺的位置
      this.setScalePosition();
    },
    // 设置地图基本的控件
    setControlLine() {
      const options = this.options;
      // 比例尺
      let scaleLine = new ScaleLine({
        units: "metric",
        target: "scalebar",
        className: options.scaleData.show ? "ol-scale-line" : "hidden",
      });
      // 鼠标控制器
      let mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(6),
        projection: "EPSG:4490",
        className: options.posistonZoomData.show ? "custom-mouse-position" : "hidden",
        target: document.getElementById("srsbox"),
        undefinedHTML: "X ：0.00 ， Y ： 0.00",
      });
      // 返回生成的实例
      return { scaleLine, mousePositionControl };
    },
    // 设置比例尺的偏移量
    setScalePosition() {
      const scale = document.getElementsByClassName("ol-scale-line")[0];
      if (!scale) {
        return;
      }
      const left = this.options.scaleData.left;
      const top = this.options.scaleData.top;
      scale.style.left = `${left}%`;
      scale.style.top = `${top}%`;
    },
    // 设置需要初始化添加的图层
    setInitMapLayer() {
      // 测量线和面积的矢量图层
      let source = new VectorSource();
      let measureLayer = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 0, 0, 0.6)",
          }),
          stroke: new Stroke({
            color: "rgba(255, 0, 0, 1)",
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: "rgba(255, 0, 0, 1)",
            }),
          }),
        }),
        zIndex: 9999,
      });
      measureLayer.name = "measureLayer";
      // 三维地图建筑图层
      let buildingLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          stroke: new Stroke({
            color: "rgba(205, 205, 205, 0.5)",
            width: 1,
          }),
        }),
      });
      buildingLayer.name = "buildingLayer";
      // 已经存在的房屋feature
      let exitBuildingSource = new VectorSource({
        features: new GeoJSONFormat().readFeatures(ExistBuildingData)
      });
      let exitBuildingLayer = new VectorLayer({
        source: exitBuildingSource,
        style: new Style({
          stroke: new Stroke({
            color: "red",
            width: 1,
          }),
          fill: new Fill({ color: "rgba(255, 204, 76, 0.5)" }),
        }),
        zIndex: 999,
      });
      // 正在申请的房屋feature
      let applyBuildingSource = new VectorSource({
        features: new GeoJSONFormat().readFeatures(ApplyBuildingData)
      });
      let applyBuildingLayer = new VectorLayer({
        source: applyBuildingSource,
        style: new Style({
          stroke: new Stroke({
            color: "red",
            width: 1,
          }),
          fill: new Fill({ color: "rgba(243, 1, 1, 0.8)" }),
        }),
        zIndex: 999,
      });
      // 返回生成的图层实例
      return { measureLayer, buildingLayer, exitBuildingLayer, applyBuildingLayer };
    },
    // 初始地图后的默认监听方法
    setInitMapEvent() {
      // 监听地图放大缩小移动事件
      this.scaleEvent = this.mapData.on("moveend", () => {
        // 获取当前地图的缩放级别
        this.mapZoom = parseInt(this.mapData.getView().getZoom());
      });
      // 监听地图点击事件
      this.clickEvent = this.mapData.on("singleclick", (e) => {
        const featureFlag = this.$refs.toolbarControl.featureOpenFlag;
        const mapFlag = this.$refs.toolbarControl.mapClickOpenFlag;
        // 要素查询开，地图点击关
        if (featureFlag && !mapFlag) {
          if (this.mapData.hasFeatureAtPixel(e.pixel)) {
            this.$emit("featureClick", this.mapData.getCoordinateFromPixel(e.pixel));
          }
        }
        // 要素查询关，地图点击开
        if (!featureFlag && mapFlag) {
          this.$emit("mapClick", this.mapData.getCoordinateFromPixel(e.pixel));
        }
        // 暂时移除 2022-04-07
        // if (this.mapData.hasFeatureAtPixel(e.pixel)) {
        //   let feature = this.mapData.getFeaturesAtPixel(e.pixel);
        //   if (feature[0].values_.Id) {
        //     this.$store.commit(
        //       "MAP_INFO",
        //       this.mapInfo[new Number(feature[0].values_.Id)]
        //     );
        //   }
        //   const featureGeoJson = JSON.parse(new GeoJSON().writeFeatures(feature));
        //   this.$emit("featureClick", featureGeoJson);
        // }
      });
    },
    // 结束绘制事件
    handleOnDrawEnd(drawGeoJson) {
      this.drawGeoJson = drawGeoJson;
      this.$emit("drawEnd", JSON.stringify(drawGeoJson));
    },
    // 清除绘制
    handleOnCloseMeasure() {
      this.$refs.toolbarControl.clearClick();
    },
  },
};
</script>

<style type="text/less" lang="less">
.online-map {
  position: absolute;
  left: 0px;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .map-container {
    width: 100%;
    height: 100%;
  }
}
/* 比例尺 */
.ol-scale-line {
  background-color: transparent;
  border-radius: 4px;
  width: 100px;
  padding: 2px;
  position: absolute;
  text-align: left;
  .ol-scale-line-inner {
    display: inline-block;
    width: 100px !important;
    line-height: 1.1;
    overflow: hidden;
    padding: 2px 5px 1px;
    border: 2px solid #777;
    border-top: none;
    color: #333333;
    font-size: 13px;
    white-space: nowrap;
    box-sizing: border-box;
  }
}
//各类overlay
#queryPositionMark {
  font-size: 28px;
  color: #f56c6c;
}
.hidden {
  display: none !important;
}
.eventRangerOverlay-node {
  font-size: 28px !important;
  color: #f56c6c;
}
</style>