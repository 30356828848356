/* eslint-disable key-spacing */
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "0",
    component: () => import("@/components/Skeleton"),
    redirect: {
      name: "11649761068651115",
    },
    children: [
      {
        path: "GuanLiZhengCeLieBiao_1",
        name: "11649761068651115",
        component: () => import("@/view/GuanLiZhengCeLieBiao_1"),
      },
      {
        path: "ZhengCeLieBiao_2",
        name: "11653528410332793",
        component: () => import("@/view/ZhengCeLieBiao_2"),
      },
      {
        path: "ZhengCeLieBiao",
        name: "1654072050575802",
        component: () => import("@/view/ZhengCeLieBiao"),
      },
      {
        path: "GuanLiZhengCeLieBiao_1",
        name: "GuanLiZhengCeLieBiao_1",
        component: () => import("@/view/GuanLiZhengCeLieBiao_1"),
      },
      {
        path: "GuanLiYuanYuLan_1",
        name: "GuanLiYuanYuLan_1",
        component: () => import("@/view/GuanLiYuanYuLan_1"),
      },
      {
        path: "ZhengCeLieBiao_2",
        name: "ZhengCeLieBiao_2",
        component: () => import("@/view/ZhengCeLieBiao_2"),
      },
      {
        path: "YuLan",
        name: "YuLan",
        component: () => import("@/view/YuLan"),
      },
      {
        path: "ZhengCeLieBiao",
        name: "ZhengCeLieBiao",
        component: () => import("@/view/ZhengCeLieBiao"),
      },

      {
        path: "Institution",
        name: "9011",
        component: () => import("@/view/ManagementCenter/Institution"),
      },
      {
        path: "StaffManage",
        name: "9014",
        component: () => import("@/view/ManagementCenter/StaffManage"),
      },
      {
        path: "RoleManage",
        name: "9012",
        component: () => import("@/view/ManagementCenter/RoleManage"),
      },
      {
        path: "ModulesManage",
        name: "9013",
        component: () => import("@/view/ManagementCenter/ModulesManage"),
      },
      {
        path: "logManage",
        name: "640163386159665152",
        component: () => import("@/view/ManagementCenter/logManage"),
      },
      {
        path: "dataDictionaryManage",
        name: "690659754246279168",
        component: () => import("@/view/ManagementCenter/DataDictionaryManage"),
      },
    ],
  },
    {
      path: "/Login",
      name: "Login",
      component: () => import("@/view/login/Login"),
    },
];
const router = new VueRouter({
  routes
});
export default router;
