<template>
  <el-steps
      :direction="direction"
      :process-status="processStatus"
      :finish-status="finishStatus"
  >
    <el-step
        v-for="flowRecord in flowRecordData"
        :title="flowRecord.createTime"
        :key="flowRecord.id"
        :status="getStatusStr(flowRecord)"
    >
      <template slot="description">
        <el-card class="flow-record-card">
          <div class="record-title">{{ flowRecord.taskName }}</div>
          <div class="record-item">
            <div class="record-item-title">审 批 人 ：</div>
            <div class="record-item-content">{{ flowRecord.approver }}</div>
          </div>
          <div class="record-item">
            <div class="record-item-title">审批结果：</div>
            <div class="record-item-content">
              {{ flowRecord.approverStatus }}
            </div>
          </div>
          <div class="record-item">
            <div class="record-item-title">审批意见：</div>
            <div class="record-item-content">
              {{ flowRecord.approverContent }}
            </div>
          </div>
          <div class="record-item">
            <div class="record-item-title">开始时间：</div>
            <div class="record-item-content">{{ flowRecord.createTime }}</div>
          </div>
          <div class="record-item">
            <div class="record-item-title">完成时间：</div>
            <div class="record-item-content">
              {{ flowRecord.approverTime }}
            </div>
          </div>
        </el-card>
      </template>
    </el-step>
  </el-steps>
</template>
<script>
export default {
  name: "FlowRecord",
  props: {
    flowRecordData: {
      type: Array,
    },
    direction: {
      type: String,
      default: () => "vertical",
    },
    processStatus: {
      type: String,
      default: () => "process",
    },
    finishStatus: {
      type: String,
      default: () => "finish",
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    //获取审批节点的完成状态
    getStatusStr(flowRecord) {
      if (flowRecord.approverTime) {
        //已完成
        return "finish";
      }
      //进行中
      return "process";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-step__title {
  font-size: 14px;
}
/deep/.el-step:last-of-type .el-step__description {
  padding-right: 10% !important;
}
.flow-record-card {
  height: 170px;
  min-width: 320px;
  max-width: 600px;
  margin: 4px;
  margin-bottom: 20px;
  .record-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .record-item {
    display: flex;
    .record-item-title {
      color: #112f4171;
      min-width: 5.2em;
    }
    .record-item-content {
    }
  }
}
</style>