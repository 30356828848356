// 组件和页面栏相关配置
export const COMPONENT_TABOPTION = [
  { label: "页面", value: "page" },
  { label: "组件库", value: "component" },
];
// 属性栏相关配置
export const ATTR_TABOPTION = [
  { label: "属性配置", value: "AttrOption" },
  { label: "数据绑定", value: "ModelOption" },
  { label: "事件编排", value: "EventOption" },
];
// 工具栏小图标
export const TOOL_BAR_OPTION = [
  {
    name: "保存",
    icon: "savaComponent img-icon",
    clickEvent: "savaPgaeComponent",
  },
  {
    name: "居中",
    icon: "el-icon-aim el-icon",
    clickEvent: "setPageCenter",
  },
  {
    icon: "workLine",
  },
  {
    name: "撤销",
    icon: "revoke img-icon",
    clickEvent: "setLastStep",
  },
  {
    name: "恢复",
    icon: "recovery img-icon",
    clickEvent: "setNextStep",
  },
  {
    name: "删除选中的组件",
    icon: "pageDelete img-icon",
    clickEvent: "deleteCheckedDom",
  },
  {
    name: "复制组件",
    icon: "pageCopy img-icon",
    clickEvent: "copyComponent",
  },
  {
    name: "粘贴组件",
    icon: "iconfont icon-niantie paste img-icon",
    clickEvent: "pasteComponent",
  },
  {
    icon: "workLine",
  },
  {
    name: "预览",
    icon: "pagePreview img-icon",
    clickEvent: "preview",
  },
];
// 移动端二维地图工具栏
export const APP_TOOLBAR_OPTION_ITEM = [
  {
    name: "结束绘制",
    type: "closeDraw",
    icon: "iconfont icon-icon-jieshu",
  },
  {
    name: "清除绘制",
    type: "clear",
    icon: "el-icon-delete",
  },
  {
    name: "切换图层",
    type: "changeMapLayer",
    icon: "iconfont icon-icon-caozuozujian",
  },

];
// 组件相关mock数据
export const COMPONENT_RENDER_DATA = [
  {
    name: "容器",
    key: "group",
    expandFlag: true,
    data: [
      //弹性
      {
        //组件标识：动态组件标识
        tag: "vant-flex",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "弹性容器",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-danxingrongqi",
          //所属分组
          group: ["容器"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "inner", "next"],
          //组件分类：容器、组件
          type: "容器",
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "弹性容器",
              width: 100,
              widthUnit: "%",
              height: 50,
              heightUnit: "%",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "容器",
            },
            //组件属性I
            attribute: {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              borderValue: "show",
              borderRadius: "",
              boxShadow: "",
              borderWidth: "",
              borderStyle: "",
              borderColor: null,
              overflowX: "hidden",
              overflowY: "hidden",
              backgroundColor: "#fff",
              backgroundImage: "",
              backgroundSizeWidth: "100",
              backgroundSizeWidthUnit: "%",
              backgroundSizeHeight: "100",
              backgroundSizeHeightUnit: "%",
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //栅格
      {
        //组件标识：动态组件标识
        tag: "vant-grid",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "栅格容器",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-zhagerongqi",
          //所属分组
          group: ["容器"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "inner", "next"],
          //组件分类：容器、组件
          type: "容器",
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "栅格容器",
              width: 100,
              widthUnit: "%",
              height: 0,
              heightUnit: "%",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "容器",
            },
            //组件属性I
            attribute: {
              span: "12:12",
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //折叠面板
      {
        //组件标识：动态组件标识
        tag: "vant-collapse",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "折叠面板",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-zhediemianban",
          //所属分组
          group: ["容器"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "inner", "next"],
          //组件分类：容器、组件
          type: "容器",
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "折叠面板",
              width: 100,
              widthUnit: "%",
              height: 0,
              heightUnit: "%",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "容器",
            },
            //组件属性
            attribute: {
              accordion: false,
              size: "normal",
              iconUrl: "",
              paddingLeft: 15,
              fontSize: 16,
              titleColor: "#443535",
              marginRight: 15,
              fontWeight: 400,
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            collapseItem: [
              { title: "折叠面板一", icon: "friends-o" },
              { title: "折叠面板二", icon: "comment-o" },
              { title: "折叠面板三", icon: "setting-o" },
            ],
            activeName: [],
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      {
        //组件标识：动态组件标识
        tag: "vant-form",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "表单",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-biaodan",
          //所属分组
          group: ["容器"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "inner", "next"],
          //组件分类：容器、组件
          type: "容器",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "表单",
              width: 100,
              height: 50,
              widthUnit: "%",
              heightUnit: "%",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
            },
            //组件属性：参考elementUI组件库的API
            attribute: {
              labelAlign: "left", //标签对齐方式
              labelWidth: "30", // 标签的宽度
              textAlign: "left", //文本对齐方式
              scrollToError: false, // 是否滚动到效验不通过位置
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //列表容器
      {
        tag: "vant-list",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "列表容器",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-biaoge",
          //所属分组
          group: ["容器"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "inner", "next"],
          //组件分类：容器、组件
          type: "容器",
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "列表容器",
              width: 100,
              widthUnit: "%",
              height: 120,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "容器",
            },
            //组件属性I
            attribute: {},
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            pageSize: 5,
            listItemNum: 1,
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
    ],
  },
  {
    name: "基础组件",
    key: "basicComponent",
    expandFlag: true,
    data: [
      //按钮
      {
        //组件标识：动态组件标识
        tag: "vant-button",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "按钮",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-anniu",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //是否允许拖拽
          allowDrop: true,
        },
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "按钮",
              width: 100,
              widthUnit: "%",
              height: 50,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              text: "按钮",
              color: "#1989FA",
              size: "large",
              type: "primary",
              plain: false,
              round: false,
              icon: "delete-o",
              iconPosition: "left",
              loading: false,
            },
          },
          // 行为控制
          action: {
            // 是否禁止
            disabled: false,
            // 点击跳转的路由地址
            url: "",
            // 调转的路由地址
            to: "",
            // 有无边框
            hairline: true,
          },
          //组件驱动数据-组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //文字
      {
        //组件标识：动态组件标识
        tag: "vant-text",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "文字",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-wenzi",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "文字",
              width: 100,
              widthUnit: "%",
              height: 36,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "组件",
            },
            //组件属性I
            attribute: {
              text: "文字内容",
              color: "#333333",
              maxlength: 100,
              fontSize: 14,
              fontWeight: 400,
              overflowX: "hidden",
              overflowY: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //图片
      {
        //组件标识：动态组件标识
        tag: "vant-image",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "图片",
          //图标：组件库中显示的图标
          icon: "el-icon-picture-outline",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "图片",
              width: 100,
              widthUnit: "%",
              height: 100,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              fit: "contain",
            },
          },
          //行为控制
          action: {
            url: "http://47.114.163.199:8070/file/fmylogo.jpg",
            preview: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
    ],
  },
  {
    name: "表单组件",
    key: "formComponent",
    expandFlag: true,
    data: [
      //单选框
      {
        //组件标识：动态组件标识
        tag: "vant-radio",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "单选框",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-danxuankuang",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "单选框",
              width: 100,
              widthUnit: "%",
              height: 45,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              shape: "round",
              direction: "horizontal",
              position: "left",
              iconSize: "20",
            },
          },
          //行为控制
          action: {
            disabled: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //选中的值
            value: "1",
            options: [
              {
                value: "1",
                name: "是",
              },
              {
                value: "0",
                name: "否",
              },
            ],
            optionsType: "static",
            dataDictionaryId: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      //多选框
      {
        //组件标识：动态组件标识
        tag: "vant-checkbox",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "多选框",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-duoxuankuang",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "多选框",
              width: 100,
              widthUnit: "%",
              height: 45,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              shape: "round",
              iconSize: "20",
              position: "left",
              direction: "horizontal",
            },
          },
          //行为控制
          action: {
            disabled: false,
            min: "1",
            max: "3",
            labelDisabled: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //选中的值
            value: [],
            options: [
              {
                value: "1",
                name: "是",
              },
              {
                value: "0",
                name: "否",
              },
            ],
            optionsType: "static",
            dataDictionaryId: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      // 选择器
      {
        //组件标识：动态组件标识
        tag: "vant-picker",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "选择器",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-xialakuang",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "选择器",
              width: 100,
              widthUnit: "%",
              height: 45,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
            },
          },
          //行为控制
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //选中的值
            inputValue: "",
            options: [
              {
                value: "杭州",
                name: "宁波",
              },
              {
                value: "温州",
                name: "温州",
              },
              {
                value: "绍兴",
                name: "绍兴",
              },
              {
                value: "湖州",
                name: "湖州",
              },
            ],
            optionsType: "static",
            dataDictionaryId: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      // 级联选择
      {
        //组件标识：动态组件标识
        tag: "vant-cascader",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "级联选择",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-jilianxuanze",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "级联选择",
              width: 100,
              widthUnit: "%",
              height: 45,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              title: "地区选择",
              placeholder: "请选择地区",
              activeColor: "#1989fa",
            },
          },
          //行为控制
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //选中的值
            fieldValue: "",
            //选中的值
            cascaderValue: "",
            options: [
              {
                text: "浙江省",
                value: "330000",
                children: [
                  {
                    text: "杭州市",
                    value: "330100",
                    children: [
                      {
                        text: "上城区",
                        value: "330101",
                      },
                      {
                        text: "下城区",
                        value: "330102",
                      },
                      {
                        text: "江干区",
                        value: "330103",
                      },
                    ],
                  },
                ],
              },
              {
                text: "江苏省",
                value: "320000",
                children: [{ text: "南京市", value: "320100" }],
              },
            ],
            optionsType: "static",
            dataDictionaryId: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      //日期选择器
      {
        //组件标识：动态组件标识
        tag: "vant-date-picker",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "日期选择",
          //图标：组件库中显示的图标
          icon: "el-icon-date",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "日期选择器",
              width: 100,
              height: 45,
              widthUnit: "%",
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性：参考elementUI组件库的API
            attribute: {
              title: "日期选择",
              placeholder: "请选择日期",
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "date",
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //选中的值
            inputValue: "",
            currentDate: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      //时间选择器
      {
        //组件标识：动态组件标识
        tag: "vant-time-picker",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "时间选择",
          //图标：组件库中显示的图标
          icon: "el-icon-date",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "时间选择器",
              width: 100,
              height: 45,
              widthUnit: "%",
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性：参考elementUI组件库的API
            attribute: {
              title: "时间选择",
              placeholder: "请选择时间",
              confirmButtonText: "确认",
              cancelButtonText: "取消",
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //选中的值
            inputValue: "",
            currentDate: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      //输入框
      {
        //组件标识：动态组件标识
        tag: "vant-field",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "输入框",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-wenbenshurukuang",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "输入框",
              width: 100,
              widthUnit: "%",
              height: 45,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              //label名
              label: "输入框",
              //label对齐方式 left/center/left
              labelAlign: "left",
              //输入值的对齐方式 left/center/left
              inputAlign: "left",
            },
          },
          //行为控制
          action: {
            disabled: false,
            readonly: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            //默认值
            value: "",
          },
        },
      },
      //搜索
      {
        //组件标识：动态组件标识
        tag: "vant-search",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "搜索",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-sousuokuang",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "搜索",
              width: 100,
              widthUnit: "%",
              height: 100,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              fit: "contain",
              shape: "round",
              placeholder: "请输入",
              inputAlign: "left",
              backgroundColor: "#ffffff",
            },
          },
          //行为控制
          action: {
            disabled: false,
            clearable: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            value: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      // 文件上传
      {
        //组件标识：动态组件标识
        tag: "vant-uploader-file",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "文件上传",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-wenjianshangchuan",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //是否允许拖拽
          allowDrop: true,
        },
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "文件上传",
              width: 100,
              widthUnit: "%",
              height: 140,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              text: "上传文件",
              color: "#1989FA",
              size: "small",
              type: "primary",
              plain: false,
              round: false,
              icon: "plus",
              iconPosition: "left",
              loading: false,
            },
          },
          // 行为控制
          action: {
            // 上传数量
            limit: 1,
            // 文件大小
            limitSize: 0.5,
            // 是否禁用
            disabled: false,
            // 文件类型
            limitTypeArray: ["audio/*,video/*,image/*"],
          },
          //组件驱动数据-组件数据、引用数据模型等
          drive: {
            fileList: [],
            fileIds: "",
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      //图片上传
      {
        //组件标识：动态组件标识
        tag: "vant-image-upload",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "图片上传",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-tupianshangchuan",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "图片上传",
              width: 100,
              widthUnit: "%",
              height: 80,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              // 图片上传组件原本不需要原生属性，为表单容器做适配加了一个属性在这里
              value: false
            },
          },
          //行为控制
          action: {
            disabled: false,
            limitTypeArray: [
              "image/jpg",
              "image/png",
              "image/jpeg",
              "image/apng",
            ],
            maxCount: 1,
            limitSize: 0.5 * 1024 * 1024,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //Switch滑块开关
      {
        //组件标识：动态组件标识
        tag: "vant-switch",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "滑块开关",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-kaiguan",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "滑块开关",
              width: 100,
              widthUnit: "%",
              height: 33,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              value: false,
              size: 20,
              activeColor: "#1989fa",
              inactiveColor: "#dcdee0",
            },
          },
          //行为控制
          action: {
            disabled: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //Stepper步进器
      {
        //组件标识：动态组件标识
        tag: "vant-stepper",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "步进器",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-kaiguan",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "步进器",
              width: 100,
              widthUnit: "%",
              height: 33,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              value: 1,
              step: 1,
              inputWidth: 32,
              buttonSize: 24,
              theme: "normal",
              disablePlus: false,
              disableMinus: false,
              disableInput: false
            },
          },
          //行为控制
          action: {
            disabled: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
      //Slider滑块
      {
        //组件标识：动态组件标识
        tag: "vant-slider",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "滑块",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-huakuai",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "滑块",
              width: 100,
              widthUnit: "%",
              height: 33,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              value: 1,
              min: 0,
              max: 100,
              step: 1,
              barHeight: 3,
              buttonSize: 24,
              activeColor: "#1989fa",
            },
          },
          //行为控制
          action: {
            disabled: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
    ],
  },
  {
    name: "展示组件",
    key: "showComponent",
    expandFlag: true,
    data: [
      //轮播图
      {
        //组件标识：动态组件标识
        tag: "vant-swipe",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "轮播图",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-zoumadeng",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "轮播图",
              width: 100,
              widthUnit: "%",
              height: 240,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              vertical: false,
              showIndicators: true,
              indicatorColor: "#ffffff",
            },
          },
          //行为控制
          action: {
            autoplay: 1000,
            touchable: false,
            loop: false,
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {
            images: [
              { url: "http://47.114.163.199:8070/file/fmylogo.jpg" },
              { url: "http://47.114.163.199:8070/file/fmylogo.jpg" },
              { url: "http://47.114.163.199:8070/file/fmylogo.jpg" },
            ],
          },
        },
        //事件驱动
        event: {},
        children: [],
      },
      // 分割线
      {
        //组件标识：动态组件标识
        tag: "vant-split-line",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "分割线",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-fengexian",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "分割线",
              width: 100,
              height: 20,
              widthUnit: "%",
              heightUnit: "px",
              position: "relative",
              top: 0,
              topUnit: "px",
              left: 0,
              leftUnit: "px",
              zIndex: 0,
              paddingTop: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性：参考elementUI组件库的API
            attribute: {
              size: 1,
              color: "#000",
            },
          },
          //行为控制：控制组件交互的属性，参考elementUI组件库的API
          action: {
            type: "",
          },
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
    ],
  },
  {
    name: "导航组件",
    key: "navComponent",
    expandFlag: true,
    data: [
      //导航栏
      {
        //组件标识：动态组件标识
        tag: "vant-navBar",
        //基本信息
        basicInfo: {
          //名称：组件库中显示的名称
          name: "导航栏",
          //图标：组件库中显示的图标
          icon: "iconfont icon-icon-dingbudaohanglan",
          //所属分组
          group: ["组件"],
          //拖拽时允许被放置的位置：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
          drop: ["prev", "next"],
          //组件分类：容器、组件
          type: "组件",
          //定位开关：限制是否能拖拽位置
          position: false,
          //是否允许拖拽
          allowDrop: true,
        },
        //组件数据
        data: {
          //外观：布局样式、大小、颜色等
          appearance: {
            //布局样式：宽高、位置、内外边距
            layout: {
              // 组件：自定义修改的名称
              alias: "导航栏",
              width: 100,
              widthUnit: "%",
              height: 46,
              heightUnit: "px",
              position: "relative",
              marginTop: 0,
              marginRight: 0,
              marginBottom: 0,
              marginLeft: 0,
              type: "组件",
            },
            //组件属性
            attribute: {
              title: "标题",
              leftText: "返回",
              rightText: "按钮",
              leftArrow: true,
              border: false,
              fixed: false,
            },
          },
          //行为控制
          action: {},
          //组件驱动数据：组件数据、引用数据模型等
          drive: {},
        },
        //事件驱动
        event: {},
        children: [],
      },
    ],
  },
];
// 默认表单设计页面数据
export const DEFAULT_APP_FORM_DESIGN_DATA = {
  tag: "vant-page",
  view: true,
  basicInfo: {
    name: "页面",
    icon: "iconfont icon-icon-yemian",
    group: ["页面"],
    drop: ["inner"],
    type: "容器",
    position: false,
    allowDrop: false,
  },
  data: {
    appearance: {
      layout: {
        alias: "页面",
        width: 100,
        height: 100,
        widthUnit: "%",
        heightUnit: "%",
        position: "relative",
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
        type: "容器",
      },
      attribute: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignContent: "flex-start",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflowX: "auto",
        overflowY: "auto",
      },
    },
    layout: {
      type: "",
      status: true,
    },
    action: {},
    drive: {},
  },
  event: {},
  children: [],
  id: "vant_page_1",
  pageId: "1472270910357536",
};
