<template>
  <div class="search-input-tyle">
    <span class="search_text">{{ searchText }}：</span>
    <el-input
      clearable
      class="search_input"
      size="mini"
      v-model="inputValue"
      :placeholder="placeholderText"
      @keyup.enter.native="emitSearch"
    ></el-input>
  </div>
</template>

<script>
/**
 *
 * @components 输入框筛选组件
 * @props searchText // 文案提示
 * @props placeholderText // 输入框的提示文案
 * @props modelData // 绑定的数据
 * @author csz 2020/05/18
 *
 */
export default {
  name: "BaseSearchInput",
  props: {
    searchText: {
      type: String,
      default: "关键字"
    },
    modelData: {
      type: [String, Number],
      default: ""
    },
    placeholderText: {
      type: String,
      default: "请输入"
    }
  },
  data() {
    return {
      inputValue: this.modelData
    };
  },
  watch: {
    inputValue(val) {
      this.$emit("update:modelData", val);
    },
    modelData(val) {
      this.inputValue = val;
    }
  },
  methods: {
    // 输入框内回车emit一个search方法给父组件
    emitSearch() {
      this.$emit("search");
    }
  }
};
</script>

<style
  type="text/less"
  lang="less"
>
.search-input-tyle {
  display: inline-block;
  margin-right: 15px;
  margin-top: 15px;

  .search_input.el-input--mini {
    display: inline-block;
    width: 200px;
    color: #333333;

    .el-input__inner {
      font-size: 13px;
    }
  }

  .search_text {
    display: inline-block;
    font-size: 13px;
    color: #333333;
  }
}
</style>