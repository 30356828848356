<template>
  <el-form :model="model" class="emailForm">
    <el-form-item
      prop="email"
      :rules="[
        {
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '输入必须为身份证',
          trigger: ['blur'],
        },
      ]"
    >
      <el-input
        type="email"
        v-model="model.email"
        :disabled="disabled"
        :readonly="readonly"
        :size="size"
        @blur="blur"
        :maxlength="30"
        :minlength="1"
      >
        <i slot="prefix" class="el-icon-postcard"></i
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    value: {
      type: null,
      default: () => {
        return "";
      },
    },
    size: {
      type: null,
      default: () => {
        return "";
      },
    },
    type: {
      type: null,
      default: () => {
        return "";
      },
    },
    readonly: {
      type: null,
      default: () => {
        return false;
      },
    },
    disabled: {
      type: null,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      model: {
        email: "",
      },
    };
  },
  methods: {
    blur() {
      this.$emit("update:value", this.model.email);
    },
  },
};
</script>
<style lang="less" scoped>
.emailForm {
  /deep/.el-form-item {
    margin: 0;
  }
  /deep/ .el-form-item__content {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}
</style>
