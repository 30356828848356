import { render, staticRenderFns } from "./AppTextComponent.vue?vue&type=template&id=d3fb6648&scoped=true&"
import script from "./AppTextComponent.vue?vue&type=script&lang=js&"
export * from "./AppTextComponent.vue?vue&type=script&lang=js&"
import style0 from "./AppTextComponent.vue?vue&type=style&index=0&id=d3fb6648&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3fb6648",
  null
  
)

export default component.exports