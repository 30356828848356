<template>
  <div ref="echartsBar" class="echarts-bar"></div>
</template>
<script>
  export default {
    name: "BarComponents",
    props: {
      layout: {
        type: Object,
      },
      title: {
        type: String,
      },
      xName: {
        type: String,
      },
      yName: {
        type: String,
      },
      type: [],
      xAxis: [],
      barOptionData: [],
      showBackground: {
        type: Boolean,
      },
      backgroundColor: {
        type: Boolean,
      },
      titleShow: {
        type: Boolean,
      },
      legend: {
        type: Boolean,
      },
      toolTip: {
        type: Boolean,
      },
      opacity: {
        type: Number,
      },
      titleLeft: {
        type: String,
      },
      titleTop: {
        type: String,
      },
      titleSize: {
        type: String,
      },
      colorList: {
        type: String,
      },
    },
    data() {
      return {
        myChart: null,
      };
    },
    computed: {
      getOption() {
        return this.data;
      },
    },
    watch: {
      layout: {
        handler() {
          this.getEcharts();
        },
        deep: true,
      },
      title() {
        this.getEcharts();
      },
      xName() {
        this.getEcharts();
      },
      yName() {
        this.getEcharts();
      },
      type() {
        this.getEcharts();
      },
      xAxis() {
        this.getEcharts();
      },
      barOptionData() {
        this.getEcharts();
      },
      showBackground() {
        this.getEcharts();
      },
      backgroundColor() {
        this.getEcharts();
      },
      legend() {
        this.getEcharts();
      },
      toolTip() {
        this.getEcharts();
      },
      titleShow() {
        this.getEcharts();
      },
      opacity() {
        this.getEcharts();
      },
      titleLeft() {
        this.getEcharts();
      },
      titleTop() {
        this.getEcharts();
      },
      titleSize() {
        this.getEcharts();
      },
      colorList() {
        this.getEcharts();
      },
    },
    mounted() {
      this.getEcharts();
    },
    methods: {
      getEcharts() {
        // 柱状图数据
        let option = {
          backgroundColor: "",
          title: {
            show: true,
            text: "sss ",
            left: "43%",
            top: "2%",
            textStyle: {
              color: "#6a6a6a",
              fontSize: 16,
            },
          },
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: true,
            left: "left",
            top: "1%",
          },
          xAxis: {
            type: "category",
            name: this.xName || "",
            data: [],
            axisLabel: {
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
            name: this.yName || "",
          },
          series: [
            {
              showBackground: false,
              name: "2011",
              type: "bar",
              data: [18203, 23489, 29034, 104970, 131744, 630230],
            },
            {
              showBackground: false,
              name: "2012",
              type: "bar",
              data: [19325, 23438, 31000, 121594, 134141, 681807],
            },
          ],
        };

        let barTypeOption = {
          // 条形图数据
          backgroundColor: "#fff",
          title: {
            show: true,
            text: "sss ",
            left: "43%",
            top: "2%",
            textStyle: {
              color: "#6a6a6a",
              fontSize: 16,
            },
          },
          tooltip: {
            show: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: true,
            left: "left",
            top: "1%",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            name: this.yName,
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "category",
            name: this.xName,
            data: [],
          },
          series: [
            {
              showBackground: false,
              name: "2011",
              type: "bar",
              data: [18203, 23489, 29034, 104970, 131744, 630230],
            },
            {
              showBackground: false,
              name: "2012",
              type: "bar",
              data: [19325, 23438, 31000, 121594, 134141, 681807],
            },
          ],
        };

        let xAxisData = typeof this.xAxis === "string" ? this.filterXData(JSON.parse(this.xAxis)) : this.filterXData(this.xAxis);
        // 设置data折线图数据
        let barData = typeof this.barOptionData === "string" ? this.filterYData( JSON.parse(this.barOptionData), JSON.parse(this.xAxis)) : this.filterYData(this.barOptionData, this.xAxis);

        // 切换模式 柱状图 or 条形图
        if (this.type == 1) {
          // // 设置x轴数据
          option.xAxis.data = xAxisData;

          // // 设置标题
          option.title.text = this.title;
          option.title.left = `${this.titleLeft ? this.titleLeft + "%" : ""}` || "center";
          option.title.top = `${this.titleTop ? this.titleTop + "%" : ""}` || "auto";
          option.title.textStyle.fontSize = this.titleSize || "18";
          // // 设置提示框开关
          option.tooltip.show = this.toolTip;
          // 设置数据颜色
          option.color = JSON.parse(this.colorList || "[]");
          // // 设置图例开关
          option.legend.show = this.legend;

          // // 标题开关
          option.title.show = this.titleShow;

          // // 设置data数据
          barData.forEach((item) => {
            item.type = "bar";
            item.label = {
              show: true,
              position: "top",
            };
          });
          // attribute.option.series = barData;

          option.series = barData;
        } else {
          // 设置x轴数据
          barTypeOption.yAxis.data = xAxisData;
          barTypeOption.title.left = `${this.titleLeft ? this.titleLeft + "%" : ""}` || "center";
          barTypeOption.title.top = `${this.titleTop ? this.titleTop + "%" : ""}` || "auto";
          barTypeOption.title.textStyle.fontSize = this.titleSize || "18";
          // 设置标题
          barTypeOption.title.text = this.title;

          // 设置提示框开关
          barTypeOption.tooltip.show = this.toolTip;

          // 设置图例开关
          barTypeOption.legend.show = this.legend;
          // 设置数据颜色
          barTypeOption.color = JSON.parse(this.colorList || "[]");
          // 标题开关
          barTypeOption.title.show = this.titleShow;

          // 设置data数据
          barData.forEach((item) => {
            item.type = "bar";
            item.label = {
              show: true,
              position: "right",
            };
          });

          barTypeOption.series = barData;
          option = barTypeOption;
        }

        // 深色模式切换
        if (this.backgroundColor) {
          option.backgroundColor = "rgb(16,12,42)";
          // 透明度切换切换
          if (this.opacity) {
            option.backgroundColor = `rgba(16,12,42,${
                    (100 - this.opacity) / 100
            })`;
          }
        } else {
          option.backgroundColor = "rgb(255,255,255)";
          if (this.opacity) {
            option.backgroundColor = `rgba(255,255,255,${
                    (100 - this.opacity) / 100
            })`;
          }
        }

        // 设置背景色
        if (this.showBackground) {
          if (option.series[0]) {
            option.series[0].showBackground = true;
          }
        } else if (option.title) {
          if (option.series[0]) {
            option.series[0].showBackground = false;
          }
        }

        // echarts渲染DOM
        if (this.myChart) {
          this.myChart.dispose();
        }
        this.myChart = this.$echarts.init(this.$refs.echartsBar, "dark");
        this.myChart.setOption(option);
      },
      // 过滤y轴数据
      filterYData(barOptionData, xAxis) {
        let data = [];
        barOptionData.forEach((v) => {
          let obj = {};
          Object.keys(v).forEach((key) => {
            if (key === "name") {
              obj["name"] = v[key];
            }
            if (key === "data") {
              obj[key] = [];
              Object.keys(v[key]).forEach((ele) => {
                xAxis.forEach((e, i) => {
                  if (e.tag === ele) {
                    obj[key][i] = v[key][ele];
                  }
                });
              });
            }
          });
          data.push(obj);
        });
        return data;
      },
      // 过滤x轴数据
      filterXData(xAxis) {
        let data = [];
        xAxis.forEach((v) => {
          Object.keys(v).forEach((key) => {
            if (key === "xAxis") {
              data.push(v[key]);
            }
          });
        });
        return data;
      },
    },
  };
</script>

<style lang="less" scoped>
  .echarts-bar {
    width: 100%;
    height: 100%;
  }
</style>
