import { render, staticRenderFns } from "./InputIdCard.vue?vue&type=template&id=7fcb7e24&scoped=true&"
import script from "./InputIdCard.vue?vue&type=script&lang=js&"
export * from "./InputIdCard.vue?vue&type=script&lang=js&"
import style0 from "./InputIdCard.vue?vue&type=style&index=0&id=7fcb7e24&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fcb7e24",
  null
  
)

export default component.exports