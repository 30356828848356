/* eslint-disable operator-linebreak */
/**
 * @Author: chensizhong
 * @Date: 2021-05-08 11:17:36
 * @LastEditors: chensizhong
 * @LastEditTime: 2021-05-08 14:44:19
 */
import { app } from "../main";


const objectUtil = {
  isEmpty(value) {
    // 对象和数组判断是否为空
    if (
      Object.prototype.toString.apply(value) === "[object Array]" ||
      Object.prototype.toString.apply(value) === "[object Object]"
    ) {
      if (
        value === null ||
        value === "null" ||
        value.length === 0 ||
        value === undefined ||
        value === "undefined"
      ) {
        return true;
      } else {
        return false;
      }
      // 字符串判断是否为空
    } else if (
      value === "" ||
      value === null ||
      value === "null" ||
      value === undefined ||
      value === "undefined"
    ) {
      return true;
    } else {
      return false;
    }
  },
  isSame(o1, o2, compare = (a, b) => a === b) {
    if (o1 === o2) {
      return true;
    }
    const keys1 = Object.keys(o1);
    const keys2 = Object.keys(o2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    // 计算相等的属性个数
    let sameParamLen = 0;
    keys1.forEach((prop) => {
      if (compare(o1[prop], o2[prop], prop)) {
        sameParamLen += 1;
      }
    });
    if (sameParamLen !== keys2.length) {
      return false;
    }
    return true;
  },
  removeEmptyProp(source, hasValue) {
    if (typeof source !== "object") {
      return source;
    }

    function defaultValidate(val) {
      if (val === 0) {
        return true;
      } else {
        return !!val;
      }
    }

    Object.keys(source).forEach((prop) => {
      const check = hasValue || defaultValidate;
      if (!check(source[prop], prop)) {
        delete source[prop];
      }
    });
    return source;
  },
  hasInvalidateProp(tar, validate) {
    let msg;
    Object.keys(validate).some((prop) => {
      const handler = validate[prop];
      if (typeof handler === "function") {
        msg = handler(tar[prop]);
      } else if (!tar[prop]) {
        msg = validate[prop];
      }
      return !!msg;
    });
    return msg;
  },
  getDifferenceProp(news, base) {
    if (!base && !news) {
      throw new Error("getDifferenceProp atleast need 1 arguemnts");
    }
    if (!base) {
      return news;
    }
    if (!news) {
      return base;
    }
    const result = {};
    Object.keys(news).forEach((prop) => {
      const p1 = base[prop];
      const p2 = news[prop];
      if (!p1) {
        result[prop] = p2;
      }
      if (p1 !== p2) {
        result[prop] = p2;
      }
    });
    return result;
  },
  getDate(timestamp) {
    let date = new Date(timestamp);
    let Y = date.getFullYear() + "-";
    let M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    let D = date.getDate() + " ";
    return Y + M + D;
  },
  getDateByType(date, type) {
    const formatObject = {
      "date": "YYYY-MM-DD",
      "year-month": "YYYY-DD",
      "month-day": "MM-DD",
      "datetime": "YYYY-MM-DD HH:mm:ss",
      "datehour": "YYYY-MM-DD HH",
    };
    if (!formatObject[type]) {
      type = "date";
    }
    return app.$moment(date).format(formatObject[type]);
  }
};

export default {
  install(Vue) {
    Vue.prototype.objectUtil = objectUtil;
  },
};
