<template>
  <div class="toolbar-panel" :style="toolbarStyle">
    <template v-for="(item, index) in toolbarItem">
      <el-dropdown
          trigger="click"
          size="small"
          v-if="setButtonVisable(item.type)"
          :key="index"
          :disabled="item.type !== 'draw'"
          @command="drawClick"
      >
        <div class="bar-item" @click="handleOnClick(item.type)">
          <div class="tool-icon" :class="item.icon"></div>
          <div class="tool-name">{{ setButtonText(item) }}</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="Point" icon="kj-point">
            点
          </el-dropdown-item>
          <el-dropdown-item command="LineString" icon="kj-line">
            线
          </el-dropdown-item>
          <el-dropdown-item command="Polygon" icon="kj-selectPolygon">
            面
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <el-button
        v-if="measureData.drawing"
        class="draw-end-button"
        size="small"
        type="primary"
        @click="drawEndClick"
    >
      结束绘制
    </el-button>
    <!-- 定位弹窗 -->
    <transition name="el-zoom-in-top">
      <div v-show="positionData.showDialog" class="positionInput">
        <el-row>
          <el-col :span="14">
            <el-input
                v-focus
                size="mini"
                ref="positionInputDom"
                placeholder="请输入经纬度"
                v-model="positionData.queryValue"
                @keyup.enter.native="movePosition"
            ></el-input>
          </el-col>
          <el-col :span="5">
            <el-button size="mini" @click="movePosition">查询</el-button>
          </el-col>
          <el-col :span="5">
            <el-button size="mini" @click="closePositionDialog">关闭</el-button>
          </el-col>
        </el-row>
      </div>
    </transition>
  </div>
</template>
<script>
import { TOOLBAR_OPTION_ITEM } from "@/config/viewConfig/onlineMapConfig";
// 引入openlayers相关
import Overlay from "ol/Overlay";
import { Draw } from "ol/interaction";
// 矢量图层和矢量源对象
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
// 样式对象
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
// 工具方法类
import { isEmpty } from "@/utils/object";
import Measure from "@/utils/measure";

export default {
  name: "ToolbarControl",
  props: {
    toolbarOptions: {
      type: Object,
      require: true
    },
    mapData: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      // 定位相关数据
      positionData: {
        // 定位弹窗显示隐藏标识
        showDialog: false,
        // 定位弹窗输入框值
        queryValue: "",
      },
      // 漫游相关数据
      roamingData: {
        // 开始漫游标识
        startFlag: false,
        timer: null
      },
      // 测量相关
      measureData: {
        measureLine: {},
        measureArea: {},
        drawing: false,
        measureType: "",
      },
      // 绘制、测量等工具的图层
      drawGeoJson: {
        features: [],
        crs: {
          type: "name",
          properties: { name: "EPSG:4490" },
        },
        type: "FeatureCollection",
      },
      // 要素查询是否开启
      featureOpenFlag: false,
      // 地图点击是否开启
      mapClickOpenFlag: true,
    };
  },
  computed: {
    toolbarItem() {
      return TOOLBAR_OPTION_ITEM;
    },
    // 判断清除按钮是否显示
    hasClearAllButton() {
      const option = this.toolbarOptions;
      return option.area || option.length || option.draw;
    },
    // 当前工具栏的偏移量
    toolbarStyle() {
      const left = this.toolbarOptions.left;
      const top = this.toolbarOptions.top;
      return { left: `${left}%`, top: `${top}%` };
    }
  },
  methods: {
    handleOnClick(type) {
      this[`${type}Click`]();
    },
    /**
     * 定位相关方法
     */
    // 点击定位按钮
    positionClick() {
      this.positionData.showDialog = !this.positionData.showDialog;
    },
    // 定位到输入的坐标点
    movePosition() {
      const queryValue = this.positionData.queryValue;
      // 将字符串类型的坐标转成Corrdinate（数组）类型
      const transValue = queryValue.replace("，", ",").replaceAll(" ", "");
      let lnglatArr = transValue.split(",");
      if (lnglatArr.length < 2) {
        this.$message.warning("请输入正确的查询坐标");
        return;
      }
      let lnglat = [parseFloat(lnglatArr[0]), parseFloat(lnglatArr[1])];
      // 在地图上标识一个标识
      if (this.mapData.getOverlayById("queryPositionMark") == null) {
        // 如果是第一次生成overlay
        let overlay = new Overlay({
          id: "queryPositionMark",
          element: document.getElementById("queryPositionMark"),
          autoPan: true,
          position: lnglat,
          positioning: "center-center",
        });
        this.mapData.addOverlay(overlay);
      } else {
        // 如果这个overlay已经存在
        this.mapData.getOverlayById("queryPositionMark").setPosition(lnglat);
      }
      // 最后将镜头定位过去
      this.mapData.getView().setCenter(lnglat);
    },
    // 关闭定位输入框
    closePositionDialog() {
      this.positionData.showDialog = false;
      this.positionData.queryValue = "";
      let overlay = this.mapData.getOverlayById("queryPositionMark");
      if (overlay != null) {
        // 此处不用removeOverlay，此方法会直接删除dom，无法在此生成
        overlay.setPosition(undefined);
      }
    },
    /**
     * 漫游相关方法
     */
    // 点击漫游按钮
    roamingClick() {
      if (!this.roamingData.startFlag) {
        this.startRomaing();
      } else {
        this.stopRomaing();
      }
    },
    //开启漫游模式
    startRomaing() {
      this.roamingData.startFlag = true;
      let view = this.mapData.getView();
      view.setZoom(13);
      const centerPosition = view.getCenter();
      let x = 0.0;
      this.roamingData.timer = setInterval(() => {
        x += 0.0004;
        let setPosition = [centerPosition[0], centerPosition[1] + x];
        view.setCenter(setPosition);
        if (x > 0.3) {
          this.stopRomaing();
        }
      }, 50);
    },
    //停止漫游模式
    stopRomaing() {
      this.roamingData.startFlag = false;
      if (this.roamingData.timer) {
        clearInterval(this.roamingData.timer);
        this.roamingData.timer = null;
      }
    },
    /**
     * 全屏相关方法
     */
    // 全屏
    fullscreenClick() {
      let el = document.querySelector(".map-container");
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }
    },
    /**
     * 测距相关
     */
    // 测量线长
    lengthClick() {
      this.beforeDraw();
      this.measureData.measureType = "line";
      this.measureData.measureLine = Measure.measure(this.mapData, "measure", "LineString");
    },
    // 测量面积
    areaClick() {
      this.beforeDraw();
      this.measureData.measureType = "area";
      this.measureData.measureArea = Measure.measure(this.mapData, "measure", "area");
    },
    // 关闭测量
    closeMeasure() {
      if (isEmpty(this.measureData.measureType)) {
        return;
      }
      if (this.measureData.measureType === "area") {
        this.measureData.measureArea.removeDrawEvent();
      } else if (this.measureData.measureType === "line") {
        this.measureData.measureLine.removeDrawEvent();
      }
    },
    // 清除标绘图层
    clearMapPlottingLayer() {
      let num = this.drawGeoJson.features === null;
      num = num || this.drawGeoJson.features.length === 0;
      num = num ? 1 : this.drawGeoJson.features.length + 1;
      for (let i = 0; i < num; i++) {
        //先清空地图上的图层
        this.mapData.getLayers().getArray().forEach((layer) => {
          if (layer.name === "mapPlottingLayer") {
            this.mapData.removeLayer(layer);
          }
        });
      }
      this.drawGeoJson.features = [];
    },
    // 打开画图前的准备
    beforeDraw() {
      this.measureData.drawing = false;
      // 移除范围绘制
      let drawExtent = this.mapData.getInteractions().getArray().find((interaction) => {
        return interaction instanceof Draw;
      });
      this.mapData.removeInteraction(drawExtent);
    },
    /**
     * 标绘相关
     */
    //标绘初始化
    drawClick(command) {
      if (!command) {
        return;
      }
      this.beforeDraw();
      this.draw(command);
    },
    //标绘画笔样式
    draw(command) {
      this.measureData.drawing = true;
      // 定义绘制vector图层
      let source = new VectorSource();
      let vector = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 0, 0, 0.2)",
          }),
          stroke: new Stroke({
            color: "rgba(255, 0, 0, 1)",
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: "rgba(255, 0, 0, 1)",
            }),
          }),
        }),
        zIndex: 9999,
      });
      // 添加绘制图层
      vector.name = "mapPlottingLayer";
      this.mapData.addLayer(vector);
      // 设置绘制的draw
      this.drawVar = new Draw({
        source: source,
        type: command,
      });
      this.mapData.addInteraction(this.drawVar);
      this.drawVar.on("drawend", (evt) => {
        let featureGeoJson = JSON.parse(
            new GeoJSON().writeFeature(evt.feature)
        );
        featureGeoJson.geometry.type = command === "Circle" ? "Point" : command;
        featureGeoJson.properties = {};
        this.drawGeoJson.features.push(featureGeoJson);
      });
    },
    /**
     * 清除相关
     */
    // 清除所有覆盖物
    clearClick() {
      //先清空地图上的图层
      this.mapData.getLayers().getArray().forEach((layer) => {
        if (layer.name === "measureLayer") {
          layer.getSource().getFeatures().forEach((feature) => {
            layer.getSource().removeFeature(feature);
          });
          if (isEmpty(this.measureData.measureType)) {
            return;
          }
          if (this.measureData.measureType === "area") {
            this.measureData.measureArea.removeOverlayEvent();
          } else if (this.measureData.measureType === "line") {
            this.measureData.measureLine.removeOverlayEvent();
          }
        }
      });
      // 清除所有覆盖物
      this.mapData.getOverlays().forEach(overlay => {
        this.mapData.removeOverlay(overlay);
      });
      // 关闭测量相关
      this.closeMeasure();
      this.clearMapPlottingLayer();
      this.beforeDraw();
    },
    /**
     * 要素查询相关
     */
    featureClick() {
      this.featureOpenFlag = !this.featureOpenFlag;
      if (this.featureOpenFlag) {
        this.mapClickOpenFlag = false;
      }
    },
    /**
     * 地图点击开启相关
     */
    mapOpenClick() {
      this.mapClickOpenFlag = !this.mapClickOpenFlag;
      if (this.mapClickOpenFlag) {
        this.featureOpenFlag = false;
      }
    },
    /**
     * 结束绘制相关
     */
    drawEndClick() {
      this.closeMeasure();
      this.beforeDraw();
      this.$emit("toolBarDrawEnd", this.drawGeoJson);
    },
    // 设置toolbar按钮是否显示
    setButtonVisable(type) {
      if (type === "clear") {
        return this.hasClearAllButton;
      } else {
        return this.toolbarOptions[type];
      }
    },
    // 设置toolbar按钮文案
    setButtonText(item) {
      if (item.type === "roaming") {
        return this.roamingData.startFlag ? "关闭漫游" : "漫游";
      } else if (item.type === "feature") {
        return this.featureOpenFlag ? "要素查询关闭" : "要素查询开启";
      } else if (item.type === "mapOpen") {
        return this.mapClickOpenFlag ? "地图点击关闭" : "地图点击开启";
      } else {
        return item.name;
      }
    },
  }
};
</script>

<style lang="less">
/* 右侧工具栏 */
.toolbar-panel {
  position: absolute;
  z-index: 1000;
  max-width: 840px;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  justify-content: space-around;
  .bar-item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    float: left;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-right: 1px solid #ccc;
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    font-family: "宋体";
    color: #666666;
    font-size: 12px;
    cursor: pointer;
    .tool-icon {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      color: inherit;
    }
  }
  .draw-end-button {
    position: absolute;
    top: 35px;
    right: 0px;
  }
  .el-dropdown:nth-last-child(2) .bar-item {
    border: none;
  }
  .bar-item:hover > div {
    color: #4497f7;
  }
  .tool-icon {
    margin-right: 5px;
  }
}
/* 定位输入框 */
.positionInput {
  position: sticky;
  width: 270px;
}
</style>
