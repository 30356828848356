<template>
  <div class="editor-render">
    <Toolbar
      :id="`toolbar-container-${componentId}`"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
      v-if="toolbarShow"
    />
    <Editor
      :id="`editor-container-${componentId}`"
      class="editor-container"
      style="height: calc(100% - 82px) !important"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar} from "@wangeditor/editor-for-vue";
import { FILE_BASE_DOMAIN, EDITOR_UPLOAD } from "@/config/ip";
export default {
  name: "EditorComponents",
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
      default: "",
    },
    componentId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {},
      toolbarShow: true,
      editorConfig: { placeholder: "请输入内容...", MENU_CONF: {}, hoverbarKeys:{} },
      mode: "default", // or 'simple'
    };
  },
  created() {
    let self = this;
    let auth = "?Authentication=" + localStorage.getItem("Authentication");
    // this.editorConfig.hoverbarKeys["attachment"] =  {
    //   menuKeys: ['downloadAttachment'], // “下载附件”菜单
    // }
    // this.toolbarConfig.insertKeys= {
    //   index: 0, // 自定义插入的位置
    //   menuKeys: ['上传附件']
    // }
    // this.editor.config.uploadFileName = "file";
    this.editorConfig.MENU_CONF["uploadImage"] = {
      server: EDITOR_UPLOAD,
      fieldName: "file",
      headers: {
        Authentication: localStorage.getItem("Authentication"),
      },
      // 自定义插入图片
      customInsert(res, insertFn) {
        // res 即服务端的返回结果
        if (res.code != "200") {
          self.getDataAxios.notifyError(res.message);
        } else {
          insertFn(FILE_BASE_DOMAIN + res.data.id + auth);
        }
      },
    };
    // this.editor.config.uploadVideoName = "file";
    this.editorConfig.MENU_CONF["uploadVideo"] = {
      server: EDITOR_UPLOAD,
      fieldName: "file",
      headers: {
        Authentication: localStorage.getItem("Authentication"),
      },
      // 自定义插入图片
      customInsert(res, insertFn) {
        // res 即服务端的返回结果
        if (res.code != "200") {
          self.getDataAxios.notifyError(res.message);
        } else {
          insertFn(FILE_BASE_DOMAIN + res.data.id + auth);
        }
      },
    };
    this.editorConfig.MENU_CONF["uploadAttachment"] = {
      server: EDITOR_UPLOAD, // 服务端地址
      timeout: 5 * 1000, // 5s

      fieldName: 'custom-fileName',
      meta: { token: 'xxx', a: 100 }, // 请求时附加的数据
      metaWithUrl: true, // meta 拼接到 url 上
      headers: { Accept: 'text/x-json' },

      maxFileSize: 10 * 1024 * 1024, // 10M

      // 上传成功后，用户自定义插入文件
      customInsert(res, file, insertFn) {
        console.log('customInsert', res)
        const { url } = res.data || {}
        if (!url) throw new Error(`url is empty`)
        // 插入附件到编辑器
        insertFn(`customInsert-${file.name}`, url)
      },
    };
    console.log(this.toolbarConfig);
    console.log(this.editorConfig);
    // `<iframe name="${res.data.name}" src="${FILE_BASE_DOMAIN + res.data.id + auth}"></iframe>`
  },
  beforeDestroy() {
    if (this.editor == null) {
      return;
    }
    this.editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  watch: {
    // 子组件向父组件传值【对应父组件的:value.sync绑定的值】
    html: {
      handler(val) {
        this.$emit("update:value", val);
      },
    },
    // 监听父组件通过请求变更的值
    value: {
      handler(val) {
        this.html = val;
      },
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.html = this.value;
    },
  },
};
</script>

<style lang="less">
.editor-render {
  padding: 1px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  .w-e-text-container {
    border: 1px solid #c9d8db;
    height: 100%;
  }
}
</style>
