<template>
  <div class="fileComponents">
    <el-upload
        action=""
        list-type="picture-card"
        :limit="limit"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :disabled="disabled"
        :http-request="uplooadFunction"
    >
      <i class="el-icon-plus"></i>
      <div slot="tip" class="el-upload__tip">
        <span v-if="showPlaceholderText">{{ placeholderText }}</span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
/*
 *
 * @components 图片文件上传组件
 * @props placeholderText // 上传的文字提示
 * @props showPlaceholderText // 是否显示上传的文字提示
 * @props limitTypeArray // 限制可以上传的文件格式
 * @props limitSize // 上传文件的大小，按MB大小，只能传入数字
 * @props limit // 上传文件的数量
 * @props width // 固定像素长
 * @props height // 固定像素高
 * @props maxWidth // 最大允许上传图片宽
 * @props maxHeight // 最大允许上传图片高
 * @props minWidth // 最小允许上传图片宽
 * @props minHeight // 最小允许上传图片高
 *
 */
import { BASE_SERVER_URL } from "@/config/ip";
import axios from "axios";
export default {
  name: "fileImageUploadComponents",
  props: {
    limitTypeArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showPlaceholderText: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholderText: {
      type: String,
      default: () => {
        return "";
      },
    },
    limit: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    limitSize: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    width: {
      type: null,
      default: () => {
        return 0;
      },
    },
    height: {
      type: null,
      default: () => {
        return 0;
      },
    },
    maxWidth: {
      type: null,
      default: () => {
        return 0;
      },
    },
    maxHeight: {
      type: null,
      default: () => {
        return 0;
      },
    },
    minWidth: {
      type: null,
      default: () => {
        return 0;
      },
    },
    minHeight: {
      type: null,
      default: () => {
        return 0;
      },
    },
    fileIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  created() {
    if (this.fileIds) {
      this.getUrlByFileId();
    }
  },
  data() {
    return {
      idDragenter: false,
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      ids: [],
    };
  },
  watch: {
    fileIds: {
      handler(val) {
        if (val) {
          this.getUrlByFileId();
        }
      }
    }
  },
  computed: {},
  methods: {
    //on-exceed	文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择${this.limit} 个文件`);
    },
    handleRemove(file, fileList) {
      this.ids.splice(this.ids.indexOf(file.id), 1);
      this.$emit("update:fileIds", this.ids.join(","));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传文件改变
    beforeUpload(file) {
      // 如果有文件限制
      let suffixArr = file.name.split(".");
      let a = this.limitTypeArray.some((type) => {
        return suffixArr[suffixArr.length - 1] === type;
      });
      if (!a && this.limitTypeArray.length > 0) {
        this.$message.warning("暂不支持该类型的文件上传");
        return false;
      }
      // 如果有大小限制
      if (this.limitSize !== 0) {
        if (file.size > this.limitSize * 1024 * 1024) {
          this.$message.warning("上传文件超过限制大小，请重新上传");
          return false;
        }
      }
      const isSize = new Promise((resolve, reject) => {
        const width = this.width;
        const height = this.height;
        const maxWidth = this.maxWidth;
        const maxHeight = this.maxHeight;
        const minWidth = this.minWidth;
        const minHeight = this.minHeight;
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
          if (
              /* eslint-disable*/
              !width &&
              !height &&
              !maxWidth &&
              !maxHeight &&
              !minWidth &&
              !minHeight
              /* eslint-disable*/
          ) {
            resolve();
          }
          if (width && height) {
            // eslint-disable-next-line
            const valid = img.width == width && img.height == height;
            // eslint-disable-next-line
            valid ? resolve() : reject();
          } else if (width || height) {
            if (width) {
              // eslint-disable-next-line
              const valid = img.width == width;
              // eslint-disable-next-line
              valid ? resolve() : reject();
            }
            if (height) {
              // eslint-disable-next-line
              const valid = img.height == height;
              // eslint-disable-next-line
              valid ? resolve() : reject();
            }
          } else {
            if (minWidth && minHeight && maxWidth && maxHeight) {
              /* eslint-disable*/
              const scope =
                  img.width >= minWidth &&
                  img.height >= minHeight &&
                  img.width <= maxWidth &&
                  img.height <= maxHeight;
              /* eslint-disable*/
              scope ? resolve() : reject();
            } else if (minWidth && !minHeight && !maxWidth && !maxHeight) {
              const scope = img.width >= minWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (minWidth && minHeight && !maxWidth && !maxHeight) {
              const scope = img.height >= minHeight && img.width >= minWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (minWidth && !minHeight && maxWidth && !maxHeight) {
              const scope = img.width <= maxWidth && img.width >= minWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (minWidth && !minHeight && !maxWidth && maxHeight) {
              const scope = img.height <= maxHeight && img.width >= minWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (minWidth && minHeight && maxWidth && !maxHeight) {
              const scope =
                  img.height >= minHeight &&
                  img.width >= minWidth &&
                  img.width <= maxWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (minWidth && !minHeight && maxWidth && maxHeight) {
              const scope =
                  img.width <= maxWidth &&
                  img.width >= minWidth &&
                  img.height <= maxHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (minWidth && minHeight && !maxWidth && maxHeight) {
              const scope =
                  img.width >= minWidth &&
                  img.height >= minHeight &&
                  img.height <= maxHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && minHeight && !maxWidth && !maxHeight) {
              const scope = img.height >= minHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && minHeight && maxWidth && !maxHeight) {
              const scope = img.height >= minHeight && img.width <= maxWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && minHeight && !maxWidth && maxHeight) {
              // eslint-disable-next-line
              const scope = img.height >= minHeight && img.height <= maxHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && minHeight && maxWidth && maxHeight) {
              const scope =
                  img.width <= maxWidth &&
                  img.height >= minHeight &&
                  img.height <= maxHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && !minHeight && maxWidth && !maxHeight) {
              const scope = img.width <= maxWidth;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && !minHeight && maxWidth && maxHeight) {
              const scope = img.width <= maxWidth && img.height <= maxHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            } else if (!minWidth && !minHeight && !maxWidth && maxHeight) {
              const scope = img.height <= maxHeight;
              // eslint-disable-next-line
              scope ? resolve() : reject();
            }
          }
        };
        img.src = _URL.createObjectURL(file);
      }).then(
          () => {
            return file;
          },
          () => {
            this.$message.error("上传图片尺寸与图片尺寸限制不符，请重新上传");
            // eslint-disable-next-line
            return Promise.reject();
          }
      );
      return isSize;
    },
    uplooadFunction(params) {
      const file = params.file;
      let param = {
        file: file,
      };
      this.getDataAxios.uploadImgae(param)
          .then((res) => {
            if (res.data) {
              this.ids.push(res.data.id);
              this.fileList.push(res.data);
              this.$emit("update:fileIds", this.ids.join(","));
            }
          })
          .catch((err) => {
            this.getDataAxios.notifyError(err);
          })
          .finally(() => {});
    },
    getUrlByFileId() {
      let authenticationParam = localStorage.getItem("token") || localStorage.getItem("Authentication") || "";
      const { Authentication, id, userName } = this.$route.query;
      if (Authentication) {
        localStorage.setItem("Authentication", Authentication);
        authenticationParam = Authentication;
        if (id) {
          localStorage.setItem("id", id);
        }
        if (userName) {
          localStorage.setItem("userName", userName);
        }
      }
      let config = {
        "method": "get",
        "headers": {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          "Authentication": authenticationParam
        },
        "timeout": 1800000,
        "url": ""
      };
      config.url = `${BASE_SERVER_URL}/api/file/fileIds?fileIds=${this.fileIds}&Authentication=${authenticationParam}`;
      axios(config).then((res)=>{
        if (res.data) {
          this.fileList = [];
          res.data.data.forEach(item => {
            this.fileList.push({
              name: item.name,
              url: `${BASE_SERVER_URL}/api/file/download/${item.id}?Authentication=${authenticationParam}`,
            })
          });
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-upload--picture-card {
  width: 140px;
  height: 140px;
  border: 1px solid #cccccc;
}
</style>
