<template>
  <div class="app-toolbar-panel bar-item">
    <el-dropdown
            trigger="click"
            size="small"
            placement="left"
            @command="drawClick"
    >
      <div
              class="bar-item"
              style="margin-bottom: 0px; position: relative; top: 2px"
      >
        <div class="tool-icon kj-boxSelection"></div>
        <div class="tool-name">开始标绘</div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="Point" icon="kj-point">
          点
        </el-dropdown-item>
        <el-dropdown-item command="LineString" icon="kj-line">
          线
        </el-dropdown-item>
        <el-dropdown-item command="Polygon" icon="kj-selectPolygon">
          面
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <template v-for="(item, index) in toolbarItem">
      <div
              class="bar-item"
              v-if="setButtonShow(item)"
              :key="index"
              @click="handleOnClick(item.type)"
      >
        <div class="tool-icon" :class="item.icon"></div>
        <div class="tool-name">{{ setButtonText(item) }}</div>
      </div>
    </template>
    <van-popup
            v-model="showChangeMapLayer"
            round
            position="bottom"
            :style="{ height: '20%' }"
    >
      <app-base-map-control
              ref="baseMapControl"
              :mapData="mapData"
              :baseLayer="baseLayer"
      ></app-base-map-control
      ></van-popup>
  </div>
</template>
<script>
  import { APP_TOOLBAR_OPTION_ITEM } from "@/config/viewConfig/appPageDesignConfig";
  import { Draw } from "ol/interaction";
  // 矢量图层和矢量源对象
  import VectorLayer from "ol/layer/Vector";
  import VectorSource from "ol/source/Vector";
  import GeoJSON from "ol/format/GeoJSON";
  // 样式对象
  import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";

  export default {
    name: "AppToolbarControl",
    props: {
      toolbarOptions: {
        type: Object,
        require: true
      },
      mapData: {
        type: Object,
        require: true
      },
      baseLayer: {
        type: Object,
        require: true
      }
    },
    data() {
      return {
        showChangeMapLayer: false,
        // 绘制、测量等工具的图层
        drawGeoJson: {
          features: [],
          crs: {
            type: "name",
            properties: { name: "EPSG:4490" },
          },
          type: "FeatureCollection",
        },
        drawing: false
      };
    },
    computed: {
      toolbarItem() {
        return APP_TOOLBAR_OPTION_ITEM;
      },
    },
    methods: {
      handleOnClick(type) {
        this[`${type}Click`]();
      },
      /**
       * 标绘相关
       */
      // 标绘开始
      drawClick(command) {
        if (!command) {
          return;
        }
        this.removeDrawInteraction();
        this.draw(command);
      },
      // 结束绘制
      closeDrawClick() {
        this.drawing = false;
        this.removeDrawInteraction();
        this.$emit("toolBarDrawEnd", this.drawGeoJson);
      },
      // 清除绘制
      clearClick() {
        // 清空绘制的geoJson和移除绘制画笔
        this.clearMapPlottingLayer();
        this.removeDrawInteraction();
      },
      // 地图切换显隐
      changeMapLayerClick() {
        this.showChangeMapLayer = true;
      },
      // 标绘画笔样式
      draw(command) {
        this.drawing = true;
        // 定义绘制vector图层
        let source = new VectorSource();
        let vector = new VectorLayer({
          source: source,
          style: new Style({
            fill: new Fill({
              color: "rgba(255, 0, 0, 0.2)",
            }),
            stroke: new Stroke({
              color: "rgba(255, 0, 0, 1)",
              width: 2,
            }),
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: "rgba(255, 0, 0, 1)",
              }),
            }),
          }),
          zIndex: 9999,
        });
        // 添加绘制图层
        vector.name = "mapPlottingLayer";
        this.mapData.addLayer(vector);
        // 设置绘制的draw
        this.drawVar = new Draw({
          source: source,
          type: command,
        });
        this.mapData.addInteraction(this.drawVar);
        this.drawVar.on("drawend", (evt) => {
          let featureGeoJson = JSON.parse(
                  new GeoJSON().writeFeature(evt.feature)
          );
          featureGeoJson.geometry.type = command === "Circle" ? "Point" : command;
          featureGeoJson.properties = {};
          this.drawGeoJson.features.push(featureGeoJson);
        });
      },
      // 清除标绘图层
      clearMapPlottingLayer() {
        // 先清空地图上的标绘
        let num = this.drawGeoJson.features === null;
        num = num || this.drawGeoJson.features.length === 0;
        num = num ? 1 : this.drawGeoJson.features.length + 1;
        for (let i = 0; i < num; i++) {
          this.mapData.getLayers().getArray().forEach((layer) => {
            if (layer.name === "mapPlottingLayer") {
              this.mapData.removeLayer(layer);
            }
          });
        }
        this.drawGeoJson.features = [];
        this.drawing = false;
      },
      // 移除绘制画笔
      removeDrawInteraction() {
        let drawExtent = this.mapData.getInteractions().getArray().find((interaction) => {
          return interaction instanceof Draw;
        });
        this.mapData.removeInteraction(drawExtent);
      },
      // 设置toolbar按钮文案
      setButtonText(item) {
        return item.name;
      },
      // 设置toolbar按钮显示隐藏
      setButtonShow(item) {
        if (item.type === "clear") {
          return this.drawGeoJson.features.length !== 0 && !this.drawing;
        } else {
          return true;
        }
      }
    }
  };
</script>

<style lang="less" scope>
  /* 右侧工具栏 */
  .app-toolbar-panel {
    position: absolute;
    top: 3%;
    right: 0;
    z-index: 1000;
    width: 85px;
    box-sizing: border-box;
    border-radius: 10px;
    .bar-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      float: left;
      height: 30px;
      line-height: 30px;
      padding: 0 5px;
      border-right: 1px solid #ccc;
      background: #fff;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
      font-family: "宋体";
      color: #666666;
      font-size: 12px;
      margin-bottom: 5px;
      cursor: pointer;
      .tool-icon {
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        color: inherit;
      }
    }
    .bar-item:hover > div {
      color: #4497f7;
    }
    .tool-icon {
      margin-right: 5px;
    }
  }
</style>
