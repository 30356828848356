<template>
  <el-upload
      class="upload-demo"
      action="http://localhost:8001/api/v1/file/upload"
      :before-upload="beforeUpload"
      multiple
      :limit="limit"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
      :file-list="fileList"
      :disabled="disabled"
      :http-request="uplooadFunction"
  >
    <el-button
        :type="buttonType"
        :size="buttonSize"
        :plain="buttonPlain"
        :round="buttonRound"
        :circle="buttonCircle"
        :disabled="disabled"
        :icon="buttonIcon"
    >{{ buttonName }}</el-button>
    <div
        slot="tip"
        class="el-upload__tip"
    >
      <span v-if="showPlaceholderText">{{ placeholderText }}</span>
    </div>
  </el-upload>
</template>
<script>
import {BASE_SERVER_URL} from "@/config/ip";
import axios from "axios";
/**
 *
 * @components 文件上传组件
 * @props placeholderText // 上传的文字提示
 * @props showPlaceholderText // 是否显示上传的文字提示
 * @props limitTypeArray // 限制可以上传的文件格式
 * @props limitSize // 上传文件的大小，按MB大小，只能传入数字
 * @props disabled // 是否禁用
 * @props limit // 上传文件的数量
 */
export default {
  name: "fileUploadComponents",
  data() {
    return {
      fileList: [
      ],
      ids: [],
    };
  },
  props: {
    buttonName: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonSize: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonType: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonIcon: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonPlain: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    buttonRound: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    buttonCircle: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    limit: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    showPlaceholderText: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholderText: {
      type: String,
      default: () => {
        return "";
      },
    },
    limitTypeArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    limitSize: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    fileIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  created() {
    if (this.fileIds) {
      this.getUrlByFileId();
    }
  },
  watch: {
    fileIds: {
      handler(val) {
        this.getUrlByFileId();
      }
    }
  },
  computed: {},
  methods: {
    //on-exceed	文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择${this.limit} 个文件，本次选择了 ${
              files.length
          } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleRemove(file, fileList) {
      this.ids.splice(this.ids.indexOf(file.id), 1);
      this.$emit("update:fileIds", this.ids.join(","));
    },
    // 上传文件改变
    beforeUpload(file) {
      // 如果有文件限制
      let suffixArr = file.name.split(".");
      if (this.limitTypeArray.indexOf("全部") === -1) {
        let a = this.limitTypeArray.some((type) => {
          return suffixArr[suffixArr.length - 1] === type;
        });
        if (!a && this.limitTypeArray.length > 0) {
          this.$message.warning("暂不支持该类型的文件上传");
          return false;
        }
      }

      // 如果有大小限制
      if (this.limitSize !== 0) {
        if (file.size > this.limitSize * 1024 * 1024) {
          this.$message.warning("上传文件超过限制大小，请重新上传");
          return false;
        }
      }
    },
    uplooadFunction(params) {
      const file = params.file;
      let param = {
        file: file,
      };
      this.getDataAxios.uploadImgae(param)
          .then((res) => {
            if (res.data) {
              this.ids.push(res.data.id);
              this.fileList.push({
                name: res.data.originalFileName,
                url: res.data.url,
              });
              this.$emit("update:fileIds", this.ids.join(","));
            }
          })
          .catch((err) => {
            this.getDataAxios.notifyError(err);
          })
          .finally(() => {});
    },
    getUrlByFileId() {
      let authenticationParam = localStorage.getItem("token") || localStorage.getItem("Authentication") || "";
      const { Authentication, id, userName } = this.$route.query;
      if (Authentication) {
        localStorage.setItem("Authentication", Authentication);
        authenticationParam = Authentication;
        if (id) {
          localStorage.setItem("id", id);
        }
        if (userName) {
          localStorage.setItem("userName", userName);
        }
      }
      let config = {
        "method": "get",
        "headers": {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          "Authentication": authenticationParam
        },
        "timeout": 1800000,
        "url": ""
      };
      config.url = `${BASE_SERVER_URL}/api/file/fileIds?fileIds=${this.fileIds}&Authentication=${authenticationParam}`;
      axios(config).then((res)=>{
        if (res.data) {
          this.fileList = [];
          res.data.data.forEach(item => {
            this.fileList.push({
              name: item.name,
              url: `${BASE_SERVER_URL}/api/file/download/${item.id}?Authentication=${authenticationParam}`,
            });
          });
        }
      });
    }
  },
};
</script>
