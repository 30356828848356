<template>
  <div class="image-components">
    <van-image
        width="100%"
        height="100%"
        :src="imageUrl"
        :fit="fit"
        @click="clickEvent"
    >
    </van-image>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import { BASE_SERVER_URL } from "@/config/ip";
import axios from "axios";
export default {
  name: "ImageComponents",
  props: {
    options: {
      type: Object,
      require: true
    },
    src: {
      type: String,
      require: ""
    },
    fit: {
      type: String,
      require: ""
    },
    fileIds: {
      type: String,
      require: ""
    },
  },
  watch: {
    src: {
      handler(val) {
        this.imageUrl = val;
      },
    },
    imageUrl: {
      handler(val) {
        this.$emit("update:src", val);
      },
    },
    fileIds: {
      handler(val) {
        if (val) {
          this.getUrlByFileId();
        }
      }
    }
  },
  data() {
    return {
      imageUrl: this.src
    };
  },
  computed: {
    Attr() {
      return this.options.data.appearance.attribute;
    },
    Action() {
      return this.options.data.action;
    },
    Style() {
      return {
        ...this.$applayout(this.options)
      };
    },
  },
  created() {
    if (this.fileIds) {
      this.getUrlByFileId();
    }
  },
  methods: {
    clickEvent() {
      ImagePreview({
        images: [this.imageUrl],
        closeable: true,
      });
    },
    getUrlByFileId() {
      let authenticationParam =
          localStorage.getItem("token") ||
          localStorage.getItem("Authentication") ||
          "";
      const { Authentication, id, userName } = this.$route.query;
      if (Authentication) {
        localStorage.setItem("Authentication", Authentication);
        authenticationParam = Authentication;
        if (id) {
          localStorage.setItem("id", id);
        }
        if (userName) {
          localStorage.setItem("userName", userName);
        }
      }
      let config = {
        "method": "get",
        "headers": {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          Authentication: authenticationParam,
        },
        "timeout": 1800000,
        "url": ""
      };
      config.url = `${BASE_SERVER_URL}/api/file/fileIds?fileIds=${this.fileIds}&Authentication=${authenticationParam}`;
      axios(config).then((res)=>{
        if (res.data) {
          this.imageUrl = `${BASE_SERVER_URL}/api/file/download/${res.data.data[0].id}?Authentication=${authenticationParam}`;
        }
      });
    }
  },
};
</script>
<style scoped lang="less">
.image-components {
  width: 100%;
  height: 100%;
}
</style>