<template>

  <div ref="echartsBar" class="echarts-bar">
    
  </div>
</template>
<script>
export default {
  props: {
    layout: {
      type: Object
    },
    title: {
      type: String
    },
    indicator: [],
    RenderData:  [],
    backgroundColor: {
      type: Boolean
    },
    titleShow: {
      type: Boolean
    },
    legend: {
      type: Boolean
    },
    toolTip: {
      type: Boolean
    }
  },
  data() {
    return {
      myChart: null,
    };
  },
  computed: {
    getOption() {
      return this.data;
    }
  },
  watch: {
    layout: {
      handler() {
        this.getEcharts();
      },
      deep: true,
    },
    title() {
      this.getEcharts();
    },
    indicator() {
      this.getEcharts();
    },
    RenderData() {
      this.getEcharts();
    }, 
    backgroundColor() {
      this.getEcharts();
    },
    legend() {
      this.getEcharts();
    },
    toolTip() {
      this.getEcharts();
    },
    titleShow() {
      this.getEcharts();
    }
  },
  mounted() {
    this.getEcharts();
  },
  methods: {
    getEcharts() {
      let  option = {
        backgroundColor: "#fff",
        title: {
          show: true,
          text: "",
          left: "center",
          textStyle: {
            color: "#333",
            fontSize: "16"
          }
        },
        tooltip: {
          show: true,
          trigger: "axis"
        },
        legend: {
          show: true,
          orient: "vertical",
          left: "left"
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { text: "素质必修课 ", max: 5.0 },   
            { text: "核心必修课 ", max: 5.0 },
            { text: "一般必修课 ", max: 5.0 },
            { text: "通识必修课", max: 5.0 },
            { text: "通识限选课", max: 5.0 },
          ]  
        },
        series: [
          {
            type: "radar",
            tooltip: {
              trigger: "item"
            },
            data: []
          }
        ]
      };
      let indicator = typeof (this.indicator) === "string" ? JSON.parse(this.indicator) : this.indicator;
      // 设置data数据
      let RenderData = typeof (this.RenderData) === "string" ? JSON.parse(this.RenderData) : this.RenderData;

      // 设置标题
      option.title.text = this.title;

      // 设置雷达图标志数据
      option.radar.indicator = indicator;

      // 设置提示框
      option.tooltip.show =  this.toolTip;

      // 设置图例
      option.legend.show =  this.legend;

      // 设置标题开关
      option.title.show =  this.titleShow;

      // 设置data数据 
      option.series[0].data = RenderData;
      
      // 深色模式切换
      if (this.backgroundColor) {
        option.backgroundColor = "#100c2a";
      } else  {
        option.backgroundColor = "#fff";
      }

      // echarts渲染DOM
      if (this.myChart) {
        this.myChart.dispose();
      }
      
      this.myChart =  this.$echarts.init(this.$refs.echartsBar, "dark");
      this.myChart.setOption(option);
    }
  },
};
</script>

<style lang="less" scoped>
.echarts-bar {
  width: 100%;
  height: 100%;
}
</style>