<template>
  <div class="online-maplayer-tree-box" :style="layerTreeBoxStyle">
    <div class="layer-box">
      <span class="title">图层列表</span>
      <i class="el-icon-s-operation" @click="treeVisible = !treeVisible" />
    </div>
    <el-tree
        :class="{ 'layer-tree-hide': !treeVisible }"
        ref="layerTree"
        v-loading="treeLoading"
        node-key="id"
        :style="layerTreeStyle"
        :expand-on-click-node="false"
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        :default-expand-all="false"
        :draggable="true"
        :allow-drag="allowDragFunction"
        :allow-drop="allowDropFunction"
        @node-drop="dropSuccess"
    >
      <span class="group-tree-node" slot-scope="{ data }">
        <!-- 节点checkbox -->
        <span v-if="data.type === 2" class="tree-node-check">
          <el-checkbox
              v-model="data.checked"
              @change="layerChange($event, data)"
          />
        </span>
        <!-- 节点图标 -->
        <span class="tree-node-icon" :class="treeNodeIconClass(data)" />
        <!-- 节点名称 -->
        <span
            class="tree-node-name"
            :class="{ 'cheked-box': data.type === 2 }"
            :title="data.name"
        >
          {{ data.name }}
        </span>
        <!-- 节点操作下拉框 -->
        <div class="operate-box" v-if="data.type === 2">
          <el-popover
              popper-class="slider_small_popover"
              placement="right"
              trigger="click"
          >
            <span class="slider_value_text">{{ data.sliderValue }}</span>
            <el-slider
                :show-tooltip="false"
                :step="10"
                vertical
                v-model="data.sliderValue"
                height="100px"
                @change="sliderChange($event, data.aliasName)"
            />
            <i class="kj-transparency f-color-hover" slot="reference"></i>
          </el-popover>
        </div>
      </span>
    </el-tree>
  </div>
</template>

<script>
/**
 *
 * @components 主区域的tree组件
 * @author csz 2020/12/23
 */
import { ONLINE_MAP_LAYER_TREE_DATA } from "@/config/viewConfig/onlineMapConfig";
import TileLayer from "ol/layer/Tile";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import TileGrid from "ol/tilegrid/TileGrid";
import WMTS from "ol/source/WMTS";
import MVT from "ol/format/MVT";
import { TileWMS } from "ol/source";
import { get as getProj } from "ol/proj.js";
import { getWidth, getTopLeft } from "ol/extent";
import { isEmpty } from "@/utils/object";
import { getQueryVariable } from "@/utils/functionUtils";

export default {
  name: "LayerTree",
  props: {
    layerTreeOption: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      treeData: [],
      treeLoading: false,
      defaultProps: {
        children: "children",
        label: "name"
      },
      treeVisible: true,
      // 当前树结构所有的图层
      treeLayerList: []
    };
  },
  computed: {
    // 当前图层树的模拟数据
    layerTreeData() {
      return ONLINE_MAP_LAYER_TREE_DATA;
    },
    // 当前图层树盒子的偏移量和宽度
    layerTreeBoxStyle() {
      const left = this.layerTreeOption.left;
      const top = this.layerTreeOption.top;
      const width = this.layerTreeOption.width;
      return { left: `${left}%`, top: `${top}%`, width: `${width}px` };
    },
    // 图层树的高度
    layerTreeStyle() {
      const height = this.layerTreeOption.height;
      return { height: `${height}px` };
    }
  },
  watch: {
    "layerTreeOption.show": {
      async handler(val) {
        await this.$nextTick();
        if (val) {
          await this.getTreeData();
          this.treeLayerList = [];
          // 递归获取到所有的图层
          this.getAllLayer(this.treeData);
          // 加载所有的图层
          this.addTreeLayer();
        } else {
          this.removeTreeLayer();
          const view = this.$parent.mapData.getView();
          const options = this.$parent.options;
          const defaultValue = options.defaultCenterPoint;
          const defaultArray = defaultValue && defaultValue.split(",");
          view.setCenter([parseFloat(defaultArray[0]), parseFloat(defaultArray[1])]);
          view.setZoom(options.defaultZoom || 18);
        }
      },
      immediate: true,
    }
  },
  methods: {
    getTreeData() {
      if (typeof this.layerTreeOption.treeId === "undefined") {
        return;
      }
      let url = `/mapTreeComponent/${this.layerTreeOption.treeId}`
      return this.getDataAxios.getMapTreeData(url)
          .then((res) => {
            this.treeData = res.data;
          })
          .catch((err) => {
            this.getDataAxios.notifyError(err);
          })
    },
    treeNodeIconClass(data) {
      if (data.type === 2) {
        return "kj-file_icon cheked-box";
      } else {
        return "kj-folder";
      }
    },
    // 勾选取消隐藏起图层
    layerChange(flag, data) {
      let baseMapLayerList = this.$parent.mapData.getLayers().getArray();
      baseMapLayerList.forEach(layer => {
        if (layer.name === data.aliasName) {
          layer.setVisible(flag);
        }
      });
      if (flag) {
        let bboxArray = "";
        if (data.bbox) {
          bboxArray = data.bbox.split(",");
        }
        this.setMapCenter(bboxArray);
      }
    },
    // 透明度改变
    sliderChange(sliderVal, name) {
      let baseMapLayerList = this.$parent.mapData.getLayers().getArray();
      baseMapLayerList.forEach(layer => {
        if (layer.name === name) {
          layer.setOpacity(parseFloat(sliderVal) / 100);
        }
      });
    },
    // 判断当前节点是否可以推拽
    allowDragFunction(node) {
      return node.data.type === 2;
    },
    // 判断当前节点是否可以放置
    allowDropFunction(draggingNode, dropNode, type) {
      if (dropNode.data.type === 1 && type === "inner") {
        return true;
      } else if (dropNode.data.type === 2 && type !== "inner") {
        return true;
      } else {
        return false;
      }
    },
    // 拖拽成功回调
    dropSuccess() {
      this.removeTreeLayer();
      this.treeLayerList = [];
      // 递归获取到所有的图层
      this.getAllLayer(this.treeData);
      // 加载所有的图层
      this.addTreeLayer();
    },
    // 移除所有图层树的图层
    removeTreeLayer() {
      let baseMapLayerList = this.$parent.mapData.getLayers().getArray();
      this.treeLayerList.forEach(item => {
        baseMapLayerList.forEach(layer => {
          if (layer.name === item.aliasName) {
            this.$parent.mapData.removeLayer(layer);
          }
        });
      });
    },
    // 递归加载图层
    getAllLayer(array) {
      array.forEach(item => {
        if (item.type === 2) {
          this.treeLayerList.push(item);
        }
        if (item.children && item.children.length) {
          this.getAllLayer(item.children);
        }
      });
    },
    // 添加图层
    addTreeLayer() {
      this.treeLayerList.forEach(layer => {
        if (!isEmpty(layer.url["矢量瓦片服务"])) {
          this.addVertorService(layer, layer.url["矢量瓦片服务"]);
        } else if (!isEmpty(layer.url["WMTS"])) {
          this.addWMTService(layer, layer.url["WMTS"]);
        } else if (!isEmpty(layer.url["WMS"])) {
          this.addWMSService(layer, layer.url["WMS"]);
        }
      });
    },
    // 添加矢量瓦片服务
    addVertorService(layer, layerUrl) {
      let projection = getProj("EPSG:4490");
      let projectionExtent = projection.getExtent(); // web墨卡托投影坐标系的四至
      let width = getWidth(projectionExtent); //web墨卡托投影坐标系的水平宽度，单位：米
      let origin = getTopLeft(projectionExtent); //坐标系起点
      // 计算瓦片分辨率数组，每片分辨率256
      let resolutions = [];
      for (let z = 0; z < 22; ++z) {
        resolutions[z] = width / (256 * Math.pow(2, z + 1));
      }
      let addlayer = new VectorTileLayer({
        declutter: true,
        source: new VectorTileSource({
          tileGrid: new TileGrid({
            origin,
            resolutions,
            tileSize: [256, 256],
          }),
          projection,
          format: new MVT(),
          url: layerUrl,
        }),
      });
      this.setAddLayer(layer, addlayer);
    },
    // 添加wmts服务
    addWMTService(layer, layerUrl) {
      let projection = getProj("EPSG:4490");
      let projectionExtent = projection.getExtent(); // web墨卡托投影坐标系的四至
      let width = getWidth(projectionExtent); //web墨卡托投影坐标系的水平宽度，单位：米
      let origin = getTopLeft(projectionExtent); //坐标系起点
      // 计算瓦片分辨率数组，每片分辨率256
      let resolutions = [];
      let matrixIds = [];
      for (let z = 0; z < 22; ++z) {
        resolutions[z] = width / (256 * Math.pow(2, z + 1));
        matrixIds[z] = "EPSG:4490:" + z;
      }
      let tileGrid = new WMTSTileGrid({
        origin: origin,
        tileSize: [256, 256],
        resolutions: resolutions,
        matrixIds: matrixIds,
      });
      //通过WMTS加载
      let addlayer = new TileLayer({
        source: new WMTS({
          url: layerUrl, //WMTS服务基地址
          matrixSet: "EPSG:4490", //投影坐标系设置矩阵
          format: "image/png", //图片格式
          projection, //数据的投影坐标系
          //瓦片网格对象
          tileGrid,
          wrapX: true,
        }),
      });
      this.setAddLayer(layer, addlayer);
    },
    // 添加wms服务
    addWMSService(layer, layerUrl) {
      let addlayer = new TileLayer({
        opacity: 0.7, //图层透明度
        // 注意这里用的是TileWMS类而不是ImageWMS类
        source: new TileWMS({
          url: layerUrl, // WMS服务的URL.
          // WMS请求参数
          params: {
            LAYERS: layer.gsName, // 请求的图层名
          },
          serverType: "geoserver", // 服务器类型
        }),
      });
      this.setAddLayer(layer, addlayer);
    },
    // 图层添加的统一设置
    setAddLayer(layer, addlayer) {
      let bboxArray = "";
      if (layer.bbox) {
        bboxArray = layer.bbox.split(",");
      }
      addlayer.name = layer.aliasName;
      addlayer.tag = "treeLayer";
      addlayer.bboxArray = bboxArray;
      addlayer.setVisible(layer.checked);
      this.$parent.mapData.addLayer(addlayer);
    },
    // 设置添加后图层的中心点
    setMapCenter(bboxArray) {
      let view = this.$parent.mapData.getView();
      view.fit(bboxArray, this.$parent.mapData.getSize());
    },
  },
};
</script>

<style lang="less">
.online-maplayer-tree-box {
  position: absolute;
  z-index: 2000;
  background: #ffffff;
  box-shadow: 0px 9px 19px 2px rgba(0, 0, 0, 0.13);
  .layer-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    .title {
      font-size: 15px;
      font-weight: bolder;
    }
    i {
      position: absolute;
      right: 10px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .layer-tree-hide {
    height: 0px !important;
    padding: 0 !important;
  }
  .el-tree {
    border-top: 1px solid #e7e7e8;
    overflow: auto;
    padding: 10px 5px;
    box-sizing: border-box;
    transition: all 0.2s linear;
  }
  .el-tree-node__content {
    height: 32px;
    font-size: 14px;
    .group-tree-node {
      width: 100%;
      height: 100%;
      position: relative;
      line-height: 32px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .el-tree-node.is-current > .el-tree-node__content {
    background: rgba(22, 119, 255, 0.1);
    color: #187aff;
  }
  .tree-node-check {
    position: absolute;
    margin-right: 17px;
  }
  .tree-node-icon {
    position: absolute;
    top: -1px;
    font-size: 18px;
    color: #f2c64e;
    &.cheked-box {
      left: 20px;
    }
  }
  .kj-file_icon {
    color: #409eff;
  }
  .tree-node-name {
    display: inline-block;
    width: 180px;
    height: 100%;
    padding-left: 25px;
    line-height: 32px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.cheked-box {
      padding-left: 45px;
    }
  }
  .operate-box {
    .kj-transparency {
      position: relative;
      top: 3px;
      font-size: 25px;
    }
  }
}
.slider_small_popover {
  width: 35px;
  min-width: 35px;
  margin-left: 5px;
  padding: 10px 0;
  .slider_value_text {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .el-slider__runway {
    width: 3px !important;
  }
  .el-slider__bar {
    width: 3px !important;
  }
  .el-slider__button-wrapper {
    left: -17px !important;
  }
  .el-slider__button {
    height: 10px !important;
    width: 10px !important;
  }
}
</style>