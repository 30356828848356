/* eslint-disable operator-linebreak */
// 判断元素是否为空
export function isEmpty(value) {
  // 对象和数组判断是否为空
  if (
    Object.prototype.toString.apply(value) === "[object Array]" ||
    Object.prototype.toString.apply(value) === "[object Object]"
  ) {
    if (
      value === null ||
      value === "null" ||
      value.length === 0 ||
      value === undefined ||
      value === "undefined"
    ) {
      return true;
    } else {
      return false;
    }
    // 字符串判断是否为空
  } else if (
    value === "" ||
    value === null ||
    value === "null" ||
    value === undefined ||
    value === "undefined"
  ) {
    return true;
  } else {
    return false;
  }
}

export function strToArray(str) {
  let strs = new Array(); //定义一数组
  if (typeof str == "string") {
    strs = str.split(","); //字符分割
  }
  return strs;
}

// 判断两个元素是否相等
export function isSame(o1, o2, compare = (a, b) => a === b) {
  if (o1 === o2) {
    return true;
  }
  const keys1 = Object.keys(o1);
  const keys2 = Object.keys(o2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // 计算相等的属性个数
  let sameParamLen = 0;
  keys1.forEach((prop) => {
    if (compare(o1[prop], o2[prop], prop)) {
      sameParamLen += 1;
    }
  });
  if (sameParamLen !== keys2.length) {
    return false;
  }
  return true;
}

// 移除对象内为空的属性
export function removeEmptyProp(source, hasValue) {
  if (typeof source !== "object") {
    return source;
  }

  function defaultValidate(val) {
    return !!val;
  }

  Object.keys(source).forEach((prop) => {
    const check = hasValue || defaultValidate;
    if (!check(source[prop], prop)) {
      delete source[prop];
    }
  });
  return source;
}

export function hasInvalidateProp(tar, validate) {
  let msg;
  Object.keys(validate).some((prop) => {
    const handler = validate[prop];
    if (typeof handler === "function") {
      msg = handler(tar[prop]);
    } else if (!tar[prop]) {
      msg = validate[prop];
    }
    return !!msg;
  });
  return msg;
}

export function getDifferenceProp(news, base) {
  if (!base && !news) {
    throw new Error("getDifferenceProp atleast need 1 arguemnts");
  }
  if (!base) {
    return news;
  }
  if (!news) {
    return base;
  }
  const result = {};
  Object.keys(news).forEach((prop) => {
    const p1 = base[prop];
    const p2 = news[prop];
    if (!p1) {
      result[prop] = p2;
    }
    if (p1 !== p2) {
      result[prop] = p2;
    }
  });
  return result;
}

// 数组的深度拷贝
export function copyArr(arr) {
  let obj = arr instanceof Array ? [] : {};
  for (let i in arr) {
    if (typeof arr[i] == "object" && arr[i] !== undefined) {
      obj[i] = copyArr(arr[i]);
    } else if (typeof arr[i] == "object" && arr[i] == undefined) {
      obj[i] = null;
    } else {
      obj[i] = arr[i];
    }
  }
  return obj;
}

//递归查找树里的某个对象
export function findItemFromTree(arr, k, v) {
  for (const ele of arr) {
    if (ele[k] === v) {
      return ele;
    } else {
      if (ele.children) {
        const res = findItemFromTree(ele.children, k, v);
        if (res) {
          return res;
        }
      }
    }
  }
  return null;
}
