<template>
  <el-descriptions
    class="descriptions-render"
    :title="title"
    :border="border"
    :size="size"
    :direction="direction"
    :colon="colon"
    :column="column || 0"
  >
    <el-descriptions-item
      v-for="(item, index) in dataMapping"
      :key="index"
      :label="item.label"
    >{{ descriptionsItem[item.prop] }}</el-descriptions-item>
  </el-descriptions>
</template>
<script>
export default {
  name: "DescriptionsComponent",
  props: {
    descriptionsItem: {
      type: Object,
      default: () => {},
    },
    dataMapping: {
      type: Array,
      default: () => {
        return [];
      },
    },
    border: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    },
    direction: {
      type: String,
      default: ""
    },
    colon: {
      type: Boolean,
      default: false
    },
    column: {
      type: [Number, String],
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.descriptions-render {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
