import Overlay from "ol/Overlay";
import TileLayer from "ol/layer/Tile";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import TileGrid from "ol/tilegrid/TileGrid";
import WMTS from "ol/source/WMTS";
import MVT from "ol/format/MVT";
import { TileWMS } from "ol/source";
import { get as getProj } from "ol/proj.js";
import { getWidth, getTopLeft } from "ol/extent";
import { getQueryVariable } from "@/utils/functionUtils";
import VectorSource from "ol/source/Vector";
import GeoJSONFormat from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style } from "ol/style.js";
import { getArea } from "ol/sphere";
// 事件编排相关地图事件
export default {
    methods: {
        /**
         * 事件编排地图引用相关事件
         */
        // 设置地图缩放等级
        set2DMapZoom(zoom) {
            if (this.mapData == null) {
                return;
            }
            // 获取地图视图
            const view = this.mapData.getView();
            // 缩放
            view.setZoom(zoom);
        },
        // 设置地图中心点
        set2DMapCenter(source, layerType, layerName, lnglatString) {
            if (source === "layer") {
                let resultLayer = null;
                this.mapData.getLayers().forEach(layer => {
                    if (layerName === layer.name) {
                        resultLayer = layer;
                    }
                });
                if (layerType === "service") {
                    if (resultLayer.bboxArray) {
                        this.setMapCenter(resultLayer.bboxArray);
                    }
                } else {
                    if (resultLayer != null) {
                        this.setMapCenter(resultLayer.getSource().getExtent());
                    }
                }
            } else {
                const transValue = lnglatString.replace("，", ",").replaceAll(" ", "");
                let lnglatArr = transValue.split(",");
                if (lnglatArr.length < 2) {
                    this.$message.warning("请输入正确的查询坐标");
                    return;
                }
                let lnglat = [parseFloat(lnglatArr[0]), parseFloat(lnglatArr[1])];
                // 获取地图视图
                const view = this.mapData.getView();
                if (view) {
                    // 缩放
                    view.setCenter(lnglat);
                }
            }
        },
        // 设置最大缩放等级
        set2DMapMaxZoom(maxZoom) {
            // 获取地图视图
            const view = this.mapData.getView();
            // 缩放
            view.setMaxZoom(maxZoom);
        },
        // 设置最小缩放等级
        set2DMapMinZoom(minZoom) {
            // 获取地图视图
            const view = this.mapData.getView();
            // 缩放
            view.setMinZoom(minZoom);
        },
        // 添加一个图层
        add2DMapLayer(layerName, layerType, layer, fillColor) {
            if (layerType === "service") {
                return this.getDataAxios.getServerInfoData(layer).then(res => {
                    const layerUrl = res.data.url;
                    const type = res.data.type;
                    if (type === "WMS") {
                        this.addWmsLayer(layerName, layerUrl);
                    } else if (type === "WFS") {
                        this.addWfsLayer(layerName, layerUrl);
                    } else if (type === "WMTS") {
                        this.addWmtsLayer(layerName, layerUrl);
                    } else if (type === "矢量瓦片服务") {
                        this.addVertorLayer(layerName, layerUrl);
                    }
                });
            } else {
                if (layer === undefined || this.mapData === null || layer === "" || layer === "{}") {
                    return new Promise((resolve) => { resolve(); });
                }
                let layerObj = JSON.parse(layer);
                if (layerObj.features.length == 0) {
                    return new Promise((resolve) => { resolve(); });
                }
                let features = new GeoJSONFormat().readFeatures(layer);
                const addSource = new VectorSource({
                    features: features
                });
                let style;
                if (fillColor !== undefined) {
                    style = new Style({
                        stroke: new Stroke({
                            color: "rgba(255, 255, 0, 0.5)",
                            width: 3,
                        })
                    });
                } else {
                    style = new Style({
                        stroke: new Stroke({
                            color: "rgba(255, 0, 0, 0.5)",
                            width: 3,
                        })
                    });
                }
                let addLayer = new VectorLayer({
                    source: addSource,
                    style: style
                });
                addLayer.name = layerName;
                addLayer.tag = "addLayer";
                this.mapData.addLayer(addLayer);
                return new Promise((resolve) => { resolve(); });
            }
        },
        // 加载服务图层
        addWmsLayer(layerName, layerUrl) {
            const bboxArray = getQueryVariable(layerUrl, "bbox").split(",");
            let addlayer = new TileLayer({
                // 注意这里用的是TileWMS类而不是ImageWMS类
                source: new TileWMS({
                    url: layerUrl, // WMS服务的URL.
                    // WMS请求参数
                    params: {
                        LAYERS: layerName, // 请求的图层名
                    },
                    serverType: "geoserver", // 服务器类型
                }),
            });
            addlayer.name = layerName;
            addlayer.tag = "addLayer";
            addlayer.bboxArray = bboxArray;
            this.mapData.addLayer(addlayer);
        },
        addWmtsLayer(layerName, layerUrl) {
            const bboxArray = getQueryVariable(layerUrl, "bbox").split(",");
            let projection = getProj("EPSG:4490");
            let projectionExtent = projection.getExtent(); // web墨卡托投影坐标系的四至
            let width = getWidth(projectionExtent); //web墨卡托投影坐标系的水平宽度，单位：米
            let origin = getTopLeft(projectionExtent); //坐标系起点
            // 计算瓦片分辨率数组，每片分辨率256
            let resolutions = [];
            let matrixIds = [];
            for (let z = 0; z < 22; ++z) {
                resolutions[z] = width / (256 * Math.pow(2, z + 1));
                matrixIds[z] = "EPSG:4490:" + z;
            }
            let tileGrid = new WMTSTileGrid({
                origin: origin,
                tileSize: [256, 256],
                resolutions: resolutions,
                matrixIds: matrixIds,
            });
            //通过WMTS加载
            let addlayer = new TileLayer({
                opacity: 0.7, //图层透明度
                source: new WMTS({
                    url: layerUrl, //WMTS服务基地址
                    matrixSet: "EPSG:4490", //投影坐标系设置矩阵
                    format: "image/png", //图片格式
                    projection: projection, //数据的投影坐标系
                    //瓦片网格对象
                    tileGrid: tileGrid,
                    wrapX: true,
                }),
            });
            addlayer.name = layerName;
            addlayer.tag = "addLayer";
            addlayer.bboxArray = bboxArray;
            this.mapData.addLayer(addlayer);
        },
        addVertorLayer(layerName, layerUrl) {
            let bboxArray;
            if (layerUrl.includes("bbox")) {
                bboxArray = getQueryVariable(layerUrl, "bbox").split(",");
            }
            let projection = getProj("EPSG:4490");
            let projectionExtent = projection.getExtent(); // web墨卡托投影坐标系的四至
            let width = getWidth(projectionExtent); //web墨卡托投影坐标系的水平宽度，单位：米
            let origin = getTopLeft(projectionExtent); //坐标系起点
            // 计算瓦片分辨率数组，每片分辨率256
            let resolutions = [];
            for (let z = 0; z < 22; ++z) {
                resolutions[z] = width / (256 * Math.pow(2, z + 1));
            }
            let addlayer = new VectorTileLayer({
                declutter: true,
                source: new VectorTileSource({
                    tileGrid: new TileGrid({
                        origin: origin,
                        resolutions: resolutions,
                        tileSize: [256, 256],
                    }),
                    projection: projection,
                    format: new MVT(),
                    url: layerUrl,
                }),
            });
            addlayer.name = layerName;
            addlayer.tag = "addLayer";
            addlayer.bboxArray = bboxArray === undefined ? "" : bboxArray;
            this.mapData.addLayer(addlayer);
        },
        addWfsLayer(layerUrl) {

        },
        // 删除一个图层
        delete2DMapLayer(layerName) {
            //先清空地图上的图层
            this.mapData.getLayers().forEach(layer => {
                if (layer.name === layerName) {
                    this.mapData.removeLayer(layer);
                }
            });
        },
        // 清除手动添加的图层
        clear2DMapLayer() {
            // 删除指定图层
            this.mapData.getLayers().forEach(layer => {
                if (layer === undefined || layer["tag"] === undefined) {
                    return;
                }
                if (layer["tag"] === "addLayer") {
                    this.mapData.removeLayer(layer);
                }
            });
        },
        // 添加overlay
        add2DMapOverlay(markFlag, markIcon, lnglat) {
            const mapDiv = document.getElementById("onlineMap");
            // 新生成一个overlay的div
            let overLayElement = document.createElement("div");
            overLayElement.id = "eventRangerOverlay";
            mapDiv.appendChild(overLayElement);
            // 创建一个div并设置样式和图标名
            let problemPointDiv = document.createElement("div");
            problemPointDiv.classList.add("eventRangerOverlay-node");
            problemPointDiv.classList.add(markIcon);
            overLayElement.appendChild(problemPointDiv);
            // 设置添加的overlay
            let overlay = new Overlay({
                id: markFlag,
                element: overLayElement,
                autoPan: true,
                position: lnglat,
                positioning: "center-center",
            });
            overlay.tag = "addOverlay";
            this.mapData.addOverlay(overlay);
        },
        // 删除overlay
        delete2DMapOverlay(markFlag) {
            this.mapData.getOverlays().forEach(overlay => {
                if (overlay.id === markFlag) {
                    this.mapData.removeOverlay(overlay);
                }
            });
        },
        // 清除所有的overlay
        clear2DMapOverlay() {
            this.mapData.getOverlays().forEach(overlay => {
                if (overlay.tag === "addOverlay") {
                    this.mapData.removeOverlay(overlay);
                }
            });
        },
        // 绘制结束
        drawEnd2DMap() {
            return JSON.stringify(this.drawGeoJson);
        },
        // 要素查询
        query2DMapFeature(point, inputLayer) {
            this.$openLoading();
            return this.getDataAxios.queryFeature({ point, inputLayer })
                .finally(() => {
                    this.$closeLoading();
                });
        },
        // 叠加分析
        overlayLayers2DMapIntersect(inputLayer, referLayer) {
            return this.mapOverlayAnalysis("intersects", inputLayer, referLayer);
        },
        overlayLayers2DMapUnion(inputLayer, referLayer) {
            return this.mapOverlayAnalysis("union", inputLayer, referLayer);
        },
        overlayLayers2DMapExcept(inputLayer, referLayer) {
            return this.mapOverlayAnalysis("except", inputLayer, referLayer);
        },
        mapOverlayAnalysis(overlayType, inputLayer, referLayer) {
            this.$openLoading();
            return this.getDataAxios.mapOverlayAnalysis({
                overlayType, inputLayer, referLayer
            }).finally(() => {
                this.$closeLoading();
            });
        },
        // 地图居中显示
        setMapCenter(bboxArray) {
            let view = this.mapData.getView();
            view.setCenter([
                (parseFloat(bboxArray[0]) + parseFloat(bboxArray[2])) / 2,
                (parseFloat(bboxArray[1]) + parseFloat(bboxArray[3])) / 2,
            ]);
        },
    },
};
