export const ExistBuildingData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [119.09863677925395, 25.359796140153872],
            [119.09845819799668, 25.359659296278572],
            [119.09826798501001, 25.35986114099464],
            [119.09844656626728, 25.35999593221181],
            [119.09863677925395, 25.359796140153872]
          ]
        ]
      },
      properties: { Id: "0" }
    }
  ]
};
export const ApplyBuildingData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [119.09875175835113, 25.359842592012974],
            [119.09890107536802, 25.359698698132288],
            [119.0988218975794, 25.359632535870567],
            [119.09867402673216, 25.359778599005736],
            [119.09875175835113, 25.359842592012974]
          ]
        ]
      },
      properties: { Id: "1" }
    }
  ]
};
