/**
 * @Author: chensizhong
 * @Date: 2021-05-08 11:17:36
 * @LastEditors: chensizhong
 * @LastEditTime: 2021-05-08 14:44:19
 */
const validateObject = {
  validataIllegalString(rule, value, callback) {
    if (
      /[!！@#\$%\^&\*\,\，\'\‘\’\:\?\？\<\>\|\"\“\”\{\}\[\]\=\+\(\)\、\`\~\《\》\-\：\；\;\、\/\\]+/g.test(
        value
      )
    ) {
      callback(new Error("您的输入含有非法字符"));
    } else {
      callback();
    }
  },
  validataTaxRateFalse(rule, value, callback) {
    let validate = isNaN(Number(value));
    if (validate) {
      callback(new Error("输入应该为数字"));
    } else if (value > 100) {
      callback(new Error("填写的比例不应大于100"));
    } else if (value < 0) {
      callback(new Error("填写的比例不应小于0"));
    } else {
      callback();
    }
  },
  validataTaxRate(rule, value, callback) {
    if (value === "" || value === undefined) {
      callback(new Error("内容不能为空"));
    } else {
      let validate = isNaN(Number(value));
      if (validate) {
        callback(new Error("输入应该为数字"));
      } else if (value > 100) {
        callback(new Error("填写的比例不应大于100"));
      } else if (value < 0) {
        callback(new Error("填写的比例不应小于0"));
      } else {
        callback();
      }
    }
  },
  validateLinkUrl(rule, value, callback) {
    let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
    if (value === "" || value === undefined) {
      callback(new Error("邮箱地址不能为空"));
    } else if (!reg.test(value)) {
      callback(new Error("请输入有效的地址"));
    } else {
      callback();
    }
  },
  validateIdCard(rule, value, callback) {
    if (value === "" || value === undefined) {
      callback(new Error("内容不能为空"));
    } else {
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error("输入的身份证号码不符合规范"));
      } else {
        callback();
      }
    }
  },
  validateTel(rule, value, callback) {
    if (value === "" || value === undefined) {
      callback(new Error("手机号码不能为空"));
    } else {
      if (!/^1[23456789]\d{9}$/.test(value)) {
        callback(new Error("输入的手机号码不符合规范"));
      } else {
        callback();
      }
    }
  },
  validateFloatFalse(rule, value, callback) {
    let validate = isNaN(Number(value));
    if (validate) {
      callback(new Error("输入应该为数字"));
    } else if (value < 0) {
      callback(new Error("输入应该大于等于0"));
    } else if ((value * 100).toFixed(1) % 1 !== 0) {
      callback(new Error("最多允许2位小数"));
    } else {
      callback();
    }
  },
  validateFloat(rule, value, callback) {
    if (value === "" || value === null) {
      callback(new Error("内容不能为空"));
    } else {
      if (isNaN(Number(value))) {
        callback(new Error("输入应该为数字"));
      } else if (value < 0) {
        callback(new Error("输入应该大于等于0"));
      } else if ((value * 100).toFixed(1) % 1 !== 0) {
        callback(new Error("最多允许2位小数"));
      } else {
        callback();
      }
    }
  },
  validateIntFlase(rule, value, callback) {
    if (isNaN(Number(value))) {
      callback(new Error("输入应该为数字"));
    } else if (value < 0) {
      callback(new Error("输入应该大于等于0"));
    } else if (value % 1 !== 0) {
      callback(new Error("应该为整数"));
    } else {
      callback();
    }
  },
  validateInt(rule, value, callback) {
    if (value === "" || value === undefined) {
      callback(new Error("内容不能为空"));
    } else {
      if (isNaN(Number(value))) {
        callback(new Error("输入应该为数字"));
      } else if (value < 0) {
        callback(new Error("输入应该大于等于0"));
      } else if (value % 1 !== 0) {
        callback(new Error("应该为整数"));
      } else {
        callback();
      }
    }
  },
  validateStr50(rule, value, callback) {
    if (value.length > 50) {
      callback(new Error("输入内容不得超过50个字符"));
    } else {
      callback();
    }
  },
  validateTrim(rule, value, callback) {
    if (value.trim() === "") {
      callback(new Error("输入不能为纯空格"));
    }
    callback();
  },
};

export default {
  install(Vue) {
    Vue.prototype.validateObject = validateObject;
  },
};
