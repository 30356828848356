<template>
  <el-form :model="model" class="telephoneForm">
    <el-form-item
      prop="telephone"
      :rules="[
        {
          pattern:
            /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
          message: '输入必须为电话',
          trigger: ['blur'],
        },
      ]"
    >
      <el-input
        type="telephone"
        v-model.number="model.telephone"
        :disabled="disabled"
        :readonly="readonly"
        :size="size"
        @blur="blur"
        :maxlength="30"
        :minlength="1"
      >
        <i slot="prefix" class="el-icon-phone"></i
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    value: {
      type: null,
      default: () => {
        return "";
      },
    },
    size: {
      type: null,
      default: () => {
        return "";
      },
    },
    type: {
      type: null,
      default: () => {
        return "";
      },
    },
    readonly: {
      type: null,
      default: () => {
        return false;
      },
    },
    disabled: {
      type: null,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      model: {
        telephone: "",
      },
    };
  },
  methods: {
    blur() {
      this.$emit("update:value", this.model.telephone);
    },
  },
};
</script>
<style lang="less" scoped>
.telephoneForm {
  /deep/.el-form-item {
    margin: 0;
  }
  /deep/ .el-form-item__content {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}
</style>
