/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
/**
 * Created by csz on 2022/03/15.
 */
import Vue from "vue";
let loadingInstance = null;
const utils = {
  openLoading(options) {
    loadingInstance = Vue.prototype.$loading({
      fullscreen: true,
      customClass: "pageLoading",
      spinner: "pageLoadingImg",
      background: "rgba(0,0,0,0.6)",
      text: "加载中...",
      ...options,
    });
  },
  closeLoading() {
    if (
      loadingInstance &&
      loadingInstance.close &&
      typeof loadingInstance.close === "function"
    ) {
      loadingInstance.close();
    }
  },
};
export default {
  install(Vue) {
    Vue.prototype.$openLoading = utils.openLoading;
    Vue.prototype.$closeLoading = utils.closeLoading;
  },
};
