<template>
  <a
    :disabled="disabled"
    :target="target"
    :style="{
      color: textColor,
      fontSize: `${fontSize}px`,
      fontWeight: fontWeight,
      textDecoration: underline,
    }"
    :href="address"
    ><i :class="icon" v-if="icon"></i>
    <span>{{ text }}</span>
  </a>
</template>
<script>
export default {
  props: {
    disabled: {
      type: null,
      default: () => {
        return null;
      },
    },
    target: {
      type: null,
      default: () => {
        return null;
      },
    },
    underline: {
      type: null,
      default: () => {
        return null;
      },
    },
    address: {
      type: null,
      default: () => {
        return null;
      },
    },
    icon: {
      type: null,
      default: () => {
        return null;
      },
    },
    textColor: {
      type: null,
      default: () => {
        return null;
      },
    },
    fontSize: {
      type: null,
      default: () => {
        return null;
      },
    },
    fontWeight: {
      type: null,
      default: () => {
        return null;
      },
    },
    text: {
      type: null,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {};
  },
};
</script>
