<template>
  <div>
    <div v-if="loading" class="dialog_content_wrap" />
    <el-dialog
      class="confirm-dialog-style"
      :class="className"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      :append-to-body="dialogParams.appendToBody"
      :top="dialogParams.top"
      :width="width"
    >
      <!-- 弹窗中心区域 -->
      <slot />
      <!-- 弹窗文案中心区域 -->
      <slot name="text" />
      <!-- 弹窗底部按钮 -->
      <span slot="footer" v-if="dialogType === 'confirm'" class="dialog-footer">
        <el-button
          plain
          class="edit-dialog-btn"
          size="small"
          type="info"
          @click="cancel"
          >{{ cancelText }}</el-button
        >
        <el-button
          class="edit-dialog-btn"
          size="small"
          :loading="loading"
          type="primary"
          @click="submit"
          >{{ confirmText }}</el-button
        >
      </span>
      <span slot="footer" v-if="dialogType === 'applyConfirm'" class="dialog-footer">
        <el-button
          plain
          class="edit-dialog-btn"
          size="small"
          type="info"
          @click="cancel"
          >{{ cancelText }}</el-button
        >
        <el-button
          class="edit-dialog-btn"
          size="small"
          :loading="loading"
          type="primary"
          :disabled="!isNotDisabled"
          @click="submit"
          >{{ confirmText }}</el-button
        >
      </span>
      <span
        slot="footer"
        v-if="dialogType === 'dataconfirm'"
        class="dialog-footer"
      >
        <el-button
          plain
          class="edit-dialog-btn"
          size="small"
          type="info"
          @click="cancel"
          >{{ cancelText }}</el-button
        >
        <el-button
          class="edit-dialog-btn"
          size="small"
          type="primary"
          :loading="loading"
          v-if="isshow"
          @click="verify"
          >{{ verifyText }}</el-button
        >
        <el-button
          class="edit-dialog-btn"
          size="small"
          :loading="loading"
          :disabled="isdisabled"
          type="primary"
          @click="submit"
          >{{ confirmText }}</el-button
        >
      </span>
      <span slot="footer" v-if="dialogType === 'info'" class="dialog-footer">
        <el-button
          class="edit-dialog-btn"
          size="small"
          type="primary"
          @click="cancel"
          >{{ cancelText }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 *
 * @components 弹窗组件
 * @props loading // 确认按钮的加载遮罩
 * @props show // 弹窗的显示隐藏表示，子组件值改变通过emit和sync修饰符修改父组件传参
 * @props title // 弹窗标题
 * @props width // 弹窗宽度
 * @props dialogParams // 弹窗与页面顶部的距离
 * @props dialogType // confirm表示弹窗有确定，取消两个按钮， info表示只有一个取消按钮
 * @props confirmText，cancelText // 确认按钮和取消按钮的的文本
 * @author csz 2020/05/10
 *
 */
export default {
  name: "BaseConfirm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isshow: {
      type: Boolean,
      default: false,
    },
    isdisabled: {
      type: Boolean,
      default: false,
    },
    isNotDisabled: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "提示",
    },
    param: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: "50%",
    },
    dialogParams: {
      type: Object,
      default: () => {
        // 嵌套对话框时修改以下参数
        return {
          top: "20vh",
          appendToBody: true,
        };
      },
    },
    dialogType: {
      type: String,
      default: "confirm",
    },
    confirmText: {
      type: String,
      default: "确定",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    verifyText: {
      type: String,
      default: "数据包校验",
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    // 双向监听，监听父组件传入的改变和子组件自主触发的改变
    show: {
      handler() {
        this.dialogVisible = this.show;
      },
      immediate: true,
    },
    dialogVisible: {
      handler(val) {
        this.$emit("update:show", val);
      },
    },
  },
  methods: {
    // emit按钮的点击事件给父组件
    cancel() {
      this.$emit("cancel");
    },
    verify() {
      this.$emit("verify");
    },
    submit() {
      this.$emit("confirm");
    },
  },
};
</script>

<style
  type="text/less"
  lang="less"
>
.dialog_content_wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000000000000;
}

.confirm-dialog-style {
  .el-dialog {
    border-radius: 8px;
  }

  .el-dialog__header {
    padding: 10px 20px;
    background-color: #e8e8ea;
    border-radius: 8px 8px 0 0;

    .el-dialog__close {
      position: relative;
      top: 2px;
      font-size: 20px;
    }
  }

  .el-dialog__title {
    display: inline-block;
    line-height: 24px;
    font-size: 16px;
    color: #303133;
    border-bottom: 1px solid #e7e8eb;
  }

  .el-dialog__headerbtn {
    top: 10px;
    right: 10px;
    font-size: 20px;
  }

  .el-dialog__body {
    padding: 20px 30px 15px 15px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }

  .el-dialog__footer {
    padding: 15px 20px;
    text-align: right;
    border-top: 1px solid #e7e8eb;
  }

  .dialog-footer {
    .edit-dialog-btn {
      padding: 8px 18px;
      border-radius: 4px;
    }
  }
}
</style>
