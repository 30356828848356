<template>
  <div class="uploader" :style="uploaderStyle">
    <van-uploader
        v-model="fileLists"
        :max-count="limit"
        :max-size="limitSize * 1024 * 1024"
        :disabled="disabled"
        :accept="accept"
        :style="uploaderStyle"
        @oversize="overflowSize"
        :before-read="beforeRead"
        :before-delete="beforeDelete"

    >
      <van-button
          :type="buttonType"
          :size="buttonSize"
          :plain="buttonPlain"
          :round="buttonRound"
          :icon="buttonIcon"
          :color="buttonColor"
          :icon-position="buttonIconPosition"
          :disabled="disabled"
      >{{ buttonName }}
      </van-button>
    </van-uploader>
  </div>
</template>
<script>
import { Toast } from "vant";
import { BASE_SERVER_URL } from "@/config/ip";
import axios from "axios";
/**
 *
 * @components 文件上传组件
 * @props placeholderText // 上传的文字提示
 * @props showPlaceholderText // 是否显示上传的文字提示
 * @props limitTypeArray // 限制可以上传的文件格式
 * @props limitSize // 上传文件的大小，按MB大小，只能传入数字
 * @props disabled // 是否禁用
 * @props limit // 上传文件的数量
 */
export default {
  name: "AppFileUploadFileComponents",
  data() {
    return {
      fileLists: [],
      ids: [],
    };
  },
  props: {
    buttonName: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonSize: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonType: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonIcon: {
      type: String,
      default: () => {
        return "";
      },
    },
    buttonPlain: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    buttonColor: {
      type: String,
      require: false
    },
    buttonRound: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    buttonIconPosition: {
      type: String,
      default: () => {
        return "left";
      },
    },
    limit: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    accept: {
      type: String,
      default: () => {
        return "";
      },
    },
    limitSize: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    limitTypeArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fileIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  computed: {
    uploaderStyle() {
      return {
        width: "100%",
        height: "100%",
      };
    },
  },
  watch: {
    // 双向监听，监听父组件传入的改变和子组件自主触发的改变
    fileList: {
      handler() {
        this.fileLists = this.fileList;
      },
      immediate: true,
    },
    fileLists: {
      handler(val) {
        this.$emit("update:fileList", val);
      },
    },
    fileIds: {
      handler(val) {
        if (val) {
          this.getUrlByFileId();
        }
      }
    }
  },
  created() {
    if (this.fileIds) {
      this.getUrlByFileId();
    }
  },
  methods: {
    overflowSize() {
      Toast(`文件大小不能超过${this.limitSize}MB`);
    },
    beforeRead(file) {
      // 如果有文件限制
      let suffixArr = file.name.split(".");
      if (this.limitTypeArray.indexOf("audio/*,video/*,image/*") === -1) {
        let allSame = this.limitTypeArray.some((type) => {
          return suffixArr[suffixArr.length - 1] === type;
        });
        if (!allSame && this.limitTypeArray.length > 0) {
          Toast("暂不支持该类型的文件上传");
          return false;
        }
      };
      // 如果有大小限制
      if (this.limitSize !== 0) {
        if (file.size > this.limitSize * 1024 * 1024) {
          return false;
        }
      };
      let param = {
        file: file,
      };
      return this.getDataAxios.uploadImgae(param)
          .then((res) => {
            if (res.data) {
              this.ids.push(res.data.id);
            }
          }).catch((err) => {
            this.getDataAxios.notifyError(err);
          });
    },
    beforeDelete(file) {
      this.ids.splice(this.ids.indexOf(file.id), 1);
      this.fileLists = [];
      this.$emit("update:fileIds", this.ids.join(","));
    },
    getUrlByFileId() {
      let authenticationParam =
          localStorage.getItem("token") ||
          localStorage.getItem("Authentication") ||
          "";
      const { Authentication, id, userName } = this.$route.query;
      if (Authentication) {
        localStorage.setItem("Authentication", Authentication);
        authenticationParam = Authentication;
        if (id) {
          localStorage.setItem("id", id);
        }
        if (userName) {
          localStorage.setItem("userName", userName);
        }
      }
      let config = {
        "method": "get",
        "headers": {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          Authentication: authenticationParam,
        },
        "timeout": 1800000,
        "url": ""
      };
      config.url = `${BASE_SERVER_URL}/api/file/fileIds?fileIds=${this.fileIds}&Authentication=${authenticationParam}`;
      axios(config).then((res)=>{
        if (res.data) {
          this.fileLists = [];
          res.data.data.forEach(item => {
            this.fileLists.push({
              //url: `${BASE_SERVER_URL}/api/file/download/${item.id}?Authentication=${authenticationParam}`,
              name: item.name,
              isImage: false
            });
          });
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
/deep/.van-uploader__input-wrapper {
  width: 100%;
}
</style>
