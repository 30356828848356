<template>
  <div>
    <!-- 地图放大缩小等工具栏 -->
    <div
        v-if="mapOptions.zoomData.show"
        class="zoom-box"
        :style="setZoomStyle"
    >
      <div
          style="margin-bottom: -1px"
          class="interaction_item"
          @click="scaleMap('big')"
      >
        <i class="el-icon-plus"></i>
      </div>
      <div @click="scaleMap('small')" class="interaction_item">
        <i class="el-icon-minus"></i>
      </div>
    </div>
    <!-- 底部工具栏 -->
    <div
        v-if="mapOptions.posistonZoomData.show"
        class="bottom_box"
        :style="posistionZoomStyle"
    >
      <div class="srs_box">
        <span style="margin-right: 10px">当前坐标:</span>
        <div id="srsbox" style="display: inline-block"></div>
      </div>
      <span class="split_span"></span>
      <template>
        <div class="layer_level_box">缩放等级: {{ mapZoom }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherControl",
  props: {
    mapData: {
      type: Object,
      require: true
    },
    mapOptions: {
      type: Object,
      require: true
    },
    mapZoom: {
      type: [Number, String],
      require: true
    }
  },
  data() {
    return {};
  },
  computed: {
    // 当前工具栏的偏移量
    posistionZoomStyle() {
      const left = this.mapOptions.posistonZoomData.left;
      const top = this.mapOptions.posistonZoomData.top;
      return { left: `${left}%`, top: `${top}%` };
    },
    // 当前缩放小工具的偏移量
    setZoomStyle() {
      const left = this.mapOptions.zoomData.left;
      const top = this.mapOptions.zoomData.top;
      return { left: `${left}%`, top: `${top}%` };
    }
  },
  methods: {
    // 缩放地图
    scaleMap(type) {
      // 最大缩放和最小缩放
      const minZoom = this.mapOptions.minZoom;
      const maxZoom = this.mapOptions.maxZoom;
      let view = this.mapData.getView();
      let zoom = view.getZoom();
      let sum = type === "big" ? 1 : -1;
      if (type === "small" && zoom <= minZoom) {
        return;
      }
      if (type === "big" && zoom >= maxZoom) {
        return;
      }
      let result = zoom + sum;
      // 缩放
      view.setZoom(result);
      // 关闭测量相关
      this.$emit("handleOnSetZoom");
    },
  },
};
</script>

<style type="text/less" lang="less">
/* 地图放大缩小 */
.zoom-box {
  position: absolute;
  .interaction_item {
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: #ffffff;
    box-shadow: 0px 2px 2px 0px #cccccc;
    border: 1px solid #dcdfe6;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
    i {
      font-size: 16px;
    }
  }
  .interaction_item:hover {
    i {
      color: #276bd7 !important;
    }
  }
  .interaction_item:focus {
    outline: none;
    i {
      color: #276bd7 !important;
    }
  }
}
/* 底部工具栏 */
.bottom_box {
  position: absolute;
  color: white;
  width: 350px;
  height: 30px;
  line-height: 30px;
  padding: 0 30px 0 20px;
  font-size: 12px;
  z-index: 1;
  /* 分割线 */
  .split_span {
    position: relative;
    top: 2px;
    display: inline-block;
    background-color: #333131;
    width: 2px;
    height: 13px;
    margin: 0 30px 0 0;
  }
  /* 当前坐标 */
  .srs_box {
    display: inline-block;
    .custom-mouse-position {
      width: 150px;
    }
  }
  /* 缩放等级 */
  .layer_level_box {
    display: inline-block;
  }
}
/* 底部工具栏 */
.bottom_box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
</style>
