<template>
  <div id="basemap-container" class="app-expand">
    <div class="app-mapType">
      <div class="mapTypeCard normal" @click="toggleBaseLayer('vec')">
        <span>矢量</span>
      </div>
      <div class="mapTypeCard earth" @click="toggleBaseLayer('ter')">
        <span>地形</span>
      </div>
      <div
          class="mapTypeCard panorama choosedType"
          @click="toggleBaseLayer('img')"
      >
        <span>影像</span>
      </div>
    </div>
  </div>
</template>

<script>
import { get as getProj } from "ol/proj.js";
import { getWidth, getTopLeft } from "ol/extent";
import TileLayer from "ol/layer/Tile";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import WMTS from "ol/source/WMTS";
import XYZ from "ol/source/XYZ";

export default {
  name: "AppBaseMapControl",
  props: {
    mapData: {
      type: Object,
      require: true
    },
    baseLayer: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      currentBaseLayer: null
    };
  },
  computed: {
  },
  methods: {
    // 底图切换
    toggleBaseLayer(type) {
      let layers = this.mapData.getLayers();
      let mapConfig = this.baseLayer[type];
      if (type !== this.currentBaseLayer && layers.getArray().length > 2) {
        layers.setAt(1, this.WMTSLayer(mapConfig.m.url, mapConfig.m.layerName));
        layers.setAt(2, this.WMTSLayer(mapConfig.c.url, mapConfig.c.layerName));
      } else {
        this.mapData.addLayer(
            this.WMTSLayer(mapConfig.m.url, mapConfig.m.layerName)
        );
        this.mapData.addLayer(
            this.WMTSLayer(mapConfig.c.url, mapConfig.c.layerName)
        );
      }
      // 新的插入
      this.currentBaseLayer = type;
    },
    //通过gridset计算行列号加载wmts
    WMTSLayer(baseUrl, layerName) {
      const projection = getProj("EPSG:3857");
      const projectionExtent = projection.getExtent();
      const size = getWidth(projectionExtent) / 256;
      const resolutions = new Array(19);
      const matrixIds = new Array(19);
      for (let z = 0; z < 19; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z); //如果图层加载失败，z+1
        matrixIds[z] = z;
      }
      return new TileLayer({
        opacity: 0.7,
        source: new WMTS({
          url: baseUrl,
          layer: layerName,
          matrixSet: "w",
          format: "tiles",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          style: "default",
          wrapX: true,
        }),
      });
    },
    //新建wmts图层,name为图层名
    WMTSLayer_xyz(baseUrl, layerName) {
      let source = new XYZ({
        url: baseUrl,
        projection: "EPSG:3857",
      });
      let layer = new TileLayer({
        source: source,
      });
      layer.name = layerName;
      layer.highlight = true;
      return layer;
    },
  },
};
</script>

<style type="text/less" lang="less">
/* 底图切换 */
#basemap-container {
  position: absolute;
  right: calc(50% - 150px);
  top: 30px;
  width: 300px;
  height: 80px;
}
/* 右下角底图切换-收缩情况下的样式 */
.app-mapType {
  height: 80px;
  cursor: pointer;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  width: 110px;
  background-color: rgba(255, 255, 255, 0);
  .panorama {
    background-image: url(../../../../assets/img/maptype.png);
  }
  .normal {
    z-index: 1;
    background-position: 0 0;
    right: 20px;
  }
  .mapTypeCard {
    height: 60px;
    width: 86px;
    position: absolute;
    border-radius: 2px;
    top: 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border: 1px solid rgba(153, 153, 153, 0.42);
    background: url(../../../../assets/img/maptype.png) no-repeat 0 0;
    background-size: 86px 240px;
    -webkit-transition-property: right, background-image;
    transition-property: right, background-image;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
  .normal .switch-box input.switch {
    left: 5px;
  }
  .normal .switch-box p {
    margin-left: 20px;
  }
  .switch-box p {
    display: inline-block;
    margin-left: 25px;
  }
  .switch-box input.switch {
    position: absolute;
    left: 8px;
    top: 4px;
    cursor: pointer;
  }
  .mapTypeCard.active span,
  .mapTypeCard:hover span {
    background-color: #3385ff;
  }
  .mapTypeCard span {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    padding: 3px 3px 2px 4px;
    font-size: 12px;
    height: 12px;
    line-height: 12px;
    color: #fff;
    background-color: #999;
    border-top-left-radius: 2px;
  }
  .earth {
    background-position: 0 -70px;
  }
  .satellite,
  .earth {
    right: 15px;
    z-index: 2;
    background-position: 0 -80px;
  }
  .switch-box {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 22px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
  }
  .panorama {
    z-index: 3;
    right: 10px;
    background-image: url(../../../../assets/img/maptype.png);
    background-position: 0 -160px;
    border-left: 1px solid rgba(153, 153, 153, 0.6);
  }
  .three-dimensional {
    right: 30px;
    z-index: 0;
    background-position: 0 -160px;
  }
}
/* 右下角底图切换-伸展情况下的样式 */
.app-expand .app-mapType {
  .mapTypeCard.active {
    border: 1px solid #3385ff;
  }
  .mapTypeCard {
    background-image: url(../../../../assets/img/maptype.png);
  }
  .normal {
    right: 202px;
  }
  .mapTypeCard {
    border: 1px solid rgba(255, 255, 255, 0);
    background-image: url(../../../../assets/img/maptype.png);
  }
  .satellite,
  .earth {
    right: 106px;
  }
  .mapTypeCard {
    border: 1px solid rgba(255, 255, 255, 0);
    background-image: url(../../../../assets/img/maptype.png);
  }
}
</style>
