<template>

  <div ref="echartsBar" class="echarts-bar">
  </div>
</template>
<script>
export default {
  props: {
    layout: {
      type: Object
    },
    smooth: [],
    xAxis: [],
    lineData: [],
    title: [],
    titleShow: [Boolean],
    legend: [Boolean],
    toolTip: [Boolean], 
    backgroundColor: {
      type: Boolean
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  computed: {
    
  },
  watch: {
    layout: {
      handler() {
        this.getEcharts();
      },
      deep: true,
    },
    smooth() {
      this.getEcharts();
    },
    xAxis() {
      this.getEcharts();
    },
    lineData() {
      this.getEcharts();
    },
    title() {
      this.getEcharts();
    },
    titleShow() {
      this.getEcharts();
    },
    legend() {
      this.getEcharts();
    },
    toolTip() {
      this.getEcharts();
    },
    backgroundColor() {
      this.getEcharts();
    }
  },
  mounted() {
    this.getEcharts();
  },
  methods: {
    getEcharts() {
      // 基础折线图数据
      let  option = { 
        backgroundColor: "",
        title: {
          show: true,
          text: "",
          left: "43%",
          top: "6%",
          textStyle: {
            color: "#6a6a6a",
            fontSize: 16,
          }
        },
        tooltip: {
          show: true, 
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: []
        },
        legend: {
          show: true,
          left: "left"
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: false,

          }
        ],
      };

      // 堆叠面积图数据
      let  stackedAreaOption = {  
        title: {
          show: true,
          text: "",
          left: "43%",
          top: "6%",
          textStyle: {
            color: "#6a6a6a",
            fontSize: 16,
          }
        },
        tooltip: {
          show: true, 
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        legend: {
          show: true,
          left: "left"
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: []
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: []
      };

      // 设置data折线图数据
      let lineData = typeof (this.lineData) === "string" ? JSON.parse(this.lineData) : this.lineData;

      // 切换模式 柱状图 or 条形图

      if (this.smooth == 1) {   // 折线图

        // 设置x轴数据
        let xAxisData = typeof (this.xAxis) === "string" ? JSON.parse(this.xAxis) : this.xAxis;
        option.xAxis.data = xAxisData;

        // 设置标题
        option.title.text = this.title;

        // 设置提示框
        option.tooltip.show =  this.toolTip;

        // 设置图例
        option.legend.show =  this.legend;

        // 设置标题开关
        option.title.show =  this.titleShow;

        // 设置折线data数据
        lineData.forEach(item => {
          let lineData = [];
          item.type = "line";
          lineData.push(item); 
        });
        option.series = lineData;

        // 设置折线图数据
        // option = attribute.option;

      } else if (this.smooth == 2) { //曲线图
      
        // 设置x轴数据
        let xAxisData = typeof (this.xAxis) === "string" ? JSON.parse(this.xAxis) : this.xAxis;
        option.xAxis.data = xAxisData;

        // 设置标题
        option.title.text = this.title;

        // 设置提示框开关
        option.tooltip.show =  this.toolTip;

        // 设置图例开关
        option.legend.show =  this.legend;

        // 设置标题开关;
        option.title.show =  this.titleShow;



        // 曲线图设置
        // attribute.option.series[0].smooth = true;
        // 设置折线data数据
        lineData.forEach(item => {
          let lineData = [];
          item.type = "line";
          item.smooth = true;
          lineData.push(item); 
        });
        option.series = lineData;

        // option =  attribute.option;
      } else if (this.smooth == 3) {  // 基础面积图

        // 设置x轴数据
        let xAxisData = typeof (this.xAxis) === "string" ? JSON.parse(this.xAxis) : this.xAxis;
        option.xAxis.data = xAxisData;

        // 设置标题
        option.title.text = this.title;

        // 设置提示框开关
        option.tooltip.show =  this.toolTip;

        // 设置图例开关
        option.legend.show =  this.legend;

        // 设置标题开关;
        option.title.show =  this.titleShow;


        // 设置折线data数据
        lineData.forEach(item => {
          let lineData = [];
          item.type = "line";
          item.areaStyle = {};
          lineData.push(item); 
        });
        option.series = lineData;


        // 设置折线图数据
        // option = attribute.option;

      } else {  // 堆叠面积图数据
      

        // 设置x轴数据
        let xAxisData = typeof (this.xAxis) === "string" ? JSON.parse(this.xAxis) : this.xAxis;
        stackedAreaOption.xAxis[0].data = xAxisData;

        // 设置标题
        stackedAreaOption.title.text = this.title;

        // 设置图例开关
        stackedAreaOption.tooltip.show =  this.toolTip;

        // 设置提示框开关
        stackedAreaOption.legend.show =  this.legend;

        // 设置标题开关;
        stackedAreaOption.title.show =  this.titleShow;

        
        // 设置折线data数据
        lineData.forEach(item => {
          let lineData = [];
          item.type = "line";
          item.stack = "Total";
          item.areaStyle = {};
          item.emphasis = {
            focus: "series"
          };
          lineData.push(item); 
        });

        stackedAreaOption.series = lineData;

        option = stackedAreaOption;
      }

      // 深色模式切换
      if (this.backgroundColor) {
        option.backgroundColor = "#100c2a";
      } else if (option.title) {
        option.backgroundColor = "#fff";
      }
      if (this.title) {
        option.title.text = this.title;
      }
      // echarts渲染DOM
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart =  this.$echarts.init(this.$refs.echartsBar, "dark");
      this.myChart.setOption(option);
    }
  },
};
</script>

<style lang="less" scoped>
.echarts-bar {
  width: 100%;
  height: 100%;
}
</style>