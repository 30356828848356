<template>
  <div ref="echartsBar" className="echarts-bar"></div>
</template>
<script>
export default {
  props: {
    layout: {
      type: Object,
    },
    smooth: [],
    gaugeData: [],
    backgroundColor: {
      type: Boolean,
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  computed: {},
  watch: {
    layout: {
      handler() {
        this.getEcharts();
      },
      deep: true,
    },
    smooth() {
      this.getEcharts();
    },
    gaugeData() {
      this.getEcharts();
    },
    backgroundColor() {
      this.getEcharts();
    },
  },
  mounted() {
    this.getEcharts();
  },
  methods: {
    getEcharts() {
      // 仪表盘数据
      let option = {
        backgroundColor: "#fff",
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            detail: {
              formatter: "{value}",
            },
            data: [],
          },
        ],
      };

      // 阶段速度仪表盘数据
      let stageSpeedOption = {
        backgroundColor: "#fff",
        series: [
          {
            type: "gauge",
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.3, "#67e0e3"],
                  [0.7, "#37a2da"],
                  [1, "#fd666d"],
                ],
              },
            },
            pointer: {
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: "#fff",
                width: 2,
              },
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: "#fff",
                width: 4,
              },
            },
            axisLabel: {
              color: "auto",
              distance: 40,
              fontSize: 15,
            },
            detail: {
              valueAnimation: true,
              formatter: "{value} km/h",
              color: "auto",
              fontSize: 20,
            },
            data: [],
          },
        ],
      };
      // 等级仪表盘数据
      let gradeOption = {
        backgroundColor: "#fff",
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, "#FF6E76"],
                  [0.5, "#FDDD60"],
                  [0.75, "#58D9F9"],
                  [1, "#7CFFB2"],
                ],
              },
            },
            pointer: {
              icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-60%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            splitLine: {
              length: 15,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },
            axisLabel: {
              color: "#464646",
              fontSize: 15,
              distance: -60,
              formatter: function (value) {
                if (value === 0.875) {
                  return "A";
                } else if (value === 0.625) {
                  return "B";
                } else if (value === 0.375) {
                  return "C";
                } else if (value === 0.125) {
                  return "D";
                }
                return "";
              },
            },
            title: {
              offsetCenter: [0, "-20%"],
              fontSize: 20,
            },
            detail: {
              fontSize: 30,
              offsetCenter: [0, "0%"],
              valueAnimation: true,
              formatter: function (value) {
                return Math.round(value * 100) + "分";
              },
              color: "auto",
            },
            data: [{}],
          },
        ],
      };
      let isStr = typeof this.gaugeData === "string";
      let lineData = isStr ? JSON.parse(this.gaugeData) : this.gaugeData;

      // 切换图形模式
      if (this.smooth == 1) {
        option.series[0].data = lineData;
        option = option;
      } else if (this.smooth == 2) {
        stageSpeedOption.series[0].data = lineData;
        option = stageSpeedOption;
      } else {
        gradeOption.series[0].data = lineData;
        option = gradeOption;
      }

      // 深色模式切换
      if (this.backgroundColor) {
        option.backgroundColor = "#100c2a";
      } else {
        option.backgroundColor = "#fff";
      }

      // echarts渲染DOM
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(this.$refs.echartsBar, "dark");
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.echarts-bar {
  width: 100%;
  height: 100%;
}
</style>
