import CryptoJS from "crypto-js";

// 与后台保持统一
const KEY = CryptoJS.enc.Utf8.parse("1631503872411567");
const IV = CryptoJS.enc.Utf8.parse("1631503872411567");

const secretUtil = {
  /**
   * AES加密 ：字符串 key iv  返回base64
   */
  Encrypt: function (word, keyStr, ivStr) {
    let key = KEY;
    let iv = IV;
    if (keyStr) {
      key = CryptoJS.enc.Utf8.parse(keyStr);
      iv = CryptoJS.enc.Utf8.parse(ivStr);
    }
    let srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  },
  /**
   * AES 解密 ：字符串 key iv  返回base64
   *
   * @return {string}
   */
  Decrypt: function (word, keyStr, ivStr) {
    let key = KEY;
    let iv = IV;

    if (keyStr) {
      key = CryptoJS.enc.Utf8.parse(keyStr);
      iv = CryptoJS.enc.Utf8.parse(ivStr);
    }

    let base64 = CryptoJS.enc.Base64.parse(word);
    let src = CryptoJS.enc.Base64.stringify(base64);

    let decrypt = CryptoJS.AES.decrypt(src, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });

    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },
};

export default {
  install(Vue) {
    Vue.prototype.secretUtil = secretUtil;
  },
};
