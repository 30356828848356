<template>
  <!-- 地图部分 -->
  <div class="online-map" id="onlineMap">
    <!-- 地图容器 -->
    <div ref="onlineMap" :id="getMapId" class="map-container"></div>
    <!-- 工具栏 -->
    <app-toolbar-control
      ref="appToolbarControl"
      :toolbarOptions="options.toolBar"
      :mapData="mapData"
      :baseLayer="baseLayer"
      @toolBarDrawEnd="handleOnDrawEnd"
    >
    </app-toolbar-control>
  </div>
</template>

<script>
  import AppToolbarControl from "./AppToolbarControl";
  import mapEventArrangeMixin from "./mapEventArrangeMixin";
  // 引入地图相关mock数据
  import {
    ExistBuildingData,
    ApplyBuildingData,
  } from "@/mock/workbench/map/nczjdGeojsonMock";
  // 引入openlayers相关
  import "ol/ol.css";
  import { Map, View } from "ol";
  import Register from "@/utils/registerProj";
  // 矢量图层和矢量源对象
  import VectorLayer from "ol/layer/Vector";
  import VectorSource from "ol/source/Vector";
  import GeoJSONFormat from "ol/format/GeoJSON";
  import { ScaleLine } from "ol/control";
  import { get as getProj } from "ol/proj.js";
  import { getWidth, getTopLeft } from "ol/extent";
  import TileLayer from "ol/layer/Tile";
  import WMTSTileGrid from "ol/tilegrid/WMTS";
  import WMTS from "ol/source/WMTS";
  // 样式对象
  import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
  // 控制器对象
  import { defaults as defaultControls } from "ol/control";

  export default {
    name: "AppOnlineMap",
    components: {
      AppToolbarControl,
    },
    mixins: [mapEventArrangeMixin],
    props: {
      baseLayer: {
        type: Object,
        require: true
      },
      options: {
        type: Object,
        require: true
      },
    },
    data() {
      return {
        // 二维地图的实例
        mapData: null,
        // 主区域的layer
        mainLayer: null,
        // map的缩放事件
        scaleEvent: null,
        // map的zoom等级
        mapZoom: this.options.defaultZoom || 18,
        // 当前map的绘制geoJson集合
        drawGeoJson: {}
      };
    },
    watch: {
      "options.scaleData": {
        handler() {
          this.setScalePosition();
        },
        deep: true
      }
    },
    computed: {
      // vuex缓存的map
      getMap() {
        return this.$store.state.map;
      },
      // 地图底图的默认配置项
      baselayerDefaultConfig() {
        return BASELAYER_CONFIG;
      },
      // 地图控件设置的默认值
      onlineMapOption() {
        return ONLINE_MAP_OPTION;
      },
      //计算控制地图id
      getMapId() {
        let tag = new Date().getTime();
        return `map-${tag}`;
      },
      // 当前比例尺的偏移量
      scaleDataStyle() {
        const left = this.options.scaleData.left;
        const top = this.options.scaleData.top;
        return { left: `${left}%`, top: `${top}%` };
      },
    },
    async created() {
      await this.$nextTick();
      // 页面渲染后，绑定底图切换的鼠标移入移出事件
      let container = document.getElementById("basemap-container");
      if (container) {
        container.onmouseenter = function () {
          container.classList.add("expand");
        };
        container.onmouseleave = function () {
          container.classList.remove("expand");
        };
      }
      // 初始化地图
      this.initMap();
    },
    methods: {
      // 初始化地图
      async initMap() {
        // 注册自定义坐标系
        if (this.mapData == null) {
          Register.registerProjection();
        } else {
          this.mapData.destroy();
          this.mapData = null;
        }
        // 移除所有的绘制geojson
        this.drawGeoJson = {};
        // 控制工具的集合
        const resultControl = this.setControlLine();
        // 基本图层的集合
        const resultLayer = this.setInitMapLayer();
        // 地图中心点
        const defaultValue = this.options.defaultCenterPoint;
        const defaultArray = defaultValue && defaultValue.split(",");
        let mapCenter = "";
        if (defaultArray.length <= 1) {
          mapCenter = [119.09841817148207, 25.359994436691665];
        } else {
          mapCenter = [parseFloat(defaultArray[0]), parseFloat(defaultArray[1])];
        }
        // 初始化地图
        this.mapData = new Map({
          target: this.getMapId,
          view: new View({
            zoom: this.mapZoom,
            center: mapCenter,
            projection: "EPSG:4490",
            minZoom: this.options.minZoom || 2,
            maxZoom: this.options.maxZoom || 24
          }),
          // 设置控制器
          controls: defaultControls({
            attributionOptions: {
              collapsible: false,
              zoomWheelEnabled: false
            },
            zoom: false,
            // 扩展设置好的比例尺和鼠标控制器
          }).extend([
            resultControl.scaleLine
          ]),
        });
        // 加入基础的图层
        this.mapData.addLayer(resultLayer.buildingLayer);
        this.mapData.addLayer(resultLayer.exitBuildingLayer);
        this.mapData.addLayer(resultLayer.applyBuildingLayer);
        await this.$nextTick();
        let layers = this.mapData.getLayers();
        let mapConfig = this.baseLayer["img"];
        layers.setAt(1, this.WMTSLayer(mapConfig.m.url, mapConfig.m.layerName));
        layers.setAt(2, this.WMTSLayer(mapConfig.c.url, mapConfig.c.layerName));
        // 设置比列尺的位置
        this.setScalePosition();
        this.mapData.on("click", (e) => {
          this.mapData
                  .getLayers()
                  .getArray()
                  .forEach((e) => {});
        });
      },
      //通过gridset计算行列号加载wmts
      WMTSLayer(baseUrl, layerName) {
        const projection = getProj("EPSG:3857");
        const projectionExtent = projection.getExtent();
        const size = getWidth(projectionExtent) / 256;
        const resolutions = new Array(19);
        const matrixIds = new Array(19);
        for (let z = 0; z < 19; ++z) {
          // generate resolutions and matrixIds arrays for this WMTS
          resolutions[z] = size / Math.pow(2, z); //如果图层加载失败，z+1
          matrixIds[z] = z;
        }
        return new TileLayer({
          opacity: 0.7,
          source: new WMTS({
            url: baseUrl,
            layer: layerName,
            matrixSet: "w",
            format: "tiles",
            projection: projection,
            tileGrid: new WMTSTileGrid({
              origin: getTopLeft(projectionExtent),
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: "default",
            wrapX: true,
          }),
        });
      },
      // 设置地图基本的控件
      setControlLine() {
        const options = this.options;
        // 比例尺
        let scaleLine = new ScaleLine({
          units: "metric",
          target: "scalebar",
          className: options.scaleData.show ? "ol-scale-line" : "hidden",
        });
        return { scaleLine };
      },
      // 设置比例尺的偏移量
      setScalePosition() {
        const scale = document.getElementsByClassName("ol-scale-line")[0];
        if (!scale) {
          return;
        }
        scale.style.left = "5%";
        scale.style.bottom = "0%";
      },
      // 设置需要初始化添加的图层
      setInitMapLayer() {
        // 三维地图建筑图层
        let buildingLayer = new VectorLayer({
          source: new VectorSource(),
          style: new Style({
            stroke: new Stroke({
              color: "rgba(205, 205, 205, 0.5)",
              width: 1,
            }),
          }),
        });
        buildingLayer.name = "buildingLayer";
        // 已经存在的房屋feature
        let exitBuildingSource = new VectorSource({
          features: new GeoJSONFormat().readFeatures(ExistBuildingData)
        });
        let exitBuildingLayer = new VectorLayer({
          source: exitBuildingSource,
          style: new Style({
            stroke: new Stroke({
              color: "red",
              width: 1,
            }),
            fill: new Fill({ color: "rgba(255, 204, 76, 0.5)" }),
          }),
          zIndex: 999,
        });
        // 正在申请的房屋feature
        let applyBuildingSource = new VectorSource({
          features: new GeoJSONFormat().readFeatures(ApplyBuildingData)
        });
        let applyBuildingLayer = new VectorLayer({
          source: applyBuildingSource,
          style: new Style({
            stroke: new Stroke({
              color: "red",
              width: 1,
            }),
            fill: new Fill({ color: "rgba(243, 1, 1, 0.8)" }),
          }),
          zIndex: 999,
        });
        // 返回生成的图层实例
        return { buildingLayer, exitBuildingLayer, applyBuildingLayer };
      },
      // 结束绘制事件
      handleOnDrawEnd(drawGeoJson) {
        this.drawGeoJson = drawGeoJson;
        this.$emit("drawEnd", JSON.stringify(drawGeoJson));
      },
    },
  };
</script>

<style type="text/less" lang="less">
.online-map {
  position: absolute;
  left: 0px;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .map-container {
    width: 100%;
    height: 100%;
  }
}
/* 比例尺 */
.ol-scale-line {
  background-color: transparent;
  border-radius: 4px;
  width: 100px;
  padding: 2px;
  position: absolute;
  text-align: left;
  .ol-scale-line-inner {
    display: inline-block;
    width: 100px !important;
    line-height: 1.1;
    overflow: hidden;
    padding: 2px 5px 1px;
    border: 2px solid #777;
    border-top: none;
    color: #333333;
    font-size: 13px;
    white-space: nowrap;
    box-sizing: border-box;
  }
}
//各类overlay
#queryPositionMark {
  font-size: 28px;
  color: red;
}
.hidden {
  display: none !important;
}
.eventRangerOverlay-node {
  font-size: 28px !important;
  color: red;
}
</style>
