// 二维地图工具栏
export const TOOLBAR_OPTION_ITEM = [
  {
    name: "定位",
    type: "position",
    icon: "el-icon-position",
  },
  {
    name: "漫游",
    type: "roaming",
    icon: "el-icon-place",
  },
  {
    name: "全屏",
    type: "fullscreen",
    icon: "el-icon-full-screen",
  },
  {
    name: "长度测量",
    type: "length",
    icon: "kj-ranging",
  },
  {
    name: "面积测量",
    type: "area",
    icon: "kj-Polygon",
  },
  {
    name: "标绘",
    type: "draw",
    icon: "kj-boxSelection",
  },
  {
    name: "要素查询开启",
    type: "feature",
    icon: "el-icon-info",
  },
  {
    name: "地图点击开启",
    type: "mapOpen",
    icon: "el-icon-info",
  },
  {
    name: "清除",
    type: "clear",
    icon: "el-icon-delete",
  },
];
// cesium初始化viewer时的公共配置
export const commonOptions = {
  baseLayerPicker: true,
  sceneModePicker: false,
  geocoder: false,
  homeButton: false,
  navigationHelpButton: false,
  fullscreenButton: false,
  animation: false,
  timeline: false,
  infoBox: false,
  selectionIndicator: false
};
// 图层树的默认配置数据
export const test = [
  {
    parentFlag: true,
    name: "河流",
    id: "1629870567148",
    children: [
      {
        parentFlag: false,
        checked: false,
        sliderValue: 100,
        id: "20220506182923182",
        parentId: "1629870567148",
        url: "http://47.114.163.199:8033/cite/wms",
        style: "fill:ffffff;stroke:3399cc", //蓝色
        name: "河流示例",
        layerName: "cite:shp_20220506182923182",
        aliasName: "河流示例20220506182923182",
      },
    ]
  },
  {
    parentFlag: true,
    name: "建筑",
    id: "16298705671492",
    children: [
      {
        parentFlag: false,
        checked: false,
        sliderValue: 100,
        id: "202205061829256852",
        parentId: "16298705671492",
        url: "http://47.114.163.199:8033/cite/wms",
        style: "fill:b22e26;stroke:e1180b", //红色
        name: "建筑示例红色",
        layerName: "cite:shp_20220506182925685",
        aliasName: "建筑示例202205061829256852红色",
      },
    ]
  },
  {
    parentFlag: true,
    name: "建筑",
    id: "1629870567149",
    children: [
      {
        parentFlag: false,
        checked: false,
        sliderValue: 100,
        id: "20220506182925685",
        parentId: "1629870567149",
        url: "http://47.114.163.199:8033/cite/wms",
        style: "fill:b28c26;stroke:f5b60b", //黄色
        name: "建筑示例黄色",
        layerName: "cite:shp_20220506182925685",
        aliasName: "建筑示例20220506182925685黄色",
      },
    ]
  },
  {
    parentFlag: true,
    name: "道路",
    id: "1629870567150",
    children: [
      {
        parentFlag: false,
        checked: false,
        sliderValue: 100,
        id: "20220506182937340",
        parentId: "1629870567150",
        url: "http://47.114.163.199:8033/cite/wms",
        style: "fill:33a338;stroke:02dc0c", //绿色
        name: "道路示例",
        layerName: "cite:shp_20220506182937340",
        aliasName: "道路示例20220506182937340",
      },
    ]
  },
  {
    parentFlag: true,
    name: "点",
    id: "1629870567151",
    children: [
      {
        parentFlag: false,
        checked: false,
        sliderValue: 100,
        id: "20220506182937122",
        parentId: "1629870567151",
        url: "http://47.114.163.199:8033/cite/wms",
        style: "fill:b22e26;stroke:e1180b", //红色
        name: "点示例",
        layerName: "cite:shp_20220506182937122",
        aliasName: "点示例20220506182937122",
      },
    ]
  }
];
// 二维地图的图层树
export const ONLINE_MAP_LAYER_TREE_DATA = [
  {
    "id": "723944256464621568",
    "name": "生态红线组",
    "type": 1,
    "sort": 1,
    "parentId": "11",
    "leafNode": 0,
    "createUserId": "619978874410897408",
    "createTime": "2022-05-17 14:17:06",
    "children": [{
      "id": "714551775704780800",
      "name": "生态保护红线",
      "sliderValue": 100,
      "type": 2,
      "checked": false,
      "parentId": "723944256464621568",
      "aliasName": "生态保护红线714551775704780800",
      "mapServiceType": "[\"WMS\",\"WFS\",\"WMTS\",\"矢量瓦片服务\"]",
      "dataServiceType": "[]",
      "layers": null,
      "layerType": "LAYER",
      "srs": "EPSG:4490",
      "styles": "[\"\"]",
      "bbox": "109.539390563965,30.201208114624,109.729286193848,30.4189186096191",
      "description": "",
      "sourceDirId": "722159962868027392",
      "dataMaps": null,
      "styleList": null,
      "createTime": "2022-04-21 16:14:44",
      "createUserId": "619978874410897408",
      "updateTime": null,
      "updateUserId": null,
      "deleted": null,
      "url": {
        "WMTS": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421160917996&STYLE=&TILEMATRIXSET=EPSG:4490&FORMAT=image/png&resourceName=生态保护红线",
        "WFS": "http://47.114.163.199:8053/api/mapService/cite/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite:shp_20220421160917996&outputFormat=application/json&resourceName=生态保护红线",
        "WMS": "http://47.114.163.199:8053/api/mapService/cite/wms?service=WMS&version=1.1.0&request=GetMap&layers=cite:shp_20220421160917996&srs=EPSG:4490&styles=&resourceName=生态保护红线",
        "WCS": null,
        "DATA": null,
        "矢量瓦片服务": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421160917996&STYLE=&TILEMATRIX=EPSG:4490:{z}&TILEMATRIXSET=EPSG:4490&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}&resourceName=生态保护红线"
      },
      "gsName": "cite:shp_20220421160917996",
      "serviceType": 0,
      "serviceStatus": 0,
      "maxScale": "",
      "dataId": "714550425327636480",
      "isShow": null,
      "serviceSource": 0,
      "fileDataId": "714550404708438016",
      "relName": null,
      "tableName": null,
      "path": null,
      "dataFormat": null,
      "modifer": null,
      "creator": {
        "id": "619978874410897408",
        "userName": "admin",
        "realName": "管理员",
        "cellphone": "5g91qZS2CTl7jTD0IKGHEA=="
      }
    }]
  },
  {
    "id": "723944375037595648",
    "name": "土地调查组",
    "type": 1,
    "sort": 2,
    "parentId": "11",
    "leafNode": 0,
    "createUserId": "619978874410897408",
    "createTime": "2022-05-17 14:17:34",
    "deleted": 0,
    "children": [{
      "id": "714551741143715840",
      "name": "第三次全国土地调查",
      "type": 2,
      "checked": false,
      "sliderValue": 100,
      "parentId": "723948934103437312",
      "aliasName": "第三次全国土地调查714551741143715840",
      "mapServiceType": "[\"WMS\",\"WFS\",\"WMTS\",\"矢量瓦片服务\"]",
      "dataServiceType": "[]",
      "layers": null,
      "layerType": "LAYER",
      "srs": "EPSG:4490",
      "styles": "[\"\"]",
      "bbox": "109.535865783691,30.2004070281982,109.729309082031,30.4189224243164",
      "description": "",
      "sourceDirId": "722089184298405888",
      "dataMaps": null,
      "styleList": null,
      "createTime": "2022-04-21 16:14:36",
      "createUserId": "619978874410897408",
      "updateTime": null,
      "updateUserId": null,
      "deleted": null,
      "url": {
        "WMTS": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421160959064&STYLE=&TILEMATRIXSET=EPSG:4490&FORMAT=image/png&resourceName=第三次全国土地调查",
        "WFS": "http://47.114.163.199:8053/api/mapService/cite/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite:shp_20220421160959064&outputFormat=application/json&resourceName=第三次全国土地调查",
        "WMS": "http://47.114.163.199:8053/api/mapService/cite/wms?service=WMS&version=1.1.0&request=GetMap&layers=cite:shp_20220421160959064&srs=EPSG:4490&styles=&resourceName=第三次全国土地调查",
        "WCS": null,
        "DATA": null,
        "矢量瓦片服务": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421160959064&STYLE=&TILEMATRIX=EPSG:4490:{z}&TILEMATRIXSET=EPSG:4490&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}&resourceName=第三次全国土地调查"
      },
      "gsName": "cite:shp_20220421160959064",
      "serviceType": 0,
      "serviceStatus": 0,
      "maxScale": "",
      "dataId": "714550984906510336",
      "isShow": 1,
      "serviceSource": 0,
      "fileDataId": "714550574783270912",
      "relName": null,
      "tableName": null,
      "path": null,
      "dataFormat": null,
      "modifer": null,
      "creator": {
        "id": "619978874410897408",
        "userName": "admin",
        "realName": "管理员",
        "cellphone": "5g91qZS2CTl7jTD0IKGHEA=="
      }
    }]
  },
  {
    "id": "723944604109508608",
    "name": "基本农田组",
    "type": 1,
    "sort": 3,
    "parentId": "11",
    "leafNode": 0,
    "createUserId": "619978874410897408",
    "createTime": "2022-05-17 14:18:29",
    "deleted": 0,
    "children": [
      {
        "id": "714551701893419008",
        "name": "永久基本农田",
        "sliderValue": 100,
        "type": 2,
        "checked": false,
        "parentId": "723996191217553408",
        "aliasName": "永久基本农田714551701893419008",
        "mapServiceType": "[\"WMS\",\"WFS\",\"WMTS\",\"矢量瓦片服务\"]",
        "dataServiceType": "[]",
        "layers": null,
        "layerType": "LAYER",
        "srs": "EPSG:4490",
        "styles": "[\"\"]",
        "bbox": "109.536697387695,30.2187194824219,109.723999023438,30.4179916381836",
        "description": "",
        "sourceDirId": "722159962868027392",
        "dataMaps": null,
        "styleList": null,
        "createTime": "2022-04-21 16:14:27",
        "createUserId": "619978874410897408",
        "updateTime": null,
        "updateUserId": null,
        "deleted": null,
        "url": {
          "WMTS": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421161321999&STYLE=&TILEMATRIXSET=EPSG:4490&FORMAT=image/png&resourceName=永久基本农田",
          "WFS": "http://47.114.163.199:8053/api/mapService/cite/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite:shp_20220421161321999&outputFormat=application/json&resourceName=永久基本农田",
          "WMS": "http://47.114.163.199:8053/api/mapService/cite/wms?service=WMS&version=1.1.0&request=GetMap&layers=cite:shp_20220421161321999&srs=EPSG:4490&styles=&resourceName=永久基本农田",
          "WCS": null,
          "DATA": null,
          "矢量瓦片服务": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421161321999&STYLE=&TILEMATRIX=EPSG:4490:{z}&TILEMATRIXSET=EPSG:4490&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}&resourceName=永久基本农田"
        },
        "gsName": "cite:shp_20220421161321999",
        "serviceType": 0,
        "serviceStatus": 0,
        "maxScale": "",
        "dataId": "714551503372816384",
        "isShow": 1,
        "serviceSource": 0,
        "fileDataId": "714551427879538688",
        "relName": null,
        "tableName": null,
        "path": null,
        "dataFormat": null,
        "modifer": null,
        "creator": {
          "id": "619978874410897408",
          "userName": "admin",
          "realName": "管理员",
          "cellphone": "5g91qZS2CTl7jTD0IKGHEA=="
        }
      },
      {
        "id": "714551661103812608",
        "name": "农房登记数据",
        "sliderValue": 100,
        "type": 2,
        "checked": false,
        "parentId": "723996191217553408",
        "aliasName": "农房登记数据714551661103812608",
        "mapServiceType": "[\"WMS\",\"WFS\",\"WMTS\",\"矢量瓦片服务\"]",
        "dataServiceType": "[]",
        "layers": null,
        "layerType": "LAYER",
        "srs": "EPSG:4490",
        "styles": "[\"\"]",
        "bbox": "109.540260314941,30.2159767150879,109.717788696289,30.4033889770508",
        "description": "",
        "sourceDirId": "722089184298405888",
        "dataMaps": null,
        "styleList": null,
        "createTime": "2022-04-21 16:14:17",
        "createUserId": "619978874410897408",
        "updateTime": "2022-04-21 16:14:48",
        "updateUserId": "619978874410897408",
        "deleted": null,
        "url": {
          "WMTS": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421160850502&STYLE=&TILEMATRIXSET=EPSG:4490&FORMAT=image/png&resourceName=农房登记数据",
          "WFS": "http://47.114.163.199:8053/api/mapService/cite/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite:shp_20220421160850502&outputFormat=application/json&resourceName=农房登记数据",
          "WMS": "http://47.114.163.199:8053/api/mapService/cite/wms?service=WMS&version=1.1.0&request=GetMap&layers=cite:shp_20220421160850502&srs=EPSG:4490&styles=&resourceName=农房登记数据",
          "WCS": null,
          "DATA": null,
          "矢量瓦片服务": "http://47.114.163.199:8053/api/mapService/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=cite:shp_20220421160850502&STYLE=&TILEMATRIX=EPSG:4490:{z}&TILEMATRIXSET=EPSG:4490&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}&resourceName=农房登记数据"
        },
        "gsName": "cite:shp_20220421160850502",
        "serviceType": 0,
        "serviceStatus": 0,
        "maxScale": "",
        "dataId": "714550293324500992",
        "isShow": 1,
        "serviceSource": 0,
        "fileDataId": "714550276979298304",
        "relName": null,
        "tableName": null,
        "path": null,
        "dataFormat": null,
        "modifer": {
          "id": "619978874410897408",
          "userName": "admin",
          "realName": "管理员",
          "cellphone": "5g91qZS2CTl7jTD0IKGHEA=="
        },
        "creator": {
          "id": "619978874410897408",
          "userName": "admin",
          "realName": "管理员",
          "cellphone": "5g91qZS2CTl7jTD0IKGHEA=="
        }
      }
    ]
  }
];
// 三维地图的图层树
export const THREE_ONLINE_MAP_LAYER_TREE_DATA = [
  {
    id: "723944256464621568",
    name: "楼层建筑",
    type: 1,
    sort: 1,
    parentId: "11",
    leafNode: 0,
    createUserId: "619978874410897408",
    createTime: "2022-05-17 14:17:06",
    children: [
      {
        id: "714551775704780800",
        name: "万达小区",
        type: 2,
        checked: false,
        parentId: "723944256464621568",
        url: {
          WMS: null,
          WFS: null,
          WMTS: null,
          矢量瓦片服务: null,
          WCS: null,
          DATA: "http://47.114.163.199:8002/data-server/3dtiles/zjds/tileset.json",
        },
      },
    ],
  },
  {
    id: "723944375037595648",
    name: "工程设施",
    type: 1,
    sort: 2,
    parentId: "11",
    leafNode: 0,
    createUserId: "619978874410897408",
    createTime: "2022-05-17 14:17:34",
    deleted: 0,
    children: [
      {
        id: "714551741143715840",
        name: "桥梁",
        type: 2,
        checked: false,
        parentId: "723948934103437312",
        url: {
          WMS: null,
          WFS: null,
          WMTS: null,
          矢量瓦片服务: null,
          WCS: null,
          DATA: "http://data.marsgis.cn/3dtiles/bim-qiaoliang/tileset.json",
        },
      },
    ],
  },
  {
    id: "723944604109508608",
    name: "公共设施",
    type: 1,
    sort: 3,
    parentId: "11",
    leafNode: 0,
    createUserId: "619978874410897408",
    createTime: "2022-05-17 14:18:29",
    deleted: 0,
    children: [
      {
        id: "714551701893419008",
        name: "学校",
        type: 2,
        checked: false,
        parentId: "723996191217553408",
        url: {
          WMS: null,
          WFS: null,
          WMTS: null,
          矢量瓦片服务: null,
          WCS: null,
          DATA: "http://data.marsgis.cn/3dtiles/bim-daxue/tileset.json",
        },
      },
    ],
  },
];
