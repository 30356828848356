<template>
  <div class="text-component">{{ text }}</div>
</template>
<script>
export default {
  name: "AppTextComponent",
  props: {
    text: {
      type: String,
      default: ""
    },
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {},
};
</script>
<style scoped>
.text-component {
  width: 100%;
}
</style>
