import Vue from "vue";
import Vuex, { Store } from "vuex";

Vue.use(Vuex);
const store = new Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});

export default store;
