<template>
  <van-uploader
      multiple
      v-model="fileList"
      :max-count="maxCount"
      :disabled="disabled"
      :max-size="isOverSize"
      :before-read="beforeRead"
      :before-delete="beforeDelete"
  />
</template>
<script>
import { Toast } from "vant";
import { BASE_SERVER_URL } from "@/config/ip";
import axios from "axios";
export default {
  name: "ImageUploadComponents",
  props: {
    maxCount: {
      type: Number,
      require: 0
    },
    disabled: {
      type: Boolean,
      require: false
    },
    limitTypeArray: {
      type: Array,
      require: []
    },
    limitSize: {
      type: Number,
      require: 0
    },
    fileIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      fileList: [],
      ids: [],
    };
  },
  computed: {
  },
  watch: {
    fileIds: {
      handler(val) {
        if (val) {
          this.getUrlByFileId();
        }
      }
    }
  },
  created() {
    if (this.fileIds) {
      this.getUrlByFileId();
    }
  },
  methods: {
    isOverSize(file) {
      if (!this.limitTypeArray.includes(file.type)) {
        Toast("上传图片类型不合法");
        return true;
      }
      const maxSize = this.limitSize;
      if (file.size >= maxSize) {
        Toast("上传图片超过设置大小");
        return true;
      }
      return false;
    },
    beforeRead(file) {
      let param = {
        file: file,
      };
      return this.getDataAxios.uploadImgae(param)
          .then((res) => {
            if (res.data) {
              this.ids.push(res.data.id);
            }
          }).catch((err) => {
            this.getDataAxios.notifyError(err);
          });
    },
    beforeDelete(file) {
      this.ids.splice(this.ids.indexOf(file.id), 1);
      this.fileList = [];
      this.$emit("update:fileIds", this.ids.join(","));
    },
    getUrlByFileId() {
      let authenticationParam =
          localStorage.getItem("token") ||
          localStorage.getItem("Authentication") ||
          "";
      const { Authentication, id, userName } = this.$route.query;
      if (Authentication) {
        localStorage.setItem("Authentication", Authentication);
        authenticationParam = Authentication;
        if (id) {
          localStorage.setItem("id", id);
        }
        if (userName) {
          localStorage.setItem("userName", userName);
        }
      }
      let config = {
        "method": "get",
        "headers": {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          Authentication: authenticationParam,
        },
        "timeout": 1800000,
        "url": ""
      };
      config.url = `${BASE_SERVER_URL}/api/file/fileIds?fileIds=${this.fileIds}&Authentication=${authenticationParam}`;
      axios(config).then((res)=>{
        if (res.data) {
          this.fileList = [];
          res.data.data.forEach(item => {
            this.fileList.push({
              url: `${BASE_SERVER_URL}/api/file/download/${item.id}?Authentication=${authenticationParam}`,
              isImage: true,
            });
          });
        }
      });
    }
  },
};
</script>
