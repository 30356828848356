//地图交互用的mock数据，描述点位对应的房屋基本信息
export const mapInfo = [
  {
    ID: "0",
    XM: "张福来",
    SFZH: "400200197610100000",
    JTRK: "6",
    SQJFDZ: "向前村4组",
    XYFWJZSL: "1",
    XYFWJZMJ: "609.6m²",
    SQZJDMJ: "220.7m²",
    SFWYHDZ: "是",
    XYFWJZSFFHFWGHMJBZ: "是",
    SFJBFHTJ: "是",
    SFXYQJCJ: "否",
    SFXYTZCJ: "否",
    SFWLXTXTZZG: "否",
    SFWHYJDJ: "否",
    ZJDMJ: "609.6m²",
    DJSJ: "2013年10月20日",
    XB: "男",
    NL: "35",
    AXM: "张煊毅",
    AGX: "父子"
  },
  {
    ID: "1",
    XM: "张福来",
    SFZH: "400200197610100000",
    JTRK: "6",
    SQJFDZ: "向前村4组",
    XYFWJZSL: "1",
    XYFWJZMJ: "609.6m²",
    SQZJDMJ: "220.7m²",
    SFWYHDZ: "是",
    XYFWJZSFFHFWGHMJBZ: "是",
    SFJBFHTJ: "是",
    SFXYQJCJ: "否",
    SFXYTZCJ: "否",
    SFWLXTXTZZG: "否",
    SFWHYJDJ: "否",
    ZJDMJ: "220.7m²",
    DJSJ: "",
    XB: "男",
    NL: "35",
    AXM: "张煊毅",
    AGX: "父子"
  }
];
