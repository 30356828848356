<template>
  <!-- 日期范围筛选组件 -->
  <div class="search-dates-style">
    <!-- 筛选条件名称 -->
    <span class="search_text">{{ searchText }}：</span>
    <!-- 日期范围选择框 -->
    <el-date-picker
      class="search_date"
      v-model="date"
      type="daterange"
      clearable
      :editable="false"
      size="mini"
      value-format="yyyy-MM-dd"
      @change="emitSearch"
      unlink-panels
      range-separator="至"
      :start-placeholder="placeholderTextParam.startText"
      :end-placeholder="placeholderTextParam.endText"
    ></el-date-picker>
  </div>
</template>

<script>
/**
 *
 * @components 日期范围筛选组件
 * @props placeholderTextParam // 日期筛选框的文案
 * @props modelData // 绑定的数据 （数组格式存储开始时间和结束时间）
 * @props searchText // 文案提示
 * @author csz 2020/05/25
 *
 */
export default {
  name: "BaseSearchDate",
  props: {
    placeholderTextParam: {
      type: Object,
      default() {
        return {
          startText: "开始日期",
          endText: "结束日期"
        };
      }
    },
    modelData: {
      type: Array,
      default() {
        return [];
      }
    },
    searchText: {
      type: String,
      default: "默认日期"
    }
  },
  data() {
    return {
      date: this.modelData
    };
  },
  watch: {
    "modelData"(val) {
      this.date = val;
    }
  },
  methods: {
    emitSearch(val) {
      this.$emit("update:modelData", val);
    }
  }
};
</script>

<style
  type="text/less"
  lang="less"
>
.search-dates-style {
  display: inline-block;
  margin-right: 15px;
  margin-top: 15px;

  .el-range-editor--mini.el-input__inner {
    width: 250px;
  }

  .el-range-editor--mini .el-range-input {
    font-size: 13px;
  }

  .search_text {
    display: inline-block;
    font-size: 13px;
    color: #333333;
  }

  .search_date.el-input--mini {
    display: inline-block;
    width: 200px;
    color: #333333;

    .el-input__inner {
      font-size: 13px;
    }
  }
}
</style>