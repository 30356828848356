<template>
  <div class="image-components">
    <el-image
      class="default-style"
      :src="imageUrl"
      fit="fill"
      :preview-src-list="[imageUrl]"
    ></el-image>
  </div>
</template>
<script>
import axios from "axios";
import { BASE_SERVER_URL } from "@/config/ip";
export default {
  name: "TabelImageComponents",
  props: {
    fileIds: {
      type: String,
      default: ()=>[]
    },
  },
  data() {
    return {
      imageUrl: ""
    };
  },
  computed: {
  },
  watch: {
    fileIds: {
      handler(val) {
        this.getUrlByFileId();
      }
    }
  },
  created() {
    if (this.fileIds) {
      this.getUrlByFileId();
    }
  },
  methods: {
    getUrlByFileId() {
      let authenticationParam = localStorage.getItem("token") || localStorage.getItem("Authentication") || "";
      const { Authentication, id, userName } = this.$route.query;
      if (Authentication) {
        localStorage.setItem("Authentication", Authentication);
        authenticationParam = Authentication;
        if (id) {
          localStorage.setItem("id", id);
        }
        if (userName) {
          localStorage.setItem("userName", userName);
        }
      }
      let config = {
        "method": "get",
        "headers": {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*",
          "Authentication": authenticationParam
        },
        "timeout": 1800000,
        "url": ""
      };
      config.url = `${BASE_SERVER_URL}/api/file/fileIds?fileIds=${this.fileIds}&Authentication=${authenticationParam}`;
      axios(config).then((res)=>{
        if (res.data) {
          this.imageUrl = `${BASE_SERVER_URL}/api/file/download/${res.data.data[0].id}?Authentication=${authenticationParam}`;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.image-components {
  width: 100%;
  height: 100%;
}
.default-style {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>