<template>
  <div class="search-select-style">
    <span class="search_text">{{ searchText }}：</span>
    <el-select
      class="search_select"
      v-model="selectValue"
      :placeholder="placeholderText"
      :loading="getDataLoading"
      size="mini"
      clearable
      @change="emitSearch"
      @visible-change="changHideShow"
    >
      <el-option
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value + ''"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
/**
 *
 * @components 下拉框筛选组件
 * @props items // option的项
 * @props placeholderText // 输入框的提示文案
 * @props modelData // 绑定的数据 （字符串或者数字格式存储选中的时间）
 * @props getDataLoading // 如果下拉列表的数据从后台获取的遮罩
 * @props searchText // 文案提示
 * @author csz 2020/05/18
 *
 */
export default {
  name: "BaseSearchSelect",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    placeholderText: {
      type: String,
      default: "请选择"
    },
    modelData: {
      type: [String, Number],
      default: ""
    },
    getDataLoading: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: "请选择"
    }
  },
  data() {
    return {
      selectValue: this.modelData
    };
  },
  watch: {
    "modelData"(val) {
      this.selectValue = val;
    }
  },
  methods: {
    // 选中的项发生改变更新父组件的数据和emit一个search方法
    emitSearch(val) {
      this.$emit("update:modelData", "" + val);
      this.$emit("search");
    },
    // 传入的值为Boolen
    // 下拉框显示触发的事件
    changHideShow(show) {
      if (show) {
        this.$emit("optionShow");
      }
    }
  }
};
</script>

<style
  type="text/less"
  lang="less"
>
.search-select-style {
  display: inline-block;
  margin-right: 15px;
  margin-top: 15px;

  .search_text {
    display: inline-block;
    font-size: 13px;
    color: #333333;
  }

  .search_select {
    display: inline-block;
    width: 200px;
    color: #333333;

    .el-input--mini .el-input__inner {
      font-size: 13px;
    }
  }
}
</style>