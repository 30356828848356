<template>
  <div id="basemap-container" :style="baseMapStyle">
    <div class="mapType">
      <div class="mapTypeCard normal" @click="toggleBaseLayer('vec')">
        <span>矢量</span>
      </div>
      <div class="mapTypeCard earth" @click="toggleBaseLayer('ter')">
        <span>地形</span>
      </div>
      <div
          class="mapTypeCard panorama choosedType"
          @click="toggleBaseLayer('img')"
      >
        <span>影像</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getWidth, getTopLeft } from "ol/extent";
import { get as getProj, getTransform } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import WMTS from "ol/source/WMTS";

export default {
  name: "BaseMapControl",
  props: {
    mapData: {
      type: Object,
      require: true
    },
    mapOptions: {
      type: Object,
      require: true
    },
    baseLayer: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      currentBaseLayer: null
    };
  },
  computed: {
    // 当前工具栏的偏移量
    baseMapStyle() {
      const left = this.mapOptions.mapToggleData.left;
      const top = this.mapOptions.mapToggleData.top;
      return { left: `${left}%`, top: `${top}%` };
    },
  },
  methods: {
    // 底图切换
    toggleBaseLayer(type) {
      let layers = this.mapData.getLayers();
      let mapConfig = this.baseLayer[type];
      if (type !== this.currentBaseLayer && layers.getArray().length > 2) {
        layers.setAt(1, this.WMTSLayer(mapConfig.m.url, mapConfig.m.layerName));
        layers.setAt(2, this.WMTSLayer(mapConfig.c.url, mapConfig.c.layerName));
      } else {
        this.mapData.addLayer(
            this.WMTSLayer(mapConfig.m.url, mapConfig.m.layerName)
        );
        this.mapData.addLayer(
            this.WMTSLayer(mapConfig.c.url, mapConfig.c.layerName)
        );
      }
      // 新的插入
      this.currentBaseLayer = type;
    },
    //通过gridset计算行列号加载wmts
    WMTSLayer(baseUrl, layerName) {
      const projection = getProj("EPSG:4490");
      const projectionExtent = projection.getExtent();
      let origin = projectionExtent ? getTopLeft(projectionExtent) : [-180, 90];
      let fromLonLat = getTransform("EPSG:4490", projection);
      let width = projectionExtent ? getWidth(projectionExtent) : getWidth(applyTransform([-180.0, -90.0, 180.0, 90.0], fromLonLat));
      let resolutions = [];
      let matrixIds = [];
      for (let z = 1; z < 19; z++) {
        resolutions[z] = width / (256 * Math.pow(2, z));
        matrixIds[z] = z;
      };
      let wmtsTileGrid = new WMTSTileGrid({
        origin: origin,
        resolutions: resolutions,
        matrixIds: matrixIds
      });
      let wmtsSource = new WMTS({
        url: baseUrl,
        layer: layerName,
        version: "1.0.0",
        matrixSet: "c",
        format: "tiles",
        projection: projection,
        requestEncoding: "KVP",
        style: "default",
        tileGrid: wmtsTileGrid
      });
      return new TileLayer({
        source: wmtsSource
      });
    },
  },
};
</script>

<style type="text/less" lang="less">
/* 底图切换 */
#basemap-container {
  position: absolute;
  right: 5px;
  bottom: 15px;
  width: 300px;
  height: 80px;
}
/* 右下角底图切换-收缩情况下的样式 */
.mapType {
  height: 80px;
  cursor: pointer;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  width: 110px;
  background-color: rgba(255, 255, 255, 0);
  .panorama {
    background-image: url(../../../../assets/img/maptype.png);
  }
  .normal {
    z-index: 1;
    background-position: 0 0;
    right: 20px;
  }
  .mapTypeCard {
    height: 60px;
    width: 86px;
    position: absolute;
    border-radius: 2px;
    top: 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border: 1px solid rgba(153, 153, 153, 0.42);
    background: url(../../../../assets/img/maptype.png) no-repeat 0 0;
    background-size: 86px 240px;
    -webkit-transition-property: right, background-image;
    transition-property: right, background-image;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
  .normal .switch-box input.switch {
    left: 5px;
  }
  .normal .switch-box p {
    margin-left: 20px;
  }
  .switch-box p {
    display: inline-block;
    margin-left: 25px;
  }
  .switch-box input.switch {
    position: absolute;
    left: 8px;
    top: 4px;
    cursor: pointer;
  }
  .mapTypeCard.active span,
  .mapTypeCard:hover span {
    background-color: #3385ff;
  }
  .mapTypeCard span {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    padding: 3px 3px 2px 4px;
    font-size: 12px;
    height: 12px;
    line-height: 12px;
    color: #fff;
    background-color: #999;
    border-top-left-radius: 2px;
  }
  .earth {
    background-position: 0 -70px;
  }
  .satellite,
  .earth {
    right: 15px;
    z-index: 2;
    background-position: 0 -80px;
  }
  .switch-box {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 22px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
  }
  .panorama {
    z-index: 3;
    right: 10px;
    background-image: url(../../../../assets/img/maptype.png);
    background-position: 0 -160px;
    border-left: 1px solid rgba(153, 153, 153, 0.6);
  }
  .three-dimensional {
    right: 30px;
    z-index: 0;
    background-position: 0 -160px;
  }
}
/* 右下角底图切换-伸展情况下的样式 */
.expand .mapType {
  .mapTypeCard.active {
    border: 1px solid #3385ff;
  }
  .mapTypeCard {
    background-image: url(../../../../assets/img/maptype.png);
  }
  .normal {
    right: 202px;
  }
  .mapTypeCard {
    border: 1px solid rgba(255, 255, 255, 0);
    background-image: url(../../../../assets/img/maptype.png);
  }
  .satellite,
  .earth {
    right: 106px;
  }
  .mapTypeCard {
    border: 1px solid rgba(255, 255, 255, 0);
    background-image: url(../../../../assets/img/maptype.png);
  }
  .three-dimensional {
    right: 302px;
  }
}
</style>
