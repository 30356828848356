/* eslint-disable operator-linebreak */
export function humpToHyphen(str = "", splitStr = "-") {
    if (str === "") {
        return "";
    }
    if (typeof str.toLowerCase() !== "string") {
        return "incorrect character type";
    }
    let reg = new RegExp("[A-Z]", "g");
    return str
        .replace(reg, function (match) {
            return splitStr + match.toLowerCase();
        })
        .slice(1);
}
export function hyphenToHump(str = "", splitStr = "-") {
    if (str === "") {
        return "";
    }
    if (typeof str !== "string") {
        return "incorrect character type";
    }
    let arrStr = str.split(splitStr);
    let newStr = "";
    arrStr.forEach((v, i) => {
        if (v.charCodeAt() == NaN) {
            arrStr.splice(i, 1);
        }
        newStr = newStr + v.substr(0, 1).toUpperCase() + v.substr(1);
    });
    return newStr;
}
export function getQueryVariable(url, variable) {
    let query = url ? url.substring(1) : window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return false;
}
export function stringifyJson(json) {
    return JSON.stringify(json, (k, v) => {
        // 将正则对象转换为正则字符串
        if (v instanceof RegExp) {
            return v.toString();
        }
        return v;
    });
}

