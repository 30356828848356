<template>
  <div ref="echartsBar" class="echarts-bar"></div>
</template>
<script>
  export default {
    name: "PieComponents",
    props: {
      layout: {
        type: Object,
      },
      smooth: [],
      gaugeData: [],
      backgroundColor: {
        type: Boolean,
      },
      textareaPieData: [],
      title: [],
      label: {
        type: Boolean,
      },
      legend: {
        type: Boolean,
      },
      toolTip: {
        type: Boolean,
      },
      opacity: {
        type: Number,
      },
      outRingSize: {
        type: String,
      },
      innerRingSize: {
        type: String,
      },
      titleLeft: {
        type: String,
      },
      titleTop: {
        type: String,
      },
      titleSize: {
        type: String,
      },
      colorList: {
        type: String,
      },
    },
    data() {
      return {
        myChart: null,
      };
    },
    computed: {},
    watch: {
      layout: {
        handler() {
          this.getEcharts();
        },
        deep: true,
      },
      smooth() {
        this.getEcharts();
      },
      gaugeData() {
        this.getEcharts();
      },
      backgroundColor() {
        this.getEcharts();
      },
      textareaPieData() {
        this.getEcharts();
      },
      title() {
        this.getEcharts();
      },
      label() {
        this.getEcharts();
      },
      legend() {
        this.getEcharts();
      },
      toolTip() {
        this.getEcharts();
      },
      opacity() {
        this.getEcharts();
      },
      outRingSize() {
        this.getEcharts();
      },
      innerRingSize() {
        this.getEcharts();
      },
      titleLeft() {
        this.getEcharts();
      },
      titleTop() {
        this.getEcharts();
      },
      titleSize() {
        this.getEcharts();
      },
      colorList() {
        this.getEcharts();
      },
    },
    mounted() {
      this.getEcharts();
    },
    methods: {
      getEcharts() {
        // 饼图数据
        let option = {
          title: {
            text: "",
            left: "center",
            top: "6%",
            textStyle: {
              color: "#333",
            },
          },
          tooltip: {
            show: true,
            trigger: "item",
          },
          legend: {
            show: true,
            orient: "vertical",
            top: "5%",
            left: "5%",
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              label: {
                normal: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: [],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        // 环形图数据
        let doughnutOption = {
          backgroundColor: "#fff",
          title: {
            text: "",
            left: "center",
            top: "2%",
            textStyle: {
              color: "#333",
            },
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            show: true,
            orient: "vertical",
            top: "5%",
            left: "5%",
          },
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [],
            },
          ],
        };
        //  玫瑰图数据
        let nightingaleOption = {
          backgroundColor: "#fff",
          title: {
            text: "",
            left: "center",
            top: "2%",
            textStyle: {
              color: "#333",
            },
          },
          tooltip: {
            show: true,
            trigger: "item",
          },
          legend: {
            show: true,
            orient: "vertical",
            top: "5%",
            left: "5%",
          },
          series: [
            {
              type: "pie",
              radius: [20, 150],
              center: ["50%", "50%"],
              roseType: "area",
              itemStyle: {
                borderRadius: 8,
              },
              label: {
                normal: {
                  show: false,
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                { value: 40, name: "rose 1" },
                { value: 38, name: "rose 2" },
                { value: 32, name: "rose 3" },
                { value: 30, name: "rose 4" },
                { value: 28, name: "rose 5" },
                { value: 26, name: "rose 6" },
                { value: 22, name: "rose 7" },
                { value: 18, name: "rose 8" },
              ],
            },
          ],
        };

        let pieData = typeof this.textareaPieData === "string" ? JSON.parse(this.textareaPieData) : this.textareaPieData;

        // 切换图形模式
        if (this.smooth == 1) {
          // 设置饼图
          // 设置标题
          option.title.text = this.title;
          option.title.left = `${this.titleLeft ? this.titleLeft + "%" : "" }` || "center";
          option.title.top = `${this.titleTop ? this.titleTop + "%" : ""}` || "auto";
          option.title.textStyle.fontSize = this.titleSize || "18";
          // 设置引导线开关
          option.series[0].labelLine.show = this.label;
          option.series[0].label.normal.show = this.label;
          // 设置内外圈大小
          option.series[0].radius = [`${this.innerRingSize || 0 }%`, `${this.outRingSize || 60 }%`];

          // 设置图列组件开关
          option.legend.show = this.legend;

          // 设置数据颜色
          option.color = JSON.parse( this.colorList || "[]");
          // 设置提示框组件开关
          option.tooltip.show = this.toolTip;
          pieData.forEach((item) => {
            item.label = {
              show: true,
              formatter: "{c}",
              position: "inside",
            };
          });
          let total = 0;
          pieData.forEach((v) => {
            total += v.value;
          });
          option.graphic = {
            elements: [
              {
                type: "text",
                left: "center",
                top: "85%",
                style: {
                  text: "总数",
                  textAlign: "center",
                  fill: "#000",
                  width: 30,
                  height: 30,
                  fontSize: 12,
                  color: "#ffffff",
                },
              },
              {
                type: "text",
                left: "center",
                top: "90%",
                style: {
                  text: total, //这里改用实际值
                  textAlign: "center",
                  fill: "darkgray",
                  width: 30,
                  height: 25,
                  fontSize: 14,
                  color: "red",
                },
              },
            ],
          };
          //  设置饼图data数据
          option.series[0].data = pieData;
          // option = attribute.option;
        } else if (this.smooth == 2) {
          // 设置环形图

          // 设置标题
          doughnutOption.title.text = this.title;
          doughnutOption.title.left = `${this.titleLeft ? this.titleLeft + "%" : "" }` || "center";
          doughnutOption.title.top = `${this.titleTop ? this.titleTop + "%" : ""}` || "auto";
          doughnutOption.title.textStyle.fontSize = this.titleSize || "18";
          // 设置引导线开关
          doughnutOption.series[0].labelLine.show = this.label;
          doughnutOption.series[0].label.show = this.label;
          doughnutOption.series[0].radius = [`${this.innerRingSize || 30 }%`, `${this.outRingSize || 60 }%`];

          // 设置图列组件开关
          doughnutOption.legend.show = this.legend;
          // 设置数据颜色
          doughnutOption.color = JSON.parse( this.colorList || "[]");
          // 设置提示框组件开关
          doughnutOption.tooltip.show = this.toolTip;
          pieData.forEach((item) => {
            item.label = {
              show: true,
              formatter: "{c}",
              position: "inside",
            };
          });
          let total = 0;
          pieData.forEach((v) => {
            total += v.value;
          });
          doughnutOption.graphic = {
            elements: [
              {
                type: "text",
                left: "center",
                top: "45%",
                style: {
                  text: "总数",
                  textAlign: "center",
                  fill: "#000",
                  width: 30,
                  height: 30,
                  fontSize: 12,
                  color: "#ffffff",
                },
              },
              {
                type: "text",
                left: "center",
                top: "50%",
                style: {
                  text: total, //这里改用实际值
                  textAlign: "center",
                  fill: "darkgray",
                  width: 30,
                  height: 25,
                  fontSize: 14,
                  color: "red",
                },
              },
            ],
          };
          //  设置饼图data数据
          doughnutOption.series[0].data = pieData;
          option = doughnutOption;
        } else {
          // 设置玫瑰图
          // 设置标题
          nightingaleOption.title.text = this.title;
          nightingaleOption.title.left = `${this.titleLeft ? this.titleLeft + "%" : "" }` || "center";
          nightingaleOption.title.top = `${this.titleTop ? this.titleTop + "%" : ""}` || "auto";
          nightingaleOption.title.textStyle.fontSize = this.titleSize || "18";
          // 设置引导线开关
          nightingaleOption.series[0].labelLine.show = this.label;
          nightingaleOption.series[0].label.normal.show = this.label;
          nightingaleOption.series[0].radius = [`${this.innerRingSize || 10 }%`, `${this.outRingSize || 80 }%`];

          // 设置图列组件开关
          nightingaleOption.legend.show = this.legend;
          // 设置数据颜色
          nightingaleOption.color = JSON.parse( this.colorList || "[]");
          // 设置提示框组件开关
          nightingaleOption.tooltip.show = this.toolTip;
          pieData.forEach((item) => {
            item.label = {
              show: true,
              formatter: "{c}",
              position: "inside",
            };
          });
          let total = 0;
          pieData.forEach((v) => {
            total += v.value;
          });
          nightingaleOption.graphic = {
            elements: [
              {
                type: "text",
                left: "center",
                top: "85%",
                style: {
                  text: "总数",
                  textAlign: "center",
                  fill: "#000",
                  width: 30,
                  height: 30,
                  fontSize: 12,
                  color: "#ffffff",
                },
              },
              {
                type: "text",
                left: "center",
                top: "90%",
                style: {
                  text: total, //这里改用实际值
                  textAlign: "center",
                  fill: "darkgray",
                  width: 30,
                  height: 25,
                  fontSize: 14,
                  color: "red",
                },
              },
            ],
          };
          //  设置饼图data数据
          nightingaleOption.series[0].data = pieData;
          option = nightingaleOption;
        }

        // 深色模式切换
        if (this.backgroundColor) {
          option.backgroundColor = "rgb(16,12,42)";
          // 透明度切换切换
          if (this.opacity) {
            option.backgroundColor = `rgba(16,12,42,${
                    (100 - this.opacity) / 100
            })`;
          }
        } else {
          option.backgroundColor = "rgb(255,255,255)";
          if (this.opacity) {
            option.backgroundColor = `rgba(255,255,255,${
                    (100 - this.opacity) / 100
            })`;
          }
        }

        // echarts渲染DOM
        if (this.myChart) {
          this.myChart.dispose();
        }

        this.myChart = this.$echarts.init(this.$refs.echartsBar, "dark");
        this.myChart.setOption(option);
      },
    },
  };
</script>

<style lang="less" scoped>
  .echarts-bar {
    width: 100%;
    height: 100%;
  }
</style>
