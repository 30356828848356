<template>
  <div className="form-container">
    <el-collapse v-model="activeNames" v-if="flowData.formKeyList">
      <el-collapse-item :key="item.formKey" v-for="(item,index) in flowData.formKeyList" :title="item.taskName" :name="item.formKey">
        <template v-if="index!=(flowData.formKeyList.length-1)">
          <component
              v-if="item.formKey"
              :disabled="true"
              :subFormData="flowData.formData"
              :is="item.formKey+getDataAxios.clientType()"
          />
        </template>
        <template v-else>
          <component
              v-if="item.formKey"
              :disabled="flowData.disabled"
              :subFormData.sync="subFormData"
              :is="item.formKey+getDataAxios.clientType()"
          />
        </template>
      </el-collapse-item>
    </el-collapse>

  </div>
</template>
<script>
export default {
  props: {
    flowData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      subFormData: {},
      activeNames:[]
    };
  },
  watch: {
    subFormData: {
      //表单内部的值变化，同步回父组件
      handler(newVal) {
        this.$set(this.flowData, "formData", newVal);
        this.$emit("update:flowData", this.flowData);
      },
      deep: true,
    },
    //props参数变化，重新赋值
    flowData: {
      handler(newVal) {
        if (newVal) {
          this.subFormData = newVal.formData;
          if(!this.activeNames||this.activeNames.length==0){
            let index = newVal.formKeyList && newVal.formKeyList.length ? (newVal.formKeyList.length-1) : -1;
            if(index!=-1){
              this.activeNames = [newVal.formKeyList[index].formKey]
            }
          }
          if (this.isCreateStatus()) {
            //如果是发起申请，通过流程标识获取表单标识，再渲染表单
            this.getDataAxios
                .getProcessDefinition(this.flowData.processKey)
                .then((res) => {
                  this.$set(this.flowData, "formKeyList", res.data.formKeyList);
                  // 临时代码 start  初始化表单的用户信息
                  this.$set(this.flowData, "formData", {
                    jianfnagshenqing: {
                      xzqh: "三岔镇茴坝村",
                      cxz: "赵家村1组",
                      name: "李德兵",
                      xb: "1",
                      nl: "61",
                      sfzh: "422801195112290818",
                      lxdh: "13733572528",
                      hkszd: "湖北省恩施土家族苗族自治州恩施市三岔镇茴坝村赵家村1组",
                      xyjtrk: "4",
                      name1: "陈善兰",
                      age1: "59",
                      relation1: "妻",
                      idNumber1: "422801195308100828",
                      registeredResidence1: "湖北省恩施土家族苗族自治州恩施市三岔镇茴坝村赵家村1组",
                      name2: "李维",
                      age2: "46",
                      relation2: "子",
                      idNumber2: "422801197606040818",
                      registeredResidence2: "湖北省恩施土家族苗族自治州恩施市三岔镇茴坝村赵家村1组",
                      name3: "李丹",
                      age3: "33",
                      relation3: "女",
                      idNumber3: "422801198910110889",
                      registeredResidence3: "湖北省恩施土家族苗族自治州恩施市三岔镇茴坝村赵家村1组",
                      yzjdmj: "67.73",
                      yjzmj: "81.28",
                      qszsh: "恩市集建（2001）字第01164号",
                    },
                  });
                  // 临时代码 end
                  this.$emit("update:flowData", this.flowData);
                });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    //判断是否为发起申请的状态
    isCreateStatus() {
      if (this.flowData.formKeyList&&this.flowData.formKeyList.length>0) {//无表单组件标识
        return false;
      }
      if (!this.flowData.processKey) {//有流程标识
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.form-container {
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background-color: #f4f4f400;
}
/deep/.el-collapse-item__header{
  font-weight: 600;
  color: #3f7def;
}
</style>
