// 注册自定义的坐标系
import proj4 from "proj4";
import {
  addProjection,
  addCoordinateTransforms,
  Projection
} from "ol/proj.js";
import { srsItem } from "../config/srsList";

const defaultSrs = "EPSG:4326";

// 注册自定义坐标系
export default {
  registerProjection(projections = defaultSrs) {
    srsItem.forEach(item => {
      proj4.defs(item.srsName, item.srsValue);
      let projection = new Projection({
        code: item.code,
        extent: item.extent,
        units: item.units,
        axisOrientation: item.axisOrientation,
        global: item.global
      });
      //结合proj4在ol3中自定义坐标系
      addProjection(projection);
      addCoordinateTransforms(
        projections,
        item.srsName,
        function(coordinate) {
          return proj4(defaultSrs, item.srsName, coordinate);
        },
        function(coordinate) {
          return proj4(item.srsName, defaultSrs, coordinate);
        }
      );
    });
  }
};



